<template>
  <div class="page drama">
    <!-- <x-header></x-header> -->
    <van-tabs
      class="tabs"
      :class="{ padding: isPadding }"
      v-model:active="active"
      animated
      swipeable
      lazy-render
    >
      <van-tab v-for="(item, index) in tabs" :title="$t(item.i18n)" :key="index" :name="item.name">
        <component :is="item.component"></component>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup lang="ts">
// import XHeader from './header.vue';
import XWatch from './watch.vue';
import XHistory from './history.vue';
import { tabName, useDramaStore } from '../../store/modules/drama';
import { LocaleLangEnum } from '@/locale/lang';
import useLocale from '@/composables/useLocale';
import xl from '@/utils/log';

const { currentLocale } = useLocale();
const isPadding = computed(() =>
  [LocaleLangEnum.zhCN, LocaleLangEnum.zhHK].includes(currentLocale.value)
);
const { currentTab } = toRefs(useDramaStore());
const tabs = [
  {
    title: '追剧',
    name: tabName.watch,
    i18n: 'home.tabs.tab5',
    component: XWatch,
  },
  {
    title: '历史',
    name: tabName.history,
    i18n: 'home.tabs.tab7',
    component: XHistory,
  },
];
const active = computed({
  get() {
    return currentTab.value;
  },
  set(v) {
    currentTab.value = v;
  },
});
watch(
  () => currentTab.value,
  (newVal) => {
    if (active.value === 'history') {
      xl.act({ actentryid: '300002', type: 'show' });
    } else {
      xl.act({ actentryid: '300001', type: 'show' });
    }
  }
);
onMounted(() => {
  if (active.value === 'history') {
    xl.act({ actentryid: '300002', type: 'show' });
  } else {
    xl.act({ actentryid: '300001', type: 'show' });
  }
});
</script>

<style scoped lang="scss">
.page.drama {
  :deep(.van-tabs) {
    &.padding {
      .van-tabs__wrap {
        padding: 0 250px 0 15px;
      }
    }

    .van-tabs__wrap {
      width: 100vw;
      height: 60px;
      padding: 0 180px 0 10px;
      background-image: url(../../imgs/bg-header.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }

    .van-tabs__nav {
      padding-bottom: 0;
      background-color: transparent;

      .van-tab {
        padding-bottom: 8px;
        color: #222;
        font-weight: bold;
        font-size: 18px;
        opacity: 0.6;

        &--active {
          color: #ff3969;
          opacity: 1;
        }
      }

      .van-tabs__line {
        width: 20px;
        height: 4px;
        background-color: #ff3969;
      }
    }

    .van-tabs__content {
      height: calc(100% - 60px);
    }

    .van-swipe-item {
      height: auto;
      // overflow-y: auto;
    }
  }
}
</style>
