import type { MaybeRef } from '@vueuse/core';
import type { Ref, UnwrapNestedRefs } from 'vue';
import { reactive, ref } from 'vue';
import { isArray } from '@/utils/is';
import Storage from '@/utils/storage';

interface UseLoadListReturn<D = any> {
  refreshing: Ref<boolean>;
  loading: Ref<boolean>;
  finished: Ref<boolean>;
  error: Ref<boolean>;
  pagination: UnwrapNestedRefs<Pagination>;
  list: Ref<D[]>;
  load: () => Promise<any>;
  refreshLoad: () => Promise<any>;
}

interface Params extends Partial<Pagination> {
  immediate?: boolean; // 非接口参数，该参数用于判断是否初始化直接调用一次接口，默认为 false
  cache?: boolean; // 非接口参数，该参数用于判断是否对列表数据做缓存处理，默认为 false
  delay?: number; // 是否延迟结束refreshing状态，提升下拉刷新体验
  // [k: string]: any; // 接口其他参数

  /** 缓存的分页和列表数据 */
  cacheKey?: string;
}

interface CacheData<D = any> {
  page: number;
  data: D[];
  extra: any;
  finished: boolean;
}

const FIRST_PAGE = 1;

export function useLoadList<
  T extends (...args: any[]) => Promise<{
    data: Array<D>;
    [k: string]: any;
  }>,
  D = any,
>(
  apiFunc: T,
  params?: MaybeRef<Omit<Parameters<T>[0], keyof Pagination> & Params>
): UseLoadListReturn<D> {
  // const list = ref<D[]>([]); // 使用泛型时，这种写法会导致赋值时，类型报错，详见：https://github.com/vuejs/core/issues/2136#issuecomment-908269949
  // 列表数据
  const list: Ref<D[]> = ref([]);
  // 刷新加载
  const refreshing = ref(false);
  // 滚动加载
  const loading = ref(false);
  // 加载数据出错
  const error = ref(false);
  // 分页信息
  const pagination: Pagination = reactive({
    page_num: FIRST_PAGE,
    // pageSize: 10,
  });
  // 分页结束（已经到最后一页了）
  const finished = ref(false);

  let immediate = false;
  let delay = 0;
  const unRefParams = unref(params);

  if (typeof unRefParams?.immediate === 'boolean') {
    immediate = unRefParams.immediate;
    delete unRefParams.immediate;
  }

  if (typeof unRefParams?.delay === 'number') {
    delay = unRefParams.delay;
    delete unRefParams.delay;
  }

  // 判断是否有缓存列表数据和页码
  let cacheKey = '';
  if (unRefParams?.cacheKey) {
    cacheKey = unRefParams.cacheKey;
    const cached = Storage.getItem(cacheKey) || {};
    console.log(`cacheKey=${cacheKey} =>`, cached);
    const { page = FIRST_PAGE, data = [], finished: end = false } = cached as CacheData<D>;
    if (page && data.length) {
      pagination.page_num = page;
      list.value = data;
      finished.value = end;
    }
    delete unRefParams.cacheKey;
  }

  // 缓存页码和列表数据,24小时
  const cacheData = () => {
    if (!cacheKey) return;
    Storage.setItem(
      cacheKey,
      {
        page: pagination.page_num,
        data: list.value,
        finished: finished.value,
      },
      24 * 60 * 60
    );
  };

  const req = async () => {
    try {
      const _params = unref(params);
      // 删除非API请求参数
      delete _params?.cacheKey;
      delete _params?.immediate;
      delete _params?.delay;
      delete _params?.cache;
      return await apiFunc({
        ...pagination,
        ..._params,
      });
    } catch (e) {
      error.value = true;
      refreshing.value = false;
      loading.value = false;
      throw e;
    }
  };

  const ifHasMore = (hasMore: boolean) => {
    if (hasMore) {
      finished.value = false;
      pagination.page_num++;
    } else {
      finished.value = true;
    }
  };

  const loadMore = async () => {
    if (finished.value) return;

    const res = await req();
    if (res) {
      const { data } = res || {};
      const dataIsArray = isArray(data) ? data : [];
      list.value = list.value.concat(dataIsArray);
      const hasMore = data.length >= 10;
      ifHasMore(hasMore);
      cacheData();
    }
  };

  const loadInit = async () => {
    const res = await req();
    console.log('loadInit >>> ', { res });
    if (res) {
      const { data } = res || {};
      const dataIsArray = isArray(data) ? data : [];
      list.value = dataIsArray;
      const hasMore = data.length >= 10;
      ifHasMore(hasMore);
      cacheData();
    }
  };

  const load = async () => {
    error.value = false;
    loading.value = true;
    if (pagination.page_num === FIRST_PAGE) {
      await loadInit();
    } else {
      await loadMore();
    }
    loading.value = false;
  };

  const refreshLoad = async () => {
    refreshing.value = true;
    pagination.page_num = FIRST_PAGE;
    await loadInit();
    if (delay) {
      setTimeout(() => {
        refreshing.value = false;
      }, delay);
    } else {
      refreshing.value = false;
    }
  };

  const withLock = (fn: () => Promise<any>) => {
    let lock = false;
    return async () => {
      if (lock) {
        return Promise.reject('请求过快');
      }
      try {
        lock = true;
        await fn();
        lock = false;
      } catch (error) {
        console.error(error);
        // 如果请求出错，等500ms才能再次请求。
        setTimeout(() => {
          lock = false;
        }, 500);
      }
    };
  };

  if (immediate) {
    // delay = 300;
    // nextTick(() => refreshLoad());
    // 如果有缓存数据则不用加载
    if (!list.value.length) {
      load();
    }
  }

  return {
    refreshing,
    loading,
    finished,
    error,
    pagination,
    list,
    load: withLock(load),
    refreshLoad: withLock(refreshLoad),
  };
}
