import type { RouteRecordRaw } from 'vue-router';
import MainLayout from '../layouts/main.vue';
import home from '../views/home/index.vue';
import drama from '../views/drama/index.vue';
import mine from '../views/mine/index.vue';
import joinUs from '../views/mine/join-us.vue';
import wallet from '../views/wallet/index.vue';
// import task from '../views/task/index.vue';
import video from '../views/video/index.vue';
import login from '../views/login/index.vue';
import bind from '../views/bind/index.vue';
import pay from '../views/pay/index.vue';
import logVip from '../views/pay/component/log-vip.vue';
import logK from '../views/pay/component/log-k.vue';
import useK from '../views/pay/component/use-k.vue';
import useGold from '../views/pay/component/use-gold.vue';
import setting from '../views/setting/index.vue';
import language from '../views/setting/component/language.vue';
import userAgreement from '../views/setting/component/user-agreement.vue';
import privacyPolicy from '../views/setting/component/privacy-policy.vue';
import turntable from '../views/task/turntable.vue';
import testAd from '../views/test/ad.vue';
import country from '../views/pay/country.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'layout-main',
    redirect: '/home',
    component: MainLayout,
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          keepAlive: true,
        },
        component: home,
      },
      {
        path: '/drama',
        name: 'drama',
        meta: {
          keepAlive: true,
          isLogin: true,
        },
        component: drama,
      },
      {
        path: '/mine',
        name: 'mine',
        meta: {
          keepAlive: true,
        },
        component: mine,
      },
      // {
      //   path: '/task',
      //   name: 'task',
      //   meta: {
      //     keepAlive: true,
      //   },
      //   component: task,
      // },
      // {
      //   path: '/404',
      //   name: '404',
      //   component: () => import(/* webpackChunkName: "404" */ '../views/not-found.vue'),
      // },
      {
        path: '/:_(.*)*', // 可以使用任何名称代替 _ 作为参数。重点是要提供一个名字。
        name: 'NotFound',
        redirect: '/',
      },
    ],
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: wallet,
  },
  {
    path: '/video',
    name: 'video',
    component: video,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/bind',
    name: 'bind',
    meta: {
      isLogin: true,
    },
    component: bind,
  },
  {
    path: '/pay',
    name: 'pay',
    meta: {
      isLogin: true,
    },
    component: pay,
  },
  {
    path: '/log-vip',
    name: 'log-vip',
    meta: {
      isLogin: true,
    },
    component: logVip,
  },
  {
    path: '/log-k',
    name: 'log-k',
    meta: {
      isLogin: true,
    },
    component: logK,
  },
  {
    path: '/use-k',
    name: 'use-k',
    meta: {
      isLogin: true,
    },
    component: useK,
  },
  {
    path: '/use-gold',
    name: 'use-gold',
    meta: {
      isLogin: true,
    },
    component: useGold,
  },
  {
    path: '/setting',
    name: 'setting',
    component: setting,
  },
  {
    path: '/language',
    name: 'language',
    component: language,
  },
  {
    path: '/user-agreement',
    name: 'user-agreement',
    component: userAgreement,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: privacyPolicy,
  },
  {
    path: '/turntable',
    name: 'turntable',
    component: turntable,
  },
  {
    path: '/test-ad',
    name: 'test-ad',
    component: testAd,
  },
  {
    path: '/country',
    name: 'country',
    meta: {
      isLogin: true,
    },
    component: country,
  },
  {
    path: '/join-us',
    name: 'join-us',
    component: joinUs,
  },
];

export default routes;
