<template>
  <van-popup class="popup-play" v-model:show="show" position="bottom" :duration="0.2">
    <div
      :class="[
        'play-mark',
        { panelbgdefault: payStore.u_style == 0 },
        { panelbgcolor1: payStore.u_style == 1 },
        { panelbgcolor2: payStore.u_style == 2 },
        { panelbgcolor3: payStore.u_style == 3 },
      ]"
    >
      <div class="close" @click="hideSelectPlay(true)"></div>
      <div class="header">
        <div class="t1">
          <div>
            {{ $t('play.txt.episode.k') }}：<span>{{ $t('play.txt.episode.v') }}</span>
          </div>
        </div>
        <div class="t2">
          <div>
            {{ $t('play.txt.balance1') }}：<span class="value">{{
              payStore.memberInfo.k_value
            }}</span
            >&nbsp;<span>{{ $t('play.txt.kb') }}</span>
          </div>
          <div v-show="isAppcqidOrganic()">
            <span class="line">|</span>
            <span class="value">{{ payStore.memberInfo.g_value }}</span
            >&nbsp;<span>{{ $t('task.gold') }}</span>
          </div>
        </div>
      </div>
      <van-skeleton :row="5" :loading="payStore.u_style == 0">
        <div class="content" v-if="payStore.u_style == 1">
          <div class="buy__vip" v-if="Object.values(payStore.vipGoodsList).length">
            <card-vip :list="payStore.vipGoodsList" @buy="(item) => payBuyGoods(item, 1)" />
          </div>
          <div
            :class="['k__special', { focus: Object.values(payStore.specialGoods).length }]"
            v-if="Object.values(payStore.specialGoods).length"
            @click="payBuyGoods(payStore.specialGoods, 2)"
          >
            <div class="k__info">
              <div class="price">
                {{ payStore.specialGoods.custom?.unit }}{{ payStore.specialGoods.price }}
              </div>
              <div class="text">{{ payStore.specialGoods.commodityName }}</div>
            </div>
            <div class="k__price">{{ $t('play.txt.btn3') }}</div>
          </div>
          <div class="buy__k">
            <card-k :type="1" :list="payStore.kGoodsList" @buy="(item) => payBuyGoods(item, 3)" />
          </div>
        </div>
        <div class="content" v-if="payStore.u_style == 2">
          <div class="buy__goods">
            <card-goods
              :list="payStore.goodsMixedList"
              @buy="(item, type) => payBuyGoods(item, type)"
            />
          </div>
        </div>
        <card-goods3
          v-if="payStore.u_style == 3"
          @buy="(item, type) => payBuyGoods(item, type)"
        ></card-goods3>
      </van-skeleton>
    </div>
  </van-popup>
</template>
<script setup lang="ts">
import { payItem } from '@/utils/auth';
import { usePayStore } from '@/pages/index/store/modules/pay';
import xl, { isAppcqidOrganic } from '@/utils/log';
import CardVip from '../../pay/component/card-vip.vue';
import CardK from '../../pay/component/card-k.vue';
import CardGoods from '../../pay/component/card-goods.vue';
import CardGoods3 from '../../pay/component/card-goods3.vue';

interface IProps {
  visible: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
});

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'gopay', value: PayType.PlyItemFormat): void;
  (e: 'hide', value: boolean): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});
const payStore = usePayStore();

function hideSelectPlay(open = true) {
  emit('hide', open);
}
// 充值点击
const payBuyGoods = (item: PayType.PlyItemFormat, type: 1 | 2 | 3) => {
  payItem.value = JSON.stringify(item);
  emit('gopay', item);
  hideSelectPlay(false);
  // vip商品
  if (type == 1) {
    xl.act({
      actentryid: '110005',
      subactid: `${item.commodityId}`,
      type: 'click',
    });
  }
  // 整局解锁商品
  if (type == 2) {
    xl.act({
      actentryid: '110006',
      type: 'click',
    });
  }
  // K币商品
  if (type == 3) {
    xl.act({
      actentryid: '110007',
      subactid: `${item.custom?.value}`,
      type: 'click',
    });
  }
};

watch(
  () => show.value,
  (newVal) => {
    if (newVal) {
      xl.act({ actentryid: '110005', type: 'show' });
    } else {
      xl.act({ actentryid: '110005', type: 'close' });
    }
  }
);
</script>
<style lang="scss" scoped>
.popup-play {
  background: none;
}

.play-mark {
  width: 100%;
  // height: 500px;
  min-height: 380px;
  overflow: hidden;
  border-radius: 15px 15px 0 0;

  &.panelbgdefault {
    background-color: #fff;
  }

  &.panelbgcolor1 {
    background-color: #ebedef;
  }

  &.panelbgcolor2 {
    background-color: #fff;
  }

  &.panelbgcolor3 {
    background-color: #e5e7e6;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 9;
    width: 20px;
    height: 20px;
    background: url('@/pages/index/imgs/close2.png') no-repeat top center;
    background-size: 100% 100%;
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    padding-left: 16.5px;

    .t1,
    .t2 {
      display: flex;
      align-items: center;
      margin-top: 4px;
      color: #7e808a;
      font-size: 13px;

      span {
        color: #222;
        font-weight: 500;

        &.line {
          margin: 0 4px;
        }

        &.value {
          color: #ff3868;
        }
      }
    }
  }

  :deep(.van-skeleton) {
    .van-skeleton__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .van-skeleton-paragraph {
        width: 48.2% !important;
        height: 90px;
        border-radius: 11px;

        &:first-child {
          width: 100% !important;
          height: 80px;
        }
      }
    }
  }

  .content {
    width: 100%;
    min-height: 280px;
    max-height: 430px;
    padding-top: 9px;
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    .buy__vip {
      position: relative;
      width: 100%;
      height: 80px;
      margin-bottom: 15px;
    }

    .k__special {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 345px;
      height: 80px;
      margin: 0 auto 15px;
      padding: 0 25px 0 16px;
      background: #fff;
      border-radius: 11px;
      box-shadow: 0 1px 7px 0 rgba(220, 222, 225, 0.8);

      &::before {
        display: inline-block;
        width: 67px;
        height: 41px;
        background: url('@/pages/index/imgs/pay/kcoin.png');
        background-size: 100% 100%;
        content: '';
      }

      &.focus {
        &::after {
          position: absolute;
          top: 30px;
          right: 0;
          width: 73px;
          height: 73px;
          background-image: url('@/pages/index/imgs/video/hand1.png');
          background-repeat: no-repeat;
          background-size: 60%;
          transform: translate(30px, 0) scale(1);
          opacity: 0;
          animation: move-up-down 0.5s infinite;
          content: '';
        }
      }

      .k__info {
        flex: 1;
        align-items: flex-start;
        padding-right: 10px;
        padding-left: 13px;

        .price {
          color: #222;
          font-weight: bold;
          font-size: 20px;
        }

        .text {
          color: #ffa42d;
          font-size: 14px;
          word-break: normal;
        }
      }

      .k__price {
        min-width: 62px;
        height: 30px;
        padding: 0 10px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        background: #ff3868;
        border-radius: 5.5px;
      }

      .special {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 45px;
        height: 21px;
        padding: 0 10px;
        color: #fff;
        font-size: 12px;
        line-height: 21px;
        text-align: center;
        background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
        border-radius: 11px 0;
      }
    }

    .buy__k {
      padding: 0 15px;
    }

    .buy__goods {
      padding: 0 15px;
    }
  }
}

@keyframes click-animation {
  0% {
    transform: scale(1.6);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes move-up-down {
  0% {
    transform: translate(30px, 0) scale(1);

    /* 初始位置 */
    opacity: 1;
  }

  50% {
    transform: translate(50px, 15px) scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: translate(30px, 0) scale(1);
    opacity: 1;
  }
}
</style>
