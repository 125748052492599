<template>
  <van-pull-refresh
    v-model="loading"
    :success-text="$t('message.desc2')"
    @refresh="onRefresh"
    :disabled="true"
  >
    <div ref="el" class="scroll-wrap" :style="{ height: `${contentHeight}px` }">
      <x-banner></x-banner>
      <h2 class="title">{{ $t('video.title1') }}</h2>
      <card-list1 ref="cardList1"></card-list1>
      <h2 class="title">{{ $t('video.title2') }}</h2>
      <card-list2 ref="cardList2"></card-list2>
      <h2 class="title">{{ $t('video.title3') }}</h2>
      <card-list3 ref="cardList3"></card-list3>
    </div>
  </van-pull-refresh>
</template>

<script setup lang="ts" name="x-discover">
import XBanner from './banner.vue';
import CardList1 from './card-list1.vue';
import CardList2 from './card-list2.vue';
import CardList3 from './card-list3.vue';
import { useScroll } from '@vueuse/core';
import { tabBarHeight } from '@/utils/auth';
import { useHomeStore } from '@/pages/index/store/modules/home';

const homeStore = useHomeStore();
const el = ref<HTMLElement | null>(null);
const { y, arrivedState } = useScroll(el);
// const { top } = toRefs(arrivedState);
// const { left: toLeft, right: toRight, top: toTop, bottom: toBottom } = toRefs(directions);
const cardList1 = ref(null);
const cardList2 = ref(null);
const cardList3 = ref(null);

const loading = ref(false);

const contentHeight = computed(() => window.innerHeight - tabBarHeight.value);

const onRefresh = () => {
  //   setTimeout(() => {
  //     showToast('刷新成功');
  //     loading.value = false;
  //   }, 1000);
};

watch(
  y,
  (v) => {
    homeStore.scrollPosition = v;
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  console.log('[onMounted] discover');
});
</script>

<style scoped lang="scss">
.scroll-wrap {
  // height: calc(100vh - 60px);
  overflow-y: auto;
}

.title {
  padding-top: 20px;
  padding-bottom: 18px;
  padding-left: 15px;
  color: #222;
  font-weight: bold;
  font-size: 20px;
  line-height: 1em;
}
</style>
