import { usePayStore } from '@/pages/index/store/modules/pay';
import { TOKTV_PAY_ORDERID } from '@/pages/index/keys/index';
import { withLoading } from '@/utils/with-loading';
import { getHrefWithoutMyExtraInfo } from '@/utils';
import { getPay } from '@/pages/index/api';
import { showToast } from 'vant';
import { isMeta } from '@/utils/is';
import { TOKTV_PAYHOLD_STOREDDATE, TOKTV_PAYHOLD_MAP } from '@/pages/index/keys/index';
import useLocale from '@/composables/useLocale';

export function usePay(callback?: (e?: any) => any) {
  const { t } = useI18n();
  const route = useRoute();
  const payStore = usePayStore();
  const { currentLocale } = useLocale();
  const showPayWay = ref(false);
  const showPayResult = ref(false);
  const showSelectPay = ref(false);
  const showFreeUnlock = ref(false);
  const showVideoPayHold = ref(false);
  const showHomePayHold = ref(false);
  const freeUnlockHideLogType = ref<1 | 2>(1);
  const drama_id = ref(route.query.drama_id as string);
  const payGoods = ref<PayType.PlyItemFormat>();
  const payLoading = ref(false);
  const payOrderId = useStorage(TOKTV_PAY_ORDERID, '', sessionStorage);
  const storedDate = useStorage(TOKTV_PAYHOLD_STOREDDATE, new Date().toDateString());
  const payHoldType = ref<1 | 2>(1); //支付挽留类型 1-首页挽留 2-视频页面挽留
  const payHoldStorageMap = useStorage<Recordable>(TOKTV_PAYHOLD_MAP, { 0: 0, 1: 0, 2: 0, 3: 0 }); //支付挽留map 0->首页 1-未购买过任何档位 2-充值过K币 3-充值过整剧解锁
  const payType = computed(() => {
    //如果是巴西(葡萄牙)语言则走CASHPAY，其他语言走云控
    return payStore.user_region === 'BR' && currentLocale.value === 'pt-BR'
      ? 'CASHPAY'
      : payStore.user_payment;
  });
  const handlePay = async (params: PayType.playItemFormat) => {
    const payParams = {
      commodityId: params.commodityId,
      showId: params.showId,
      entranceId: drama_id.value || '',
      payment: payType.value,
      is_trial: params.custom?.is_trial || 0,
      from_h5: 1,
      syncUrl: getHrefWithoutMyExtraInfo(),
    };
    if (payLoading.value) return;
    payLoading.value = true;
    try {
      const res = await withLoading(() => getPay(payParams), { delay: 0 });
      const { orderId, payContent } = res.data;
      payOrderId.value = orderId;
      try {
        const payContentData = JSON.parse(payContent) || '';
        if (!payContentData) return;
        // if (payType.value === 'PAYERMAXPAY' || payType.value.includes('PAYERMAXPAY')) {
        //   window.location.href = payContentData.redirectUrl;
        // } else if (payType.value === 'V5PAY') {
        //   window.location.href = payContentData.checkoutUrl;
        // } else {
        //   window.location.href = payContentData.payUrl;
        // }
        window.location.href =
          payContentData.payUrl || payContentData.redirectUrl || payContentData.checkoutUrl;
        if (payStore.choosePayments.length > 1) {
          showPayWay.value = false;
        } else {
          showSelectPay.value = false;
        }
      } catch (error) {
        console.error(error);
        showToast(t('play.txt.order_err'));
      }
    } catch (error) {
      console.log('err:', error);
    } finally {
      payLoading.value = false;
    }
  };

  //隐藏选择商品弹框时是否弹出支付挽留弹框
  const hideSelectPay = async (openPayHold: boolean) => {
    showSelectPay.value = false;
    if (openPayHold) {
      fetchPayHold(2);
    }
  };

  //是否弹出支付挽留弹框
  const fetchPayHold = async (type: 1 | 2) => {
    payHoldType.value = type;
    const today = new Date().toDateString();
    if (storedDate.value === today) {
      //代表今日
      if (type == 1 && payHoldStorageMap.value[0] == 1) return; //首页 (每日只弹一次)
      if (type == 2) {
        //视频页 && 未充值任何档位|充值过K币|充值过整剧解锁 (每日只弹两次)
        const payholdMap = Object.values(payHoldStorageMap.value).splice(1, 3);
        const mapEveryFlag = payholdMap.every((item: number) => item >= 2);
        if (mapEveryFlag) return;
      }
      try {
        await payStore.getPayHoldGoods(type);
        if (Object.values(payStore.retentionGoods).length) {
          checkPopup(type);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      //代表非今日
      storedDate.value = today;
      payHoldStorageMap.value = { 0: 0, 1: 0, 2: 0, 3: 0 };
      fetchPayHold(payHoldType.value);
    }
  };

  //检查今日是否要弹出支付挽留弹框
  const checkPopup = (type: 1 | 2) => {
    const popupCount = payHoldStorageMap.value[payStore.jump_location];
    if (type == 1) {
      //首页每日只弹一次(非会员)，facebook环境不弹出
      if (payStore.isVip || isMeta) return;
      if (popupCount < 1) {
        showHomePayHold.value = true;
      }
    } else {
      //视频页每日只弹两次(非会员)
      if (payStore.isVip) return (showFreeUnlock.value = true);
      if (popupCount < 2 && payStore.retentionGoodsNew.custom) {
        showVideoPayHold.value = true;
      }
    }
  };

  //支付确认弹框
  function userPayResult() {
    payOrderId.value = '';
    showPayResult.value = false;
    freeUnlockHideLogType.value = 2;
    if (payStore.paySuccess) {
      payStore.getVipMemberInfo();
      if (route.name === 'video') {
        callback && callback();
      }
    } else {
      showFreeUnlock.value = true;
    }
  }

  function handleFreeUnlockHide() {
    showFreeUnlock.value = false;
  }

  return {
    payType,
    payLoading,
    showPayWay,
    showPayResult,
    showSelectPay,
    showFreeUnlock,
    showVideoPayHold,
    showHomePayHold,
    payGoods,
    payOrderId,
    freeUnlockHideLogType,
    payHoldStorageMap,
    handlePay,
    hideSelectPay,
    handleFreeUnlockHide,
    fetchPayHold,
    userPayResult,
  };
}
