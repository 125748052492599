<template>
  <!-- 首页支付挽留弹框 -->
  <van-dialog class="payhold2" v-model:show="show" :showConfirmButton="false">
    <img src="../../../imgs/payhold2/firework.png" class="firework" />
    <div class="content">
      <div class="main">
        <img src="../../../imgs/payhold2/close.png" class="close" @click="handleClose" />
        <div class="title">{{ $t('payhold.home.title') }}</div>
        <div class="originprice">{{ goods?.custom.unit }}{{ goods?.custom.originPrice }}</div>
        <div class="until">{{ $t('payhold.home.until') }}</div>
        <div class="nowprice">
          <span>{{ goods?.custom.unit }}</span
          >{{ goods?.price }}
        </div>
        <div class="vip">{{ goods?.commodityName }}</div>
        <div class="countdown">
          <van-count-down :time="limitTime" @finish="limitTime = 180000">
            <template #default="timeData">
              <span class="block">{{
                timeData.minutes < 10 ? `0${timeData.minutes}` : timeData.minutes
              }}</span>
              <span class="colon">:</span>
              <span class="block">{{
                timeData.seconds < 10 ? `0${timeData.seconds}` : timeData.seconds
              }}</span>
            </template> </van-count-down
          >{{ $t('payhold.home.limit') }}
        </div>
        <div class="btn" @click="handleNowPay">{{ $t('payhold.home.buy') }}</div>
        <div class="tip">{{ $t('payhole.home.bouns') }}</div>
      </div>
    </div>
  </van-dialog>
</template>

<script setup lang="ts">
import xl from '@/utils/log';
import { usePay } from '../usePay';
import { payItem } from '@/utils/auth';

interface IProps {
  visible: boolean;
  location: number;
  goods: PayType.PlyItemFormat;
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  location: 0,
  goods: Object,
});
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'gopay', value: PayType.PlyItemFormat): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});
const limitTime = ref(180000);
const { payHoldStorageMap } = usePay();

const handleClose = () => {
  show.value = false;
  payHoldStorageMap.value[props.location] = 1;
};
const handleNowPay = () => {
  xl.act({ actentryid: '100018', type: `click` });
  payItem.value = JSON.stringify(props.goods);
  emit('gopay', props.goods);
  handleClose();
};

watch(
  () => show.value,
  (newVal) => {
    xl.act({ actentryid: '100018', type: `${newVal ? 'show' : 'close'}` });
  }
);
</script>

<style lang="scss">
.payhold2 {
  &.van-dialog {
    top: 47%;
    width: max-content;
    overflow: inherit;
    background: transparent;
  }

  .firework {
    position: relative;
    width: 351px;
    height: 145px;
    margin: 0 auto;
  }

  .content {
    position: relative;
    width: 283px;
    height: 371px;
    margin: -90px auto 0;

    &::before {
      position: absolute;
      top: 10px;
      display: inline-block;
      width: 283px;
      min-height: 350px;
      background-color: #cfa2ff;
      border-radius: 30px;
      content: '';
    }

    .main {
      position: absolute;
      top: 0;
      width: 283px;
      min-height: 371px;
      padding: 48px 0 20px;
      background-image: url(../../../imgs/payhold2/bg.png);
      background-size: 100% 100%;
      border-radius: 30px;

      .close {
        position: absolute;
        top: -70px;
        right: -12px;
        z-index: 9;
        width: 30px;
        height: 30px;
      }

      .title {
        position: absolute;
        top: -50px;
        right: 0;
        left: 0;
        margin: auto;
        color: #feef6e;
        font-weight: 700;
        font-size: 26px;
        text-align: center;
      }

      .originprice {
        color: #f09100;
        font-weight: 500;
        font-size: 26px;
        text-align: center;
        text-decoration: line-through;
      }

      .until {
        margin: 8px 0;
        color: #68430a;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }

      .nowprice {
        height: 40px;
        padding-top: 2px;
        color: #fb3226;
        font-weight: 500;
        font-size: 26px;
        text-align: center;

        span {
          margin-right: 2px;
          font-size: 16px;
        }
      }

      .vip {
        margin-top: 70px;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
      }

      .countdown {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        color: #fff;
        font-size: 14px;

        .van-count-down {
          margin-right: 6px;

          .block {
            display: inline-block;
            width: 22px;
            height: 25px;
            padding-top: 2px;
            color: #fa2e2c;
            font-weight: bold;
            font-size: 12px;
            line-height: 25px;
            text-align: center;
            background: #fff;
            border-radius: 6px;
          }

          .colon {
            margin: 0 3px;
            color: #fff;
            font-size: 12px;
          }
        }
      }

      .btn {
        width: 203px;
        height: 58px;
        margin: 10px auto 5px;
        color: #b31700;
        font-weight: 500;
        font-size: 16px;
        line-height: 54px;
        text-align: center;
        background-image: url(../../../imgs/payhold2/btn.png);
        background-size: 100% 100%;
      }

      .tip {
        color: rgba(255, 255, 255, 0.8);
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>
