export const useHandleScroll = () => {
  const el = ref<HTMLDivElement | null>(null);
  const { arrivedState } = useScroll(el);
  // const { left, right, top, bottom } = toRefs(arrivedState);

  let startX = 0;
  let startY = 0;
  const handleTouchStart = (e: TouchEvent) => {
    // 记录触摸起始点的坐标
    startX = e.touches[0].pageX;
    startY = e.touches[0].pageY;
  };
  const handleTouchMove = (e: TouchEvent) => {
    const deltaX = e.touches[0].pageX - startX;
    const deltaY = e.touches[0].pageY - startY;
    // console.log({ deltaX, deltaY, right: arrivedState.right });
    // 如果左右滑动距离大于上下滑动距离，也就是判断是左右滑动，且未滑动到最右侧，则阻止冒泡。
    if (
      //! todo: 这里逻辑待优化
      (Math.abs(deltaX) > Math.abs(deltaY) && arrivedState.left) ||
      (Math.abs(deltaX) > Math.abs(deltaY) && !arrivedState.right)
    ) {
      e.stopPropagation();
    }
  };
  return {
    el,
    handleTouchStart,
    handleTouchMove,
  };
};
