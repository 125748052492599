import { defineStore } from 'pinia';
import xl from '@/utils/log';

export enum tabName {
  watch = 'watch',
  history = 'history',
}

export interface State {
  currentTab: tabName;
}

const initState: State = {
  currentTab: tabName.watch,
};

export const useDramaStore = defineStore('drama', {
  state: (): State => ({ ...initState }),
  getters: {},
  actions: {},
  persist: {
    enabled: true,
  },
});
