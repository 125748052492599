<template>
  <div class="guide-slide-pop" @touchend="hideGuideSlide">
    <van-popup v-model:show="showGuideSlide" position="center" @click="hideGuideSlide">
      <div class="guide-slide">
        <ani-player class="guide-slide-ani" :src="svgaTouchMove"></ani-player>
        <p>{{ $t('common.text.text1') }}</p>
      </div>
    </van-popup>
  </div>
</template>

<script lang="ts" setup>
import AniPlayer from '@/components/ani-player/index.vue';
import svgaTouchMove from '../../../imgs/svga/touch-move.svga?url';
const showGuideSlide = ref(true);

const emit = defineEmits(['hide']);

function hideGuideSlide() {
  showGuideSlide.value = false;
  emit('hide');
}
</script>

<style lang="scss" scoped>
.guide-slide-pop {
  :deep(.van-popup) {
    top: 20%;
    background: none;
  }

  .guide-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-ani {
      width: 118px;
      height: 120px;
      margin-left: 12px;
    }

    p {
      position: relative;
      z-index: 9;
      padding: 6px 12px;
      color: #fff;
      font-size: 20px;
      text-align: center;
      background-color: rgba($color: #000, $alpha: 0.8);
      border: 1px solid #fff;
      border-radius: 6px;
    }
  }
}
</style>
