import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { useRouteStore } from '@/pages/index/store/modules/route';
import { useUserStore } from '../store/modules/user';
import { isMeta } from '@/utils/is';
import { wakeUp } from '@/utils';
import { usePwa } from '@/composables/usePwa';
// import Login from '@/components/login';

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  console.log({ to, from }, ' <<< [beforeEach]');
  useRouteStore().savePrevRouteInfo(from);
  const userStore = useUserStore();
  const { getHref } = usePwa();
  if (userStore.isLogin) {
    // 已登录
    if (to.path === '/login') {
      return next(from.path !== '/login' ? from.path : '/');
    }
  } else {
    if (isMeta) {
      if (['/drama', '/task', '/mine'].includes(to.path)) {
        wakeUp(getHref());
        return next('/');
      }
    } else {
      // 未登录
      if (['/drama', '/task'].includes(to.path)) {
        return next('/login');
      }
    }
  }
  // let count = 0;
  // if (from.path === '/video' && to.path === '/video') {
  //   if (count === 0) {
  //     count++;
  //     // 不记录历史
  //     return next({
  //       name: to.name as string,
  //       query: to.query,
  //       replace: true,
  //     });
  //   }
  // }
  next();
});

router.afterEach((to, from) => {
  console.log({ to, from }, ' <<< [afterEach]');
});

export function goBack(backPath?: string) {
  console.log('【back window.history】', window.history);
  const backRoute = window.history.state?.back;
  if (backRoute) {
    router.back();
  } else {
    router.replace(backPath || '/');
  }
}

export default router;
