export default {
  Encode(str: string, secret: string) {
    str = str.trim();
    str = this._utf8_encode(str);
    const base64Arr = this.string2Byte6(str);
    if (base64Arr.length === 0) return '';
    let res = '';
    for (const i in base64Arr) {
      res += secret[base64Arr[i]];
    }
    return res;
  },

  Decode(str: string, secret: string) {
    str = str.trim();
    const l = str.length;
    if (l === 0) return '';
    const baseChrMap: { [key: string]: any } = {};
    for (let i = 0; i < secret.length; i++) {
      baseChrMap[secret[i]] = i;
    }
    return this.byte6ToByte8String(str, baseChrMap);
  },

  string2Byte6(str: string) {
    const l = str.length;
    if (l === 0) return [];
    const charLen = 8;
    const base64Len = 6;
    const res = [];
    let lastLen = 0;
    let last = 0;
    let lastChr = 0;
    for (let i = 0; i < l; i++) {
      let ord = str.charCodeAt(i);
      let len = charLen;
      if (lastLen > 0) {
        lastChr = last << (base64Len - lastLen);
        const needChr = ord >> (charLen - (base64Len - lastLen));
        res.push(lastChr + needChr);
        ord = ord & (0xff >> (base64Len - lastLen));
        len = charLen - (base64Len - lastLen);
      }
      if (len === base64Len) {
        res.push(ord);
        len = 0;
        ord = 0;
      } else if (len > base64Len) {
        res.push(ord >> (len - base64Len));
        ord = ord & (0xff >> (charLen - (len - base64Len)));
        len = len - base64Len;
      }
      lastLen = len;
      last = ord;
    }
    if (lastLen > 0) {
      lastChr = last << (base64Len - lastLen);
      res.push(lastChr);
    }
    return res;
  },

  byte6ToByte8String(str: string, baseChrMap: { [key: string]: any }) {
    const l = str.length;
    if (l === 0) return '';
    let res = '';
    const needLen = 8;
    let lastLen = 0;
    let last = 0;
    for (let i = 0; i < l; i++) {
      const ord = baseChrMap[str[i]] ? baseChrMap[str[i]] : 0;
      const base64Len = 6;
      if (lastLen + base64Len < needLen) {
        lastLen = base64Len;
        last = ord;
        continue;
      }
      const surplusLen = base64Len - (needLen - lastLen);
      const num = (last << (needLen - lastLen)) + (ord >> surplusLen);
      const chr = String.fromCharCode(num);
      res += chr;
      last = (0xff >> (needLen - surplusLen)) & ord;
      lastLen = surplusLen;
    }
    return this._utf8_decode(res);
  },

  _utf8_decode(e: any) {
    let t = '';
    let n = 0;
    let r = 0;
    let c2 = 0;
    while (n < e.length) {
      r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
        n++;
      } else if (r > 191 && r < 224) {
        c2 = e.charCodeAt(n + 1);
        t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
        n += 2;
      } else {
        c2 = e.charCodeAt(n + 1);
        const c3 = e.charCodeAt(n + 2);
        t += String.fromCharCode(((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        n += 3;
      }
    }
    return t;
  },

  _utf8_encode(string: string) {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      const c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  },
};
