<template>
  <div class="k__content">
    <div
      :class="[
        'k__item',
        {
          focus: !payStore.kGoodsSpecial && index == 0 && type == 1,
        },
      ]"
      v-for="(item, index) in props.list"
      :key="item.showId"
      @click="handleBuyGoods(item)"
    >
      <div class="k__info">
        <div class="get">
          <span>{{ item.custom?.value }}</span
          >{{ $t('play.txt.kb') }}
        </div>
        <div class="add" v-show="item.custom?.extra_value && Number(item.custom?.extra_value) > 0">
          +{{ item.custom?.extra_value }} {{ $t('play.txt.kb') }}
        </div>
      </div>
      <div class="k__price">{{ item.custom?.unit }} {{ item.price }}</div>
      <div class="mark" v-show="item.custom?.markText && Number(item.custom?.extra_value) != 0">
        {{ item.custom?.markText }}
      </div>
      <div class="finger"><ani :src="svgaFinger"></ani></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ani from '@/components/ani-player/index.vue';
import svgaFinger from '@/pages/index/imgs/svga/finger.svga?url';
import { usePayStore } from '@/pages/index/store/modules/pay';

// const focusValue = ref([900]);
const payStore = usePayStore();
const emit = defineEmits(['buy']);
const props = defineProps({
  type: Number, //1-半屏支付弹框 2-充值页面
  list: Array<PayType.PlyItemFormat>,
});
const handleBuyGoods = (item: PayType.PlyItemFormat) => {
  emit('buy', item);
};
</script>
<style lang="scss" scoped>
.k__content {
  display: flex;
  flex-wrap: wrap;

  .k__item {
    position: relative;
    width: 167px;
    height: 90px;
    margin-right: 11px;
    margin-bottom: 11px;
    background: #fff;
    border-radius: 11px;
    box-shadow: 0 1px 7px 0 rgba(220, 222, 225, 0.8);

    &:nth-child(2n) {
      margin-right: 0;
    }

    &.focus {
      box-shadow: 0 0 6px rgba(255, 56, 104, 1);

      .finger {
        position: absolute;
        right: -30px;
        bottom: -46px;
        display: block;
        width: 110px;
      }
    }

    .finger {
      display: none;
      pointer-events: none;
    }

    .k__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 65px;

      .get {
        margin-bottom: 2px;
        color: #7c9094;
        font-size: 14px;

        span {
          margin-right: 4px;
          color: #222;
          font-weight: bold;
          font-size: 20px;
        }
      }

      .add {
        color: #ff7a00;
        font-size: 14px;
      }
    }

    .k__price {
      height: 25px;
      color: #222;
      font-weight: 700;
      font-size: 13px;
      line-height: 27px;
      text-align: center;
      background: #eaf1f2;
      border-radius: 0 0 11px 11px;
    }

    .mark {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 43px;
      height: 18px;
      padding: 0 10px;
      color: #fff;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
      border-radius: 0 11px;
    }
  }
}

@keyframes move-up-down {
  0% {
    transform: translate(30px, 0) scale(1);

    /* 初始位置 */
    opacity: 1;
  }

  50% {
    transform: translate(50px, 15px) scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: translate(30px, 0) scale(1);
    opacity: 1;
  }
}
</style>
