<template>
  <div class="test-ad-container">
    <x-ad v-model:visible="visibleAd" @close="handleAdClose"></x-ad>
    <van-button @click="handleClick">弹出广告</van-button>
  </div>
</template>

<script setup lang="ts">
const visibleAd = ref(false);
const handleAdClose = () => {
  console.log('handleAdClose');
};
const handleClick = () => {
  // 弹出广告
  visibleAd.value = true;
};
</script>

<style scoped></style>
