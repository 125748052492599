<template>
  <van-popup
    v-model:show="showSelectDrama"
    position="bottom"
    round
    :duration="0.2"
    @close="hideSelectDrama"
  >
    <div class="drama">
      <div class="header">
        <div class="base">
          <i></i>
          <div class="base-name">{{ dramaTitle }}</div>
          <span>{{ $t('drama.selections') }}</span>
        </div>
        <div class="down" @click="hideSelectDrama"></div>
      </div>
      <div class="content">
        <div
          :class="['drama-item', { active: curIndex == item }]"
          v-for="(item, index) in dramaNum"
          :key="index"
          @click="chooseDramaItem(item)"
        >
          {{ item }}
          <div class="yb" v-if="curIndex === item">
            <ani :src="yb"></ani>
          </div>
          <div
            class="lock"
            v-if="!payStore.isVip && lockEpisodeStartNum && item >= lockEpisodeStartNum"
          ></div>
        </div>
        <div class="zw"></div>
      </div>
    </div>
  </van-popup>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { usePayStore } from '@/pages/index/store/modules/pay';
import ani from '@/components/ani-player/index.vue';
import yb from '@/pages/index/imgs/svga/yb.svga?url';
import xl from '@/utils/log';
import { showToast } from 'vant';

const props = defineProps({
  show: Boolean,
  title: String,
  nums: Number,
  curIndex: Number,
  lockEpisodeStart: Number,
});

watch(
  () => props.show,
  (newVal, oldVal) => {
    showSelectDrama.value = newVal;
    if (newVal) {
      xl.act({ actentryid: '800006', type: 'show' });
    }
  }
);
watch(
  () => props.title,
  (newVal, oldVal) => {
    dramaTitle.value = newVal;
  }
);
watch(
  () => props.nums,
  (newVal, oldVal) => {
    dramaNum.value = newVal;
  }
);
watch(
  () => props.lockEpisodeStart,
  (newVal, oldVal) => {
    lockEpisodeStartNum.value = newVal;
  }
);

const payStore = usePayStore();
const { t } = useI18n();
const showSelectDrama = ref(false);
const dramaTitle: Ref<string | undefined> = ref('');
const dramaNum: Ref<number | undefined> = ref(0);
const lockEpisodeStartNum: Ref<number | undefined> = ref(0);

const emit = defineEmits(['hide-select', 'select-drama']);
function hideSelectDrama() {
  emit('hide-select');
}
function chooseDramaItem(index: number) {
  xl.act({ actentryid: '800006', subactid: String(index), type: 'click' });
  if (!payStore.isVip && lockEpisodeStartNum.value && index > lockEpisodeStartNum.value) {
    showToast(t('drama.lock.toast'));
    return;
  }
  emit('select-drama', index);
  hideSelectDrama();
}
</script>

<style lang="scss" scoped>
.drama {
  width: 100%;
  height: 327px;
  background: #fff;
  border-radius: 15px 15px 0 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 10px 0 14px;
    border-bottom: 1px solid #f5f5f5;

    .base {
      display: flex;
      align-items: center;
      width: 100%;
      color: #222;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;

      .base-name {
        display: -webkit-box;
        flex: 1;
        overflow: hidden;
        white-space: wrap;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      span {
        display: inline-block;
        padding-left: 16px;
        color: #999;
        font-size: 14px;
        white-space: nowrap;
      }

      i {
        display: inline-block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        background-image: url('@/pages/index/imgs/video/drama-block-icon.png');
        background-size: 100% 100%;
      }
    }

    .down {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      background-image: url('@/pages/index/imgs/video/down.png');
      background-size: 100% 100%;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    place-content: flex-start space-between;
    height: 273px;
    padding: 15px 15px 0;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    .drama-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      margin-right: 9px;
      margin-bottom: 9px;
      color: #222;
      font-weight: 500;
      font-size: 17px;
      background: #f2f2f2;
      border: 2px solid transparent;
      border-radius: 7px;

      &.active {
        color: #ff3666;
        background: #ffecf1;
        border: 2px solid #ff4a75;
      }

      &.drama-item:nth-child(6n) {
        margin-right: 0;
      }

      .lock {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 16px;
        background-image: url('@/pages/index/imgs/video/lock.png');
        background-size: 100% 100%;
      }

      .yb {
        position: absolute;
        bottom: -2px;
        left: -2px;
        width: 24px;
        height: 24px;

        .svga {
          width: 100%;
          height: 100%;
        }
      }
    }

    .zw {
      flex: 1;
      width: 50px;
      height: 50px;
    }
  }
}
</style>
