// Facebook login
import { isPro } from '@/utils/is';
import xl from '@/utils/log';

export const getFbUserInfo = (): Promise<FBUserInfo> => {
  return new Promise((resolve) => {
    FB.api('/me', resolve);
  });
};

const checkLogin = (res: FBResponse) => {
  return new Promise((resolve) => {
    !isPro && console.log('facebook response====>', res);
    if (res['status'] == 'connected') {
      xl.act({ actentryid: '700003', subactid: '1', type: 'click' });
      //已登录
      if (res.authResponse) {
        // const uid = loginResponse.authResponse.userID;
        resolve(res);
      }
    } else {
      xl.act({ actentryid: '700003', subactid: '2', type: 'click' });
    }
  });
};

const fbLogin = () => {
  console.log('FB======>', FB);
  return new Promise((resolve, reject) => {
    FB.login(
      (res: FBResponse) => {
        const subactid =
          res.status === 'connected' ? '1' : res.status === 'not_authorized' ? '2' : '3';
        xl.act({ actentryid: '700002', subactid, type: 'click' });
        resolve(res);
      },
      {
        scope: 'public_profile,email',
      }
    );
  });
};

export async function facebookLogin() {
  const loginRes = await fbLogin();
  await checkLogin(loginRes as FBResponse);
  return getFbUserInfo();
  // try {
  //   const loginRes = await fbLogin();
  //   await checkLogin(loginRes as FBResponse);
  //   const fbUserInfo = await getFbUserInfo();
  //   return handleFbUserInfo(fbUserInfo as FBUserInfo);
  // } catch (error) {
  //   console.log(error);
  //   return Promise.reject(error);
  // }
}
