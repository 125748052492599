import { defineStore } from 'pinia';
import { getSignInfo, getTaskList, reportTaskSuc, checkBindStatus } from '@/pages/index/api/index';
import router from '../../router';
import { useUserStore } from './user';

export const useTaskStore = defineStore('task', {
  state: () => ({
    now_days: 1, //当前是第几天(默认为第一天)
    sign_day_count: 0, //连续签到天数
    sign_status: -1, //签到状态 0签到下线（不展示），1今日未签到，2今日已签到 -1展示骨架屏效果
    sign_list: [] as TaskType.SignItemFormat[], //签到7天数据列表
    day_task: [] as TaskType.DayTaskItemFormat[], //每日任务
    new_task: [] as TaskType.NewTaskItemFormat[], //新手任务
    movie_task: [] as TaskType.MovieTaskItemFormat[], //看剧任务
    ad_task: {} as TaskType.DayTaskItemFormat, //看广告任务
  }),
  getters: {},
  actions: {
    //获取签到入口信息
    async getTaskSignInfo() {
      try {
        const res = await getSignInfo();
        if (res && res.code != 0) return;
        const { now_days, sign_day_count, sign_list, sign_status } = res.data;
        this.now_days = now_days;
        this.sign_day_count = sign_day_count;
        this.sign_list = sign_list;
        this.sign_status = sign_status;
      } catch (error) {
        console.error(error);
      }
    },
    //获取任务列表
    async getTaskListData() {
      try {
        const res = await getTaskList();
        if (res && res.code != 0) return;
        const { day_task, new_task, movie_task } = res.data;
        this.day_task = day_task;
        this.new_task = new_task;
        this.movie_task = movie_task;
        this.ad_task = day_task.find((item: TaskType.DayTaskItemFormat) => {
          return item.should_key === 'ad_watch';
        });
      } catch (error) {
        console.error(error);
      }
    },
    //任务上报
    async reqReportTaskSuc(taskId: string, eventId?: string) {
      const userStore = useUserStore();
      if (userStore.isLogin) {
        try {
          let params: any = { task_id: taskId };
          if (eventId) params = { ...params, event_id: eventId };
          const res = await reportTaskSuc(params);
          if (res && res.code != 0) return;
          this.getTaskListData();
        } catch (error) {
          console.error(error);
        }
      }
    },
    /**
     * @description 查询用户是否绑定过账号
     * @param type -> 23 facebook 24 google
     */
    async checkUserBindAccount(type: '23' | '24') {
      try {
        const res = await checkBindStatus({ usertype: type });
        if (res && res.code != 0) return;
        const { flag } = res.data;
        if (flag == 0) {
          router.push('/bind');
        } else {
          setTimeout(() => {
            this.getTaskListData();
          }, 100);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
