<template>
  <div
    class="card-list"
    ref="el"
    @touchstart.passive="handleTouchStart"
    @touchmove.passive="handleTouchMove"
  >
    <div class="card-list-item" v-for="item in dramaList" :key="item.drama_id">
      <card-item1 :item="item" @click="handleClick(item)"></card-item1>
    </div>
  </div>
</template>

<script setup lang="ts" name="card-list1">
import CardItem1 from './card-item1.vue';
import { useHandleScroll } from '../hooks/useHandleScroll';
import { useDramaList } from '../hooks/useDramaLIst';
import xl from '@/utils/log';

const { el, handleTouchStart, handleTouchMove } = useHandleScroll();
const { loading, dramaList } = useDramaList({ params: { label_id: '1' } });

const router = useRouter();
const handleClick = (item: DramaItem) => {
  router.push({
    name: 'video',
    query: {
      drama_id: item.drama_id,
      source: item.source,
      bigsource: '2',
    },
  });
  xl.act({ actentryid: '100003', subactid: item.drama_id, type: 'click' });
};

onMounted(() => {
  xl.act({ actentryid: '100003', type: 'show' });
});
</script>

<style scoped lang="scss">
.card-list {
  display: flex;
  flex-direction: row;
  min-height: 200px;
  padding: 0 15px;
  overflow-x: auto;

  &-item {
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
</style>
