// 广告JS逻辑

export function loadAdsByGoogle() {
  nextTick(() => {
    // @ts-expect-error adsbygoogle是全局变量
    (adsbygoogle = window.adsbygoogle || []).push({});
  });
}

export class OneMob {
  // 加载穿插广告
  public static loadAdxInsert() {
    const adScript = `https://cdn-ali.onemob.mobi/js/toktv_int_5.js`;
    const scriptId = `ONEMOB_toktv_int_1`;
    const scr = document.createElement('script');
    scr.id = scriptId;
    scr.src = adScript;
    scr.onload = () => {
      console.log(`${adScript}`, ' <<< loaded');
    };
    document.body.appendChild(scr);
  }
  // 移除穿插广告
  public static removeAdxInsert() {
    const scriptId = `ONEMOB_toktv_int_1`;
    const adDom = document.getElementById(scriptId);
    adDom?.remove();
  }
}
