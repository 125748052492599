import XAd from './ad.vue';
import type { App } from 'vue';
import { h, render } from 'vue';

interface IProps {
  showCloseMask?: boolean;
  onClose?: () => void;
}

function isFunction(obj: any): obj is (...args: any[]) => any {
  return typeof obj === 'function';
}

const Ad = {
  install(app: App) {
    app.component('x-ad', XAd);
  },
  open(config: IProps) {
    const handleClose = () => {
      if (container) {
        render(null, container);
        document.body.removeChild(container);
      }
      container = null;
      if (isFunction(config.onClose)) {
        config.onClose();
      }
    };

    const defaultConfig = {
      visible: false,
      showCloseMask: true,
      onClose: handleClose,
    };

    let container: HTMLElement | null = document.createElement('div');
    const vm = h(XAd, {
      ...config,
      ...defaultConfig,
    });

    render(vm, container);
    document.body.appendChild(container);

    return {
      close: handleClose,
    };
  },
};

export default Ad;
