import { getDramaList } from '../../../api';

export const useDramaList = (data?: {
  immediate?: boolean;
  params?: {
    // label?: '最新上架' | '正在热播' | '';
    label_id?: '1' | '2' | '';
    page_num?: number;
  };
}) => {
  const DEFAULT_DATA = {
    immediate: true,
    params: { label_id: '', page_num: 1 },
  };
  const _data = {
    ...DEFAULT_DATA,
    ...data,
  };
  const dramaList = ref<Array<DramaItem>>([]);
  const loading = ref(false);

  const loadData = async () => {
    try {
      loading.value = true;
      const res = await getDramaList(_data.params);
      dramaList.value = res.data;
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  };

  if (_data.immediate) {
    loadData();
  }

  return { loading, dramaList, loadData };
};
