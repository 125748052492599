import { defineStore } from 'pinia';

export const useRouteStore = defineStore('route', {
  state: () => ({
    saveRouteInfo: {
      fullPath: '',
      hash: '',
      href: '',
      meta: {},
      name: '',
      params: {},
      path: '',
      query: {},
    },
  }),
  getters: {},
  actions: {
    //记录上一次路由信息
    savePrevRouteInfo(routeInfo: any) {
      this.saveRouteInfo = routeInfo;
    },
  },
});
