<template>
  <div class="goods-content">
    <div
      :class="[
        'goods-item',
        { vipgoods: item.goods_type == 1 },
        { dramagoods: item.goods_type == 2 },
        { kgoods: item.goods_type == 3 },
        { firstkgoods: item.goods_type == 3 && item.commodityId == firstKCommodityId },
        {
          focus:
            (Object.values(payStore.specialGoods).length && item.goods_type == 2) ||
            (!Object.values(payStore.specialGoods).length &&
              item.goods_type == 3 &&
              item.commodityId == firstKCommodityId),
        },
      ]"
      v-for="item in props.list"
      :key="item.showId"
      @click="handleBuyGoods(item, item.goods_type)"
    >
      <div class="goods-price">
        <div class="label-vipgoods" v-if="item.goods_type == 1">
          <img :src="vipLabel" />
        </div>
        <div class="label-dramagoods" v-if="item.goods_type == 2">
          {{ $t('play.txt.unlockall') }}
        </div>
        <div
          class="label-kgoods"
          v-if="item.goods_type == 3 && item.commodityId == firstKCommodityId"
        >
          {{ $t('play.txt.limitpay') }}
        </div>
        <div
          class="label-percent"
          v-if="
            item.goods_type == 3 &&
            item.commodityId != firstKCommodityId &&
            item.custom?.originPrice
          "
        >
          {{ Math.floor((Number(item.custom?.originPrice) / Number(item.price)) * 100) }}%
        </div>
        <div class="now-price">
          {{ item.custom?.unit }}<span>{{ item.price }}</span>
        </div>
        <div class="old-price" v-if="item.custom?.originPrice">
          {{ item.custom?.unit }}{{ item.custom?.originPrice }}
        </div>
      </div>
      <div class="goods-desc">
        {{
          item.goods_type == 3
            ? `${item.commodityName}+${item.custom.extra_value}${$t('play.txt.kb')}`
            : item.commodityName
        }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { usePayStore } from '@/pages/index/store/modules/pay';
import vipLabel from '@/pages/index/imgs/pay/vip-label.png';

const payStore = usePayStore();
const firstKCommodityId = computed(() => payStore.kGoodsList[0].commodityId);
const emit = defineEmits(['buy']);
const props = defineProps({
  list: Array<PayType.PlyItemFormat>,
});
const handleBuyGoods = (item: PayType.PlyItemFormat, type: 1 | 2 | 3) => {
  emit('buy', item, type);
};
</script>
<style lang="scss" scoped>
.goods-content {
  display: flex;
  flex-wrap: wrap;

  .goods-item {
    position: relative;
    width: 167px;
    height: 90px;
    margin-right: 11px;
    margin-bottom: 11px;
    background: #fff;
    border-radius: 11px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &.dramagoods {
      background: #ffe4eb;
      border: 1px solid #ff3868;

      .goods-desc {
        background: #ff3868;
      }
    }

    &.vipgoods {
      background: #fefcf0;
      border: 1px solid #f4ca0b;

      .goods-desc {
        color: #34230e;
        background: #ffd92a;
      }
    }

    &.kgoods {
      background: #fff;
      border: 1px solid #fccaad;

      .goods-desc {
        color: #f72d2d;
        background: #ffdbc6;
      }
    }

    &.firstkgoods {
      background: #fff;
      border: 1px solid #ff7626;

      .goods-desc {
        color: #fff;
        background: #ff8124;
      }
    }

    &.focus {
      box-shadow: 0 0 6px rgba(255, 56, 104, 1);

      &::after {
        position: absolute;
        top: 30px;
        right: 0;
        width: 73px;
        height: 73px;
        background-image: url('@/pages/index/imgs/video/hand1.png');
        background-repeat: no-repeat;
        background-size: 60%;
        transform: translate(30px, 0) scale(1);
        opacity: 0;
        animation: move-up-down 0.5s infinite;
        content: '';
      }
    }

    .goods-price {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 62px;
      padding-top: 16px;
      color: #222;
      font-weight: 500;
      font-size: 18px;

      .label-dramagoods {
        position: absolute;
        top: -1px;
        right: -1px;
        min-width: 65px;
        height: 22px;
        padding: 0 10px;
        color: #fff;
        font-size: 11px;
        line-height: 22px;
        text-align: center;
        background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
        border-radius: 0 10px 0 11px;
      }

      .label-kgoods,
      .label-percent {
        position: absolute;
        top: -1px;
        right: -1px;
        min-width: 60px;
        height: 22px;
        padding: 0 10px;
        color: #fff;
        font-size: 11px;
        line-height: 22px;
        text-align: center;
        background: linear-gradient(266deg, #c65cf2, #ff8383);
        border-radius: 0 10px 0 11px;
      }

      .label-vipgoods {
        position: absolute;
        top: -1px;
        right: -1px;
        width: 43px;
        height: 22px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .now-price {
        span {
          font-weight: bold;
        }
      }

      .old-price {
        color: #999;
        font-size: 11px;
        text-decoration: line-through;
      }
    }

    .goods-desc {
      height: 27px;
      padding-top: 1.5px;
      color: #fff;
      font-size: 13px;
      line-height: 27px;
      text-align: center;
      border-radius: 0 0 11px 11px;
    }
  }
}

@keyframes move-up-down {
  0% {
    transform: translate(30px, 0) scale(1);

    /* 初始位置 */
    opacity: 1;
  }

  50% {
    transform: translate(50px, 15px) scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: translate(30px, 0) scale(1);
    opacity: 1;
  }
}
</style>
