<template>
  <div class="contact__us">
    <van-popup v-model:show="showContactUs" position="center" @close="hideContactUs">
      <div class="contact__us__main">
        <img src="@/pages/index/imgs/close.png" class="close" @click="hideContactUs" />
        <p>
          {{ $t('setting.menu4.desc1') }}：TokTV@gmail.com <br />{{ $t('setting.menu4.desc2') }}
        </p>
      </div>
    </van-popup>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue';
const props = defineProps({
  show: Boolean,
});

watch(
  () => props.show,
  (newVal, oldVal) => {
    showContactUs.value = newVal;
    console.log('newVal:', newVal);
  }
);

const showContactUs = ref(false);
const emit = defineEmits(['hide']);

const hideContactUs = () => {
  emit('hide');
};
</script>
<style lang="scss" scoped>
.contact__us {
  :deep(.van-popup) {
    background: none;
  }
}

.contact__us__main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 150px;
  background: url('@/pages/index/imgs/model-service-bg.png');
  background-size: 100% 100%;

  .close {
    position: absolute;
    top: 11px;
    right: 11px;
    width: 20px;
    height: 20px;
  }

  p {
    padding: 0 30px;
    color: #222;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
  }
}
</style>
