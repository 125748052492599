<template>
  <div class="card-item2" v-bind="$attrs" v-if="item">
    <div class="card-item2-img-wrap">
      <img v-lazy="item.cover" alt="" />
      <div class="card-item2-mask">
        <img :src="pngPlay" alt="" />
        <span>{{ Math.ceil((item.episode / Number(item.total_episode)) * 100) }}%</span>
      </div>
    </div>
    <div class="card-item2-info">
      <div>
        <h4 class="card-item2-title">{{ item.title }}</h4>
        <p class="card-item2-desc">EP.{{ item.episode }} / EP.{{ item.total_episode }}</p>
      </div>
      <div class="card-item2-opts">
        <div v-if="item.collected == 1" class="card-item2-opts-1" @click.stop="handleCollect(item)">
          <img :src="pngCollectA" alt="collect" />
          <span class="txt">{{ $t('video.havcollected') }}</span>
        </div>
        <div v-else class="card-item2-opts-1" @click.stop="handleCollect(item)">
          <img :src="pngCollect" alt="collect" />
          <span class="txt">{{ $t('video.collect') }}</span>
        </div>
        <div class="card-item2-opts-2" @click.stop="handleShare(item)">
          <img :src="pngShare" alt="share" />
        </div>
      </div>
    </div>
  </div>
  <!-- 分享 -->
  <share :show="showShare" :shareInfo="shareInfo" @hide="showShare = false"></share>
</template>

<script setup lang="ts" name="card-item2">
import pngPlay from '../../imgs/i-play-4.png';
import pngShare from '../../imgs/share.png';
import pngCollect from '../../imgs/collect.png';
import pngCollectA from '../../imgs/collect-a.png';
import { showToast } from 'vant';
import { collection } from '@/pages/index/api';
import share from '@/components/share/index.vue';
import xl from '@/utils/log';
import { ShareItem } from '@/components/share/type';

const props = defineProps<{
  item: RecordItem;
}>();
// console.log('props >>> ', props);
const router = useRouter();
const showShare = ref(false);
const shareInfo = ref<ShareItem>({
  drama_id: '',
  drama_num: '',
  title: '',
  source: '',
});
const handleCollect = async (item: RecordItem) => {
  const collect = item.collected;
  try {
    item.collected = collect == 1 ? 0 : 1;
    xl.act({ actentryid: '300003', subactid: `${collect == 1 ? 2 : 1}`, type: 'click' });
    await collection({
      drama_id: item.drama_id, // 剧ID
      collected: item.collected, // 0取消收藏 1收藏
    });
  } catch (error) {
    console.error(error);
    item.collected = collect;
  }
};

const handleShare = (item: RecordItem) => {
  // showToast('分享');
  xl.act({ actentryid: '300004', type: 'click' });
  showShare.value = true;
  shareInfo.value = {
    drama_id: item.drama_id,
    drama_num: item.episode,
    title: item.title,
    source: item.source,
  };
};
</script>

<style scoped lang="scss">
.card-item2 {
  display: flex;

  &-img-wrap {
    position: relative;
    width: 95px;
    height: 132px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 9px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 20px;
    color: #fff;
    font-size: 11px;
    background-color: rgba($color: #000, $alpha: 0.5);
    border-radius: 8px 0;

    img {
      width: 9px;
      height: 11px;
      margin-right: 3px;
    }
  }

  &-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 15px;
  }

  &-title {
    color: #222;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
  }

  &-desc {
    display: -webkit-box;
    overflow: hidden;
    color: #999;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &-opts {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &-1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 115px;
      height: 28px;
      color: #222;
      font-size: 13px;
      background-color: #edeff2;
      border-radius: 5px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    &-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 28px;
      margin-left: 10px;
      background-color: #edeff2;
      border-radius: 5px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
