<template>
  <!-- tabbar -->
  <van-tabbar ref="tabbarRef" class="tabbar" :class="{ dark: isRecommendTab }" v-model="active">
    <van-tabbar-item
      v-for="(item, index) in tabs"
      :key="index"
      :name="item.name"
      :to="item.to"
      v-show="item.show"
    >
      <span>{{ $t(item.i18n) }}</span>
      <template #icon="props">
        <img :src="isRecommendTab ? item.darkIcon : props.active ? item.active : item.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script setup lang="ts" name="x-tabbar">
import pngHome1 from '../../imgs/i-home-1.png';
import pngHome2 from '../../imgs/i-home-2.png';
import pngHome3 from '../../imgs/i-home-3.png';
import pngDrama1 from '../../imgs/i-drama-1.png';
import pngDrama2 from '../../imgs/i-drama-2.png';
import pngDrama3 from '../../imgs/i-drama-3.png';
import pngMine1 from '../../imgs/i-mine-1.png';
import pngMine2 from '../../imgs/i-mine-2.png';
import pngMine3 from '../../imgs/i-mine-3.png';
// import pngTask from '../../imgs/i-task.png';
import { tabName, useHomeStore } from '../../store/modules/home';
import { tabBarHeight } from '@/utils/auth';
import { Tabbar } from 'vant';
// import { isAppcqidOrganic } from '@/utils/log';
const DEFAULT_ROUTE_NAME = 'home';
const active = ref(DEFAULT_ROUTE_NAME);
const tabbarRef = ref<InstanceType<typeof Tabbar>>();
const route = useRoute();
const homeStore = useHomeStore();
const isRecommendTab = computed(
  () => homeStore.currentTab === tabName.recommend && route.name === DEFAULT_ROUTE_NAME
);
const tabs = [
  {
    title: '首页',
    name: 'home',
    active: pngHome3,
    darkIcon: pngHome2,
    inactive: pngHome1,
    to: '/home',
    i18n: 'home.tabs.tab4',
    show: true,
  },
  {
    title: '追剧',
    name: 'drama',
    active: pngDrama3,
    darkIcon: pngDrama2,
    inactive: pngDrama1,
    to: '/drama',
    i18n: 'home.tabs.tab5',
    show: true,
  },
  // {
  //   title: '福利',
  //   name: 'task',
  //   active: pngTask,
  //   darkIcon: pngTask,
  //   inactive: pngTask,
  //   to: '/task',
  //   i18n: 'home.tabs.tab8',
  //   show: isAppcqidOrganic(),
  // },
  {
    title: '我的',
    name: 'mine',
    active: pngMine3,
    darkIcon: pngMine2,
    inactive: pngMine1,
    to: '/mine',
    i18n: 'home.tabs.tab6',
    show: true,
  },
];
watchEffect(() => {
  const matchedPath = route.matched.map((item) => item.path);
  console.log({ matchedPath });
  const tab = tabs.find((t) => matchedPath.includes(t.to));
  active.value = tab?.name || DEFAULT_ROUTE_NAME;
});
watch(
  tabbarRef,
  (v) => {
    tabBarHeight.value = tabbarRef.value?.$el.clientHeight || 0;
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.tabbar {
  // height: 67px;
  :deep(.van-tabbar-item--active) {
    color: #ff3666;
  }

  &.dark {
    :deep(.van-tabbar-item) {
      background-color: #000;
    }

    :deep(.van-tabbar-item__text) {
      color: #fff;
      opacity: 0.7;
    }

    :deep(.van-tabbar-item--active) {
      .van-tabbar-item__text {
        opacity: 1;
      }
    }
  }
}
</style>
