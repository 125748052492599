import { IHwCommonParams } from '@xyz-fe/log/web-hw';
import jsonp from './jsonp';
import xl from './log';

export interface VlogParams {
  dramaid: string; // 剧id
  dramaname: string; // 剧名
  episode: string | number; // 第N集
  duration?: string | number; // 集时长 秒（解锁时拿不到可以为空）
  operate: '1' | '2' | '3' | '4' | '5' | '6'; // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
  unlocked?: string; // 解锁集数
  bigsource: string; // '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'; // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
  smallsource?: string; // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  starttime?: string; // 开始播放时间 毫秒时间戳（解锁的时候可空）
  endtime?: string; // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
  viewduration?: string | number; // 观看时长 秒（只有结束播放或暂停的时候报）
  playtype: '0' | '1' | '2'; // 是否是限时免费播放 0、是；1、否； 2、 vip会员
  dramasource?: string; // 短剧来源 取source字段
  totalepisodes: string | number; // 剧的总集数
  loadingtime?: string | number; // 该集首次播放loading耗时，每一集首次播放时上报(暂停后再播放不报)
}

export function vlog(params: VlogParams) {
  const url = `${import.meta.env.VITE_APP_API_VLOG}/gamefunction_logs/toktv/playlog`;
  const data: IHwCommonParams & VlogParams = {
    ...xl.commonParams,
    ...params,
  };
  jsonp(url, data);
}
