<template>
  <div class="recommend" ref="el">
    <swiper :direction="'vertical'" @slideChange="onSlideChange">
      <swiper-slide v-for="item in dramaList" :key="item.drama_id">
        <k-video :item="item"></k-video>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts" name="x-recommend">
import 'swiper/scss';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'xgplayer/dist/index.min.css';
import { getDramaList } from '@/pages/index/api';
import { useLoadList } from '../../../hooks/useLoadList';
import KVideo from './k-video.vue';

const {
  loading,
  finished,
  error,
  list: dramaList,
  load,
  refreshing,
  refreshLoad,
} = useLoadList(getDramaList, {
  immediate: true,
});

const el = ref(null);

// useResizeObserver(el, (entries) => {
//   const entry = entries[0];
//   const contentRect = entry.contentRect;
//   console.log(contentRect, 'contentRect');
// });

const onSlideChange = (swiper: any) => {
  const index = swiper.realIndex;
  console.log(index, '<<< realIndex');
  // 滑动到倒数第3集
  if (index >= dramaList.value.length - 3 && !finished.value) {
    load();
  }
};

onMounted(() => {
  console.log('[onMounted] recommend');
});
</script>

<style scoped lang="scss">
.recommend {
  width: 100%;
  height: 100%;
  background-color: #000;

  :deep(.swiper) {
    height: 100%;
  }
}
</style>
