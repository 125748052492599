<template>
  <!-- <div class="card-list">
    <card-item3 v-for="item in dramaList" :key="item.drama_id" :item="item"></card-item3>
  </div> -->
  <van-list
    v-model:loading="loading"
    v-model:error="error"
    :error-text="$t('message.desc1')"
    :finished="finished"
    :finished-text="list.length > 0 ? `~ ${$t('video.nomore')} ~` : ''"
    :offset="100"
    :immediate-check="false"
    @load="load"
  >
    <!-- <nft-list v-if="list.length > 0" :data="list"></nft-list> -->
    <div class="card-list" v-if="list.length > 0">
      <div class="card-list-item" v-for="item in list" :key="item.drama_id">
        <card-item3 :item="item" @click="handleClick(item)"></card-item3>
      </div>
    </div>
  </van-list>
</template>

<script setup lang="ts" name="card-list3">
import { getDramaList } from '@/pages/index/api';
import CardItem3 from './card-item3.vue';
import { useLoadList } from '../../../hooks/useLoadList';
import xl from '@/utils/log';

const { loading, finished, error, list, load, refreshing, refreshLoad } = useLoadList(
  getDramaList,
  {
    immediate: true,
  }
);

const router = useRouter();
const handleClick = (item: DramaItem) => {
  router.push({
    name: 'video',
    query: {
      drama_id: item.drama_id,
      source: item.source,
      bigsource: '4',
    },
  });
  xl.act({ actentryid: '100006', subactid: item.drama_id, type: 'click' });
};

onMounted(() => {
  xl.act({ actentryid: '100006', type: 'show' });
});
</script>

<style scoped lang="scss">
.card-list {
  display: flex;
  flex-flow: row wrap;
  padding: 0 15px;
  overflow-x: auto;

  &-item {
    margin-bottom: 15px;

    &:not(:nth-child(3n + 1)) {
      margin-left: 10px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
</style>
