import xl from '@/utils/log';
import { vlog } from '@/utils/vlog';
import Player, { Events, IPlayerOptions } from 'xgplayer';
import { usePayStore } from '../../store/modules/pay';
import { curEpisodeMap } from '@/utils/auth';
import { isIOS, isPro } from '@/utils/is';
import HlsJsPlayer from 'xgplayer-hls';
import videoIcon from '@/pages/index/imgs/video/video.png';

type DramaInfo = Pick<
  DramaItem,
  | 'drama_id'
  | 'title'
  | 'source'
  | 'last_play_episode'
  | 'total_episode'
  | 'cover'
  | 'collected'
  | 'total_collect_num'
>;

export function usePlayer() {
  const route = useRoute();
  const visibility = useDocumentVisibility();
  const drama_id = computed(() => route.query.drama_id as string);
  const bigsource = computed(() => (route.query.bigsource as string) || '9');
  const xgPlayer = ref<Player | null>(null);
  const payStore = usePayStore();
  const dramaInfo = ref<DramaInfo | null>(null);
  const curEpisode = computed({
    get() {
      return Number(curEpisodeMap.value[drama_id.value] || 1);
    },
    set(v) {
      curEpisodeMap.value[drama_id.value] = v;
    },
  });

  watch(curEpisode, (nv, ov) => {
    !isPro && console.log(nv, ov, ' <<<< curEpisode');
    if (nv !== ov) {
      playCount = 0;
    }
  });

  watch(visibility, (v) => {
    if (v === 'hidden') {
      xgPlayer.value?.pause();
    } else {
      xgPlayer.value?.play();
    }
  });

  // 记录开始播放时间
  let starttime = 0;
  // 记录加载时间
  let loadedtime = 0;
  // 记录播放次数
  let playCount = 0;
  let videoLoadedDataFlag = false;

  const onVideoLoadStart = () => {
    !isPro && console.log('【视频内容开始加载】', Date.now());
    loadedtime = Date.now();
    if (!dramaInfo.value) return;
    const { drama_id, title, total_episode, source } = dramaInfo.value;
    vlog({
      dramaid: drama_id, // 剧id
      dramaname: title, // 剧名
      episode: curEpisode.value, // 第N集
      dramasource: source, // 短剧来源 取source字段
      totalepisodes: total_episode, // 剧的总集数
      playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
      duration: xgPlayer.value?.duration || 0, // 集时长秒（解锁时拿不到可以为空）
      viewduration: xgPlayer.value?.currentTime || 0, // 进度条时间
      operate: '6', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停 6、视频内容开始加载
      bigsource: bigsource.value, // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
      // starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
      // endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
      // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
      // unlocked: '', // 解锁集数
      // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
    });
  };
  // 在正常情况下，loadeddata 事件会先于 play 事件触发，因为浏览器需要先加载视频的第一帧（触发 loadeddata）才能开始播放视频（触发 play）。
  // 然而，在某些特定情况下，play 事件可能会在 loadeddata 事件之前触发。
  const onVideoLoadedData = () => {
    !isPro && console.log('【视频起播数据加载完成】', Date.now());
    loadedtime = Date.now() - loadedtime;
    videoLoadedDataFlag = true;
  };
  const onVideoPlay = () => {
    if (!dramaInfo.value) return;
    starttime = Date.now();

    // playStatus.value = true;
    const { drama_id, title, total_episode, source, last_play_episode } = dramaInfo.value;
    xl.act({
      actentryid: '200006',
      subactid: `${drama_id}&${curEpisode.value}`,
      type: 'click',
    });
    xl.act({ actentryid: '800002', type: 'click' });
    vlog({
      dramaid: drama_id, // 剧id
      dramaname: title, // 剧名
      episode: curEpisode.value, // 第N集
      dramasource: source, // 短剧来源 取source字段
      totalepisodes: total_episode, // 剧的总集数
      playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
      duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
      viewduration: xgPlayer.value?.currentTime, // 进度条时间
      operate: '3', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
      bigsource: bigsource.value, // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
      loadingtime:
        playCount == 0 ? (videoLoadedDataFlag ? loadedtime : starttime - loadedtime) : '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
      starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
      // endtime: '', // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
      // unlocked: '', // 解锁集数
      // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
    });
    playCount++;
  };
  const onVideoPause = () => {
    if (!dramaInfo.value) return;
    if (xgPlayer.value?.currentTime === xgPlayer.value?.duration) {
      // 播放完成导致的暂停（非用户行为）
      return;
    }
    // playStatus.value = false;
    const { drama_id, title, total_episode, source, last_play_episode } = dramaInfo.value;
    xl.act({
      actentryid: '200006',
      subactid: `${drama_id}&${curEpisode.value}`, // 剧ID&剧集数
      type: 'close',
    });
    const endtime = Date.now();
    // const viewduration = endtime - starttime;
    vlog({
      dramaid: drama_id, // 剧id
      dramaname: title, // 剧名
      episode: curEpisode.value, // 第N集
      dramasource: source, // 短剧来源 取source字段
      totalepisodes: total_episode, // 剧的总集数
      playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
      duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
      viewduration: xgPlayer.value?.currentTime, // 进度条时间
      operate: '5', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
      bigsource: bigsource.value, // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
      starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
      endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
      // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
      // unlocked: '', // 解锁集数
      // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
    });
  };
  const onVideoEnded = () => {
    if (!dramaInfo.value) return;
    const { drama_id, title, total_episode, source, last_play_episode } = dramaInfo.value;
    const endtime = Date.now();
    vlog({
      dramaid: drama_id, // 剧id
      dramaname: title, // 剧名
      episode: curEpisode.value, // 第N集
      dramasource: source, // 短剧来源 取source字段
      totalepisodes: total_episode, // 剧的总集数
      duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
      viewduration: xgPlayer.value?.currentTime, // 进度条时间
      operate: '4', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
      bigsource: bigsource.value, // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
      playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
      starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
      endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
      // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
      // unlocked: '', // 解锁集数
      // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
    });
  };

  // 1: 解锁弹窗（支付弹窗）2：解锁成功
  function unlockVLog(operate: '1' | '2') {
    if (!dramaInfo.value) return;
    const { drama_id, title, total_episode, source, last_play_episode } = dramaInfo.value;
    // const endtime = Date.now();
    vlog({
      dramaid: drama_id, // 剧id
      dramaname: title, // 剧名
      episode: curEpisode.value, // 第N集
      dramasource: source, // 短剧来源 取source字段
      totalepisodes: total_episode, // 剧的总集数
      // duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
      // viewduration: xgPlayer.value?.currentTime, // 进度条时间
      operate: operate, // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
      bigsource: bigsource.value, // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
      playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
      unlocked: operate == '2' ? '1' : '', // 解锁集数
      // starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
      // endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
      // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
      // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
    });
  }

  function initPlayer(domId: number, url: string) {
    !isPro && console.log(domId, url, '<<< domId, url');
    nextTick(async () => {
      const playerOptions: IPlayerOptions = {
        id: `video${domId}`,
        url: url,
        autoplay: true, //自动播放
        loop: false, //循环播放
        plugins: isIOS ? [] : [HlsJsPlayer],
        poster: dramaInfo.value?.cover,
        icons: {
          startPlay: `<img src="${videoIcon}"/>`,
        },
        closeVideoDblclick: true,
        mobile: {
          closedbClick: true,
          focusVideoClick: true,
          gradient: 'none',
        },
        start: {
          isShowPause: true,
        },
        hls: {
          preloadTime: 20, // 默认值
          autoStartLoad: true,
          startPosition: 0,
          maxBufferLength: 40,
        },
        playsinline: true,
        fitVideoSize: 'fixWidth',
        videoFillMode: 'contain', // video画面填充模式
      };
      xgPlayer.value = new Player(playerOptions);
      // 加载
      xgPlayer.value.on(Events.LOAD_START, onVideoLoadStart);
      // 加载完成
      xgPlayer.value.on(Events.LOADED_DATA, onVideoLoadedData);
      //播放
      xgPlayer.value.on(Events.PLAY, onVideoPlay);
      //暂停
      xgPlayer.value.on(Events.PAUSE, onVideoPause);
      // 播放结束
      xgPlayer.value.on(Events.ENDED, onVideoEnded);
    });
  }

  function playerDestroy() {
    if (xgPlayer.value) {
      xgPlayer.value.destroy();
    }
  }

  return { drama_id, curEpisode, xgPlayer, dramaInfo, unlockVLog, initPlayer, playerDestroy };
}
