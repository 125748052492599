<template>
  <div class="video-swipe" ref="videoSwipeRef">
    <div ref="elTitle" class="back" @click="handleGoBack">
      <img src="@/pages/index/imgs/video/write-back.png" />
      <div class="drama-title">{{ dramaInfo?.title }}</div>
      <div class="drama-episode">{{ curEpisode }}/{{ dramaInfo?.total_episode }}</div>
    </div>

    <swiper
      :direction="'vertical'"
      :initialSlide="initialSwipe"
      :threshold="20"
      :touch-ratio="1.5"
      :touch-angle="45"
      :allow-slide-next="allowSlideNext"
      @init="onSwiperInit"
      @slideChange="onSlideChange"
    >
      <swiper-slide class="video-swipe-item" v-for="(item, index) in total_episode" :key="index">
        <template v-if="curEpisode == item">
          <div :id="`video${item}`" class="video-player">
            <div
              class="video-lock"
              v-if="item >= lockepisodestart && !payStore.isVip"
              @touchstart="handleLockTouchStart"
              @touchmove="handleLockTouchMove"
              @touchend="handleLockTouchEnd"
              @click="handleLockClick"
            >
              <img :src="dramaInfo?.cover" />
              <div class="video-lock-mask"><i class="icon"></i></div>
            </div>
          </div>

          <div class="video-operate" ref="videoOptRef">
            <div class="video-cover">
              <img :src="dramaInfo?.cover" />
            </div>
            <div class="video-collect" @click.stop="handleDramaCollect">
              <img :src="getAssetURL(`collect${dramaInfo?.collected ? '-a' : ''}.png`)" />
              <div>{{ dramaCollectNum }}</div>
            </div>
            <div class="video-select" @click.stop="openSelectDrama">
              <img :src="getAssetURL('drama-icon.png')" />
              <div style="max-width: 50px">{{ $t('video.select') }}</div>
            </div>
            <div class="video-share" @click.stop="openShareDrama">
              <img :src="getAssetURL('share.png')" />
              <div>{{ $t('record.btn.share') }}</div>
            </div>
            <!-- 后台控制指定集数才开始出现福袋图标 -->
            <!-- <div
              v-if="curEpisode >= commonConfigStore.dr_ep_num"
              class="video-gift"
              @click.stop="handleGiftClick"
            >
              <img :src="getAssetURL('gift.png')" />
            </div> -->
          </div>
        </template>
      </swiper-slide>
    </swiper>
  </div>
  <!-- 非pwa环境下以及第二集才展示该按钮 -->
  <div v-if="shouldShowDownload" class="download" @click.stop="handleAddToScreen">
    <img :src="pngLogo" alt="" />
    <span v-if="isChromeOrSamsung">{{ downloadBtnText }}</span>
    <span v-else>{{ $t('download.btn1') }}</span>
  </div>
  <!-- 剧集选择弹框 -->
  <select-drama
    :show="showSelectDrama"
    :nums="total_episode"
    :title="dramaInfo?.title"
    :curIndex="curEpisode"
    :lockEpisodeStart="lockepisodestart"
    @hide-select="hideSelectDrama"
    @select-drama="playDrama"
  ></select-drama>
  <!-- 分享 -->
  <share :show="showShare" :shareInfo="shareInfo" @hide="showShare = false"></share>
  <!-- 引导下载 -->
  <guide :show="showGuide" @hide="showGuide = false" />
  <!-- 引导手势滑动 -->
  <guide-slide v-if="showSwipeGuide" @hide="showSwipeGuide = false"></guide-slide>
  <!-- 免费解锁 -->
  <free-unlock
    v-if="showDownload"
    :show="showFreeUnlock"
    :type="freeUnlockHideLogType"
    @hide="handleFreeUnlockHide"
  />
  <auto-login
    v-model:visible="visibleAutoLogin"
    :from="autoLoginFrom"
    :cur-episode="curEpisode"
    @close="reloadPage"
    @ok="handleAutoLoginClose"
  />
  <guess-like v-model:visible="visibleGuessLike" :current-drama-id="drama_id" />

  <!-- 支付弹框 -->
  <select-pay v-model:visible="showSelectPay" @hide="hideSelectPay" @gopay="handleVideoNowPay" />
  <!-- 支付方式 -->
  <pay-way v-model:visible="showPayWay" :goods="payGoods" />
  <!-- 支付结果确认弹框 -->
  <pay-result
    v-model:visible="showPayResult"
    :order_id="payOrderId"
    :entrance_id="drama_id"
    @pay="userPayResult"
  />
  <!-- 视频页面支付挽留弹框 -->
  <pay-hold-video
    v-model:visible="showVideoPayHold"
    :location="payStore.jump_location"
    :goods="payStore.retentionGoodsNew"
    @gopay="handleVideoNowPay"
  />

  <!-- <web-push v-model:visible="visibleWebPush" @close="handleWebPushClose"></web-push> -->
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInstance } from 'swiper';
import { ref, onMounted, onUnmounted } from 'vue';
import SelectDrama from './component/select-drama.vue';
import SelectPay from './component/select-pay.vue';
import GuideSlide from './component/guide-slide.vue';
// import WebPush from './component/web-push.vue';
import FreeUnlock from '@/components/free-unlock/index.vue';
import Share from '@/components/share/index.vue';
import Guide from '@/components/guide/index.vue';
import PayResult from '../pay/component/pay-result.vue';
import PayHoldVideo from '../pay/component/pay-hold-video.vue';
import AutoLogin from './component/auto-login.vue';
import GuessLike from './component/guess-like/index.vue';
import PayWay from '../pay/component/pay-way.vue';
import { Events } from 'xgplayer';
import 'xgplayer/dist/index.min.css';
import {
  getDramaInfos,
  getEpisodeUrl,
  getUnlockEpisode,
  collection,
} from '@/pages/index/api/index';
import xl from '@/utils/log';
import pngLogo from '../../imgs/logo.png';
import { wakeUp, installPopSubactid, isPwa, encrypt, getCurrentLang } from '@/utils';
import { usePwa } from '@/composables/usePwa';
import { GUIDE_FLAG, TOKTV_DRAMA_NUM_FLAG, TOKTV_MOVIETASK_ID } from '../../keys';
import { usePayStore } from '@/pages/index/store/modules/pay';
import { useTaskStore } from '@/pages/index/store/modules/task';
import { isChromeOrSamsung, isIOS, isMeta, isPro } from '@/utils/is';
import { useUserStore } from '../../store/modules/user';
import { ShareItem } from '@/components/share/type';
import { usePlayer } from './usePlayer';
import { usePay } from '../pay/usePay';
import { vlog } from '@/utils/vlog';
import HlsPlugin, { Hls } from 'xgplayer-hls';
import { locale2lang } from '@/locale/lang';
import { useCommonConfigStore } from '../../store/modules/common-config';
import { backRetain } from '@/utils/auth';

const {
  handleInstall,
  installing,
  showDownload,
  getHref,
  percentNum,
  installFlag,
  isInstalled,
  handleOpen,
} = usePwa();
const { enter } = useFullscreen(document.documentElement);
const getAssetURL = (image: string) => {
  return new URL(`../../imgs/video/${image}`, import.meta.url).href;
};

const { t } = useI18n();
const { drama_id, curEpisode, dramaInfo, xgPlayer, initPlayer, playerDestroy, unlockVLog } =
  usePlayer();
const {
  payOrderId,
  showPayWay,
  payGoods,
  showPayResult,
  showSelectPay,
  showFreeUnlock,
  showVideoPayHold,
  freeUnlockHideLogType,
  handlePay,
  hideSelectPay,
  handleFreeUnlockHide,
  userPayResult,
} = usePay(() => reloadPage());
const route = useRoute();
const router = useRouter();
const payStore = usePayStore();
const taskStore = useTaskStore();
const drama_source = computed(() => (route.query.source as string) || 'wxs');
const total_episode = computed(() => (dramaInfo.value ? Number(dramaInfo.value.total_episode) : 0));
const guideFlag = useStorage(GUIDE_FLAG, true);
const showSwipeGuide = ref(false);
const showShare = ref(false);
const showGuide = ref(false);
const visibleAutoLogin = ref(false);
const visibleGuessLike = ref(false);
// const visibleWebPush = ref(false);
const loadingDramaInfo = ref(false);
const initialSwipe = computed(() => curEpisode.value - 1);
const downloadBtnText = computed(() => {
  if (installing.value) {
    return percentNum.value > 99
      ? t('download.btn3')
      : `${t('download.btn2')}...${percentNum.value}%`;
  } else {
    return installFlag.value ? t('download.btn3') : t('download.btn1');
  }
});
const lockepisodestart = ref(0);
const allowSlideNext = computed(() => payStore.isVip || curEpisode.value < lockepisodestart.value);
const showSelectDrama = ref(false);
const videoSwipeRef = ref<HTMLDivElement>();
const swiperRef = ref<SwiperInstance>();
const elTitle = ref<HTMLElement>();
const videoOptRef = ref<HTMLElement[]>();
const dramaCollectNum = computed(() => {
  return `${(Number(dramaInfo.value?.total_collect_num) / 10000).toFixed(1)}w`;
});
const shareInfo = ref<ShareItem>({
  drama_id: '',
  drama_num: '',
  title: '',
  source: '',
});

const shouldShowDownload = computed(() => {
  return showDownload.value && !loadingDramaInfo.value && curEpisode.value >= 2;
});

const bigsource = computed(() => (route.query.bigsource as string) || '9');
const dramaNumFlag = useStorage(TOKTV_DRAMA_NUM_FLAG, false, sessionStorage);

const userStore = useUserStore();
const movieTaskId = useStorage(TOKTV_MOVIETASK_ID, '', sessionStorage);
let initSlideChangeFlag = true;

// const commonConfigStore = useCommonConfigStore();
const autoLoginFrom = ref<string | undefined>();

const visibility = useDocumentVisibility();

// 记录开始播放时间
let starttime = 0;

const handleGoBack = async () => {
  xl.act({ actentryid: '130011', subactid: '', type: 'click' });
  guessLikeLogic();
};

// 返回猜你喜欢 & 去首页逻辑
function guessLikeLogic() {
  if (backRetain.value) {
    // 猜你喜欢逻辑
    visibleGuessLike.value = true;
  } else {
    xl.act({ actentryid: '800001', subactid: drama_id.value, type: 'close' });
    router.replace('/');
  }
}

// function handleWebPushClose() {
//   guessLikeLogic();
// }

const handleAddToScreen = () => {
  if (installing.value) return;
  xl.act({ actentryid: '800007', subactid: installPopSubactid(), type: 'click' });
  if (isIOS) {
    if (isMeta) {
      // meta环境：直接引导到appStore下载APP
      window.location.href = 'https://apps.apple.com/us/app/toktv/id6464023756';
    } else {
      // 如果是ios打开，弹出引导动画（注意区分语言）
      // 1. 判断是否safari浏览器，
      // 否：弹出引导safari打开的动画，
      // 是：弹出引导添加到主屏幕动画 （有一张静态图优先展示，gif加载完替换静态图）
      showGuide.value = true;
      xl.act({ actentryid: '800008', subactid: '2', type: 'show' });
    }
  } else {
    // 如果是安卓打开，直接调用安装方法
    if (isInstalled.value) {
      handleOpen();
    } else {
      handleInstall();
    }
    if (isChromeOrSamsung) {
      xl.act({ actentryid: '800008', subactid: '1', type: 'show' });
    }
  }
};

//剧集选择弹框
function openSelectDrama() {
  xl.act({ actentryid: '800004', type: 'click' });
  showSelectDrama.value = true;
}
//剧集分享
function openShareDrama() {
  xl.act({ actentryid: '800005', type: 'click' });
  showShare.value = true;
  shareInfo.value = {
    drama_id: drama_id.value,
    drama_num: curEpisode.value,
    title: dramaInfo.value ? dramaInfo.value.title : '',
    source: drama_source.value,
  };
}
//剧集收藏
function handleDramaCollect() {
  xl.act({ actentryid: '800003', type: 'click' });
  collection({ drama_id: drama_id.value, collected: dramaInfo.value?.collected == 0 ? 1 : 0 })
    .then((res: any) => {
      dramaInfo.value!.collected = dramaInfo.value?.collected == 0 ? 1 : 0;
    })
    .catch((err: any) => {
      // if (err.code == '-1001') {
      //   router.push('/login');
      // } else {
      // showToast(err.message);
      // }
    });
}

// function handleGiftClick() {
//   // 先判断是否已登录，如果未登录，需要先登录。
//   if (userStore.isLogin) {
//     jumpForShare();
//   } else {
//     // 未登录，弹出自动登录
//     visibleAutoLogin.value = true;
//     autoLoginFrom.value = 'gift';
//   }
// }

function handleAutoLoginClose() {
  if (autoLoginFrom.value === 'gift') {
    jumpForShare();
  }
}

function jumpForShare() {
  const params = {
    id: userStore.state.loginInfo.accid, // accid
    ua: navigator.userAgent, // user-agent
    did: drama_id.value, // 剧ID
    lang: locale2lang(getCurrentLang()), // 语言
    ts: Date.now(),
  };
  // 测试：http://letui.shpuxingtop.com/lslt-h5__test/?code=xxx
  // 正式：https://k.toktv.cc?code=xxx
  const url =
    import.meta.env.VITE_APP_ENV === 'production'
      ? `https://k.toktv.cc`
      : `http://letui.shpuxingtop.com/lslt-h5__test/`;
  const jumpUrl = `${url}?code=${encodeURIComponent(encrypt(JSON.stringify(params)))}`;
  // console.log({ jumpUrl });
  xgPlayer.value?.pause();
  location.href = jumpUrl;
}

// //隐藏选择剧集弹框
function hideSelectDrama() {
  showSelectDrama.value = false;
}

//选择剧集
function playDrama(num: number) {
  // 自动播放下一集时不需要上报上一集的暂停。
  // !!!（因为选集之前用户已经暂停了操作，所以不需要再上报暂停了）
  // if (!autoplay) {
  //   reportPauseVLog(curEpisode.value);
  // }
  showOptions();
  playerDestroy();
  curEpisode.value = num;
  checkStatus();
}
//获取剧集详情
const getDramaInfoReq = async () => {
  loadingDramaInfo.value = true;
  const apiResult = await getDramaInfos({
    drama_id: drama_id.value,
    source: drama_source.value,
  });
  loadingDramaInfo.value = false;
  dramaInfo.value = {
    drama_id: apiResult.data.drama_id,
    title: apiResult.data.title,
    source: apiResult.data.source,
    last_play_episode: apiResult.data.last_play_episode,
    total_episode: apiResult.data.total_episode,
    cover: apiResult.data.cover,
    collected: apiResult.data.collected,
    total_collect_num: apiResult.data.drama_lcw.collect,
  };
  lockepisodestart.value = Number(apiResult.data.lock_episode_start);
  checkStatus();
};

//获取单集播放地址
const checkStatus = async () => {
  if (isMeta) {
    // facebook平台
    getEpisodeUrlReq();
    // 锁定状态（弹窗下载更多引导到chrome打开）
    if (curEpisode.value >= lockepisodestart.value) {
      showFreeUnlock.value = true;
    } else if (curEpisode.value >= 3) {
      if (isIOS) {
        showFreeUnlock.value = true;
      } else {
        // （从第三集开始免费开始引导到chrome打开）
        xl.act({ actentryid: '800008', subactid: `${curEpisode.value}`, type: 'show' });
        wakeUp(getHref());
      }
    }
  } else {
    // 非facebook平台
    // 判断当前集是否锁定状态
    // 如果是锁定状态，再判断用户是否登录
    //    已登录，直接请求接口获取剧集信息
    //    未登录，（生成账密或缓存有账密）走自动登录逻辑
    // 如果是非锁定状态，则直接请求接口获取剧集信息
    if (curEpisode.value >= lockepisodestart.value) {
      // 锁定状态
      if (userStore.state.loginInfo.token) {
        // 已登录
        if (payStore.isVip) {
          getEpisodeUrlReq();
        } else {
          // 判断当前集和锁定集数
          !isPro && console.log(curEpisode.value, lockepisodestart.value, '<<<< 当前集和锁定集数');
          if (payOrderId.value) {
            getUnlockEpisodeFn(lockepisodestart.value);
          } else {
            getUnlockEpisodeFn(curEpisode.value);
          }
        }
      } else {
        // 未登录 且 非facebook平台
        // 弹窗自动登录的信息
        visibleAutoLogin.value = true;
      }
    } else {
      getEpisodeUrlReq();
    }
  }
};

// 获取单集播放地址等信息
const getEpisodeUrlReq = async () => {
  try {
    const apiResult = await getEpisodeUrl({
      drama_id: drama_id.value,
      episode: curEpisode.value,
      source: drama_source.value,
      isErr: 1,
    });
    // 有权限看
    !isPro && console.log('获取单集播放地址====>', apiResult);
    // const timer = setTimeout(() => {
    //   clearTimeout(timer);
    //   initPlayer(curEpisode.value, apiResult.data.url);
    // }, 10);
    initPlayer(curEpisode.value, apiResult.data.url);
  } catch (err: any) {
    // 无权限观看
    console.error(err);
  }
};

// 解锁剧集
async function getUnlockEpisodeFn(episode: number) {
  try {
    const res = await getUnlockEpisode({
      drama_id: drama_id.value,
      episode,
      source: 'wxs',
      vip_unlock: payStore.memberInfo.is_vip,
      use_k: 1,
      isErr: 1,
    });
    // 解锁剧集播放日志
    unlockVLog('2');
    // 解锁之后跳转到当前解锁视频
    !isPro && console.log('解锁剧集', res);
    const {
      k_value,
      g_value,
      spend_type,
      lock_episode_start,
      unlock_episode_urls,
      unlock_episodes,
    } = res.data;
    xl.act({
      actentryid: '120002',
      subactid: `${drama_id.value}-${episode}-${spend_type}`,
      type: 'click',
    });
    payStore.memberInfo = { ...payStore.memberInfo, k_value, g_value };
    lockepisodestart.value = Number(lock_episode_start);
    curEpisode.value = episode;
    initPlayer(episode, unlock_episode_urls[`${episode}`]);
  } catch (err) {
    // 不按顺序解锁则按要开始锁定的起始集数数字解锁
    console.log('解锁剧集err:', { err, lockepisodestart: lockepisodestart.value });
    // 如果没有展示支付结果弹窗，因为这里有可能是从三方支付页面返回回来的逻辑。
    if (!showPayResult.value) {
      showPay();
    }
  }
}
//视频页面支付
const handleVideoNowPay = (goods: PayType.PlyItemFormat) => {
  //如果只有一种支付方式则直接调用接口创建订单，否则让用户选择支付方式
  if (payStore.choosePayments.length > 1) {
    payGoods.value = goods;
    showPayWay.value = true;
  } else {
    handlePay(goods);
  }
};

// 上报短剧的暂停日志
// episode: 上报集数。
function reportPauseVLog(episode: string | number) {
  if (!dramaInfo.value) return;
  const { drama_id, title, total_episode, source } = dramaInfo.value;
  const endtime = Date.now();
  // const viewduration = endtime - starttime;
  vlog({
    dramaid: drama_id, // 剧id
    dramaname: title, // 剧名
    episode: episode, // 第N集
    dramasource: source, // 短剧来源 取source字段
    totalepisodes: total_episode, // 剧的总集数
    playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
    duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
    viewduration: xgPlayer.value?.currentTime, // 进度条时间
    operate: '5', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
    bigsource: bigsource.value, // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
    starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
    endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
    // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
    // unlocked: '', // 解锁集数
    // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  });
}

function onSwiperInit(swiper: SwiperInstance) {
  !isPro && console.log('[swiper onSwiperInit]', swiper.realIndex, curEpisode.value, swiper);
  swiperRef.value = swiper;
}

//swipe change
function onSlideChange(swiper: SwiperInstance) {
  if (!initSlideChangeFlag) {
    !isPro && console.log(swiper.realIndex, curEpisode.value, '<<< [swiper onSlideChange]');
    swiperRef.value = swiper;
    const index = swiper.realIndex;
    reportPauseVLog(curEpisode.value);
    curEpisode.value = index + 1;
    !isPro && console.log('$$$$ onSlideChange', curEpisode.value);
    playerDestroy();
    showOptions();
    checkStatus();
  }
}

function showPay() {
  showSelectPay.value = true;
  unlockVLog('1');
}

const startY = ref(0);
const deltaY = ref(0);

function handleLockTouchStart(event: TouchEvent) {
  startY.value = event.touches[0].clientY;
  deltaY.value = 0;
  !isPro && console.log('$$$$ handleLockTouchStart', startY.value);
}

function handleLockTouchMove(event: TouchEvent) {
  deltaY.value = event.touches[0].clientY - startY.value;
}

function handleLockTouchEnd(event: TouchEvent) {
  !isPro && console.log('$$$$ handleLockTouchEnd', deltaY.value);
  if (deltaY.value < 0) {
    if (isMeta) {
      showFreeUnlock.value = true;
    } else {
      showPay();
    }
  }
}

function handleLockClick() {
  !isPro && console.log('$$$$ handleLockClick');
  if (isMeta) {
    showFreeUnlock.value = true;
  } else {
    showPay();
  }
}

function showOptions() {
  if (elTitle.value?.classList.contains('hide')) {
    elTitle.value?.classList.remove('hide');
  }
  if (videoOptRef.value) {
    if (videoOptRef.value[0].classList.contains('hide')) {
      videoOptRef.value.forEach((item) => item.classList.remove('hide'));
    }
  }
}
function hideOptions() {
  if (!elTitle.value?.classList.contains('hide')) {
    elTitle.value?.classList.add('hide');
  }
  if (videoOptRef.value) {
    if (!videoOptRef.value[0].classList.contains('hide')) {
      videoOptRef.value.forEach((item) => item.classList.add('hide'));
    }
  }
}

function reloadPage() {
  // 销毁播放器
  playerDestroy();
  getDramaInfoReq();
}

async function initPage() {
  checkOrderShowResult();

  const { episode, drama_num } = route.query;
  // 推荐页过来标志
  if (episode) {
    showSelectDrama.value = true;
  }
  // 剧集数（只首次进来取url上面的drama_num）
  if (!dramaNumFlag.value && drama_num) {
    curEpisode.value = Number(drama_num);
    dramaNumFlag.value = true;
  }

  // 销毁播放器
  playerDestroy();
  getDramaInfoReq();
  payStore.getGoodsList();
}

function checkOrderShowResult() {
  // 如果存在订单ID，则弹出确认支付状态弹框。
  if (payOrderId.value) {
    showPayResult.value = true;
  }
}

watch(
  curEpisode,
  (c) => {
    !isPro && console.log(c, '<<< [watch] curEpisode !!!');
    swiperRef.value?.slideTo(c - 1, 0, false);
  },
  { immediate: true }
);

watch(xgPlayer, (player) => {
  if (player) {
    //播放
    player.on(Events.PLAY, (self: any) => {
      !isPro && console.log('视频播放了:', self);
      starttime = Date.now();
      hideOptions();
      //在播放时引导用户手势滑动（只引导一次）
      if (guideFlag.value) {
        showSwipeGuide.value = true;
        guideFlag.value = false;
      }
      if (!(import.meta.env.DEV || isPwa())) {
        enter();
      }
    });
    //暂停
    player.on(Events.PAUSE, (self: any) => {
      !isPro && console.log('视频暂停了:', self);
      showOptions();
    });
    // 播放结束
    player.on(Events.ENDED, (self: any) => {
      !isPro && console.log('播放结束了:', self);
      const value = Number(`${curEpisode.value}`) + 1;
      if (dramaInfo.value && value <= Number(dramaInfo.value.total_episode)) {
        playDrama(value);
      }
      if (movieTaskId.value) {
        taskStore.reqReportTaskSuc(movieTaskId.value, `${drama_id.value}-${curEpisode.value}`);
      }
    });
  }
});

watch(
  () => route.query,
  (query) => {
    !isPro && console.log(query, '<<< watch route.query');
    // 隐藏【猜你喜欢】的拦截弹窗
    visibleGuessLike.value = false;
    backRetain.value = true;
    reloadPage();
    // router.go(0);
  }
);

watch(
  shouldShowDownload,
  (v) => {
    if (v) {
      // 下载按钮show日志
      xl.act({ actentryid: '800007', subactid: installPopSubactid(), type: 'show' });
    }
  },
  { immediate: true }
);

watch(visibility, (v) => {
  console.log('[document visibility] >> ', v);
  if (v === 'visible') {
    checkOrderShowResult();
  }
});

onMounted(async () => {
  console.log('[document visibility onMounted]');
  initSlideChangeFlag = false;
  nextTick(() => {
    xl.act({ actentryid: '100017', subactid: HlsPlugin.isSupported() ? '1' : '2', type: 'click' });
    // 上报打点日志
    xl.act({ actentryid: '800001', subactid: drama_id.value, type: 'show' });
  });
  backRetain.value = true;
  if (!movieTaskId.value) {
    //如果用户未从看剧任务入口跳转，需要存一个不变的ID，用于看剧任务上报
    movieTaskId.value = '7';
  }
  initPage();
  // commonConfigStore.fetchCommonConfig();
});
onUnmounted(() => {
  playerDestroy();
});
</script>

<style lang="scss" scoped>
.hide {
  display: none !important;
}

.video-swipe {
  position: relative;
  width: 100vw;
  height: 100vh;

  .back {
    position: fixed;
    top: 30px;
    left: 18px;
    z-index: 900;
    display: flex;
    align-items: center;

    img {
      width: 22px;
      height: 22px;
    }

    .drama-title {
      flex: 1;
      max-width: 400px;
      color: #fff;
      font-weight: 500;
      font-size: 17px;
      word-break: normal;
    }

    .drama-episode {
      margin-left: 7px;
      padding-right: 20px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
    }
  }
}

.video-swipe-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #000;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: inline-block;
    width: 100vw;
    height: 85px;
    margin: auto;
    content: '';
  }

  &::after {
    top: inherit;
    bottom: 0;
  }

  .video-player {
    width: 100%;
    height: 100%;
  }

  .video-operate {
    position: absolute;
    right: 10px;
    bottom: 140px;
    z-index: 9;

    .video-cover {
      margin: 0 auto 23px;

      img {
        display: block;
        width: 43px;
        height: 43px;
        margin: 0 auto;
        object-fit: cover;
        background: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
      }
    }

    .video-collect,
    .video-select,
    .video-share,
    .video-gift {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-size: 13px;

      &.video-collect {
        margin-bottom: 18px;
      }

      &.video-select {
        margin-bottom: 18px;
      }

      &.video-share {
        margin-bottom: 18px;
      }

      &.video-gift {
        img {
          animation: shake 2s infinite;
          animation-timing-function: ease-in-out;
        }
      }

      img {
        width: 30px;
        height: 30px;
        margin-bottom: 7px;
      }
    }
  }

  :deep(.xgplayer) {
    width: 100% !important;
    height: 100% !important;

    .xgplayer-poster {
      background-size: contain;
      // opacity: 0.7;
    }

    .xgplayer-replay {
      opacity: 0;
    }
  }

  :deep(.xgplayer-controls) {
    right: 0;
    bottom: 30px;
    left: 0;
    width: 90%;
    height: 4px;
    margin: 0 auto;
    visibility: inherit !important;
    opacity: 1 !important;

    .xg-left-grid,
    .xg-right-grid {
      display: none !important;
    }

    .xg-pos {
      right: 0;
      left: 0;
    }

    .xg-center-grid,
    .flex-controls {
      padding: 0 !important;
    }

    .xgplayer.xgplayer-mobile .flex-controls .xg-center-grid {
      padding: 0 !important;
    }

    .xg-inner-controls {
      bottom: 0;
      height: 100%;
    }

    .xgplayer-progress {
      height: 4px;

      .xgplayer-progress-outer {
        height: 100%;
      }

      .xgplayer-progress-cache {
        background: rgba(255, 255, 255, 0.3);
      }

      .xgplayer-progress-played {
        background: rgba(255, 255, 255, 1);
      }

      .xgplayer-progress-btn {
        // left: 4px;
        width: 6px;
        height: 6px;
        background: #fff;
        border: none;
        border-radius: 50%;
        box-shadow: -1px 0 1.5px 0 rgba(0, 0, 0, 0.1);

        &::before {
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-15deg);
  }

  30% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.download {
  position: fixed;
  right: 0;
  bottom: 160px;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 210px;
  max-width: 85%;
  height: 46px;
  margin: 0 auto;
  padding: 0 20px;
  color: #fff;
  font-size: 16px;
  background: rgba(244, 25, 79, 0.3);
  border: 0.5px solid rgba(255, 255, 255, 0.7);
  border-radius: 28px;
  animation: breathe 1s infinite alternate;

  /* 设置呼吸灯效果的动画 */

  img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
}

.video-lock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  img {
    width: 100%;
  }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.3);

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      width: 45px;
      height: 56px;
      margin-top: -28px;
      margin-left: -22px;
      background-image: url(../../imgs/video/lock2.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      content: '';
    }
  }
}

@keyframes breathe {
  0% {
    /* 初始状态 */
    transform: scale(1);
  }

  100% {
    /* 最终状态 */
    transform: scale(1.1);
  }
}
</style>
