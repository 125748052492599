<template>
  <div class="content">
    <span class="right first">Version Release Date: June 2024</span>
    <span class="right">Effective Date of this Version: June 2024</span>
    <div class="panel">
      <p>
        Welcome to enter into this "User Service Agreement" (hereinafter referred to as "this
        Agreement") with us and use our platform services. In order to protect your own rights and
        interests, we recommend that you carefully read the specific terms. Before you click to
        agree to this Agreement during the registration process, please carefully read this
        Agreement.
      </p>
      <p>
        Please be sure to read and fully understand the contents of each term, especially the terms
        that exempt or limit liability, applicable laws, and dispute resolution terms. If you have
        any questions about the agreement, you can consult our platform's customer service.
      </p>
      <p>
        When you fill in the information as prompted on the registration page, read and agree to
        this agreement, and complete all registration procedures, it means that you have fully read,
        understood, and accepted all the contents of this agreement, and have reached an agreement
        with us to become a user of Toktv. During the process of reading this agreement, if you do
        not agree to this agreement or any of its terms, you should stop registering and using.
      </p>
      <p>
        If you do not go through the registration process, you can still browse the platform
        content.
      </p>
      <p>I. Definitions</p>
      <p>
        We: The platform operator providing internet video content services, launching the short
        video client ("Toktv" APP), i.e., Hong Kong Xingneng Yongsheng Technology Co., Ltd. We
        provide you with various services based on the internet, including all rules, user service
        agreements, explanations, announcements already published on all our platform websites, and
        those subsequently published, as well as various rules and implementations posted on
        channels, event pages, help centers, announcements, etc., by various platforms.
      </p>
      <p>
        If the actual controller using the same authentication information or multiple user accounts
        is determined by our platform to be the same person, they will be regarded as the same user.
      </p>
      <p>II. Scope of Agreement</p>
      <p>
        Subject of the Contract: This agreement is jointly established by you and has contractual
        effect between you and our platform operator.
      </p>
      <p>
        Our platform operator refers to the legal entity operating our platform. You can determine
        the entity that performs the contract between us and you based on the above definition (see
        1. "We"). According to this agreement, the operator of our platform may undergo changes
        based on adjustments to our platform's business. After the change, the operator of our
        platform will perform this agreement with you and provide services for you. The change of
        the operator of our platform will not affect your rights under this agreement. As we provide
        new platform services, additional platform operators for our platform may also be added. If
        you use the newly added platform services of ours, it is considered that you agree to the
        newly added platform operator of ours to perform this agreement with you. In the event of a
        dispute, you can determine the subject of the contract between you and the opposing party
        based on the specific service you use and the specific behavior affecting your rights.
      </p>
      <p>
        Due to the rapid development of the internet industry, the terms in this agreement signed
        between you and us cannot completely list and cover all rights and obligations between you
        and us, and the existing agreement cannot guarantee full satisfaction of your needs and
        future development between you and us. Therefore, the "Privacy Policy" of our platform and
        the rules of our platform are supplementary agreements to this agreement, inseparable from
        this agreement, and have equal legal effect. If you use our platform services, it is
        considered that you agree to the above supplementary agreements.
      </p>
      <p>
        Changes to the User Service Agreement: Our platform may update the user service agreement,
        privacy policy, and related platform rules (collectively referred to as "User Agreement") on
        our platform. At that time, you will be notified of the update through system prompts,
        information push, and/or contact information you left on our platform. And please be
        reminded: after the changes to the user agreement, if you continue to use our platform
        services, it means that you agree to accept our updated user agreement. If you do not agree
        to the changed user agreement, please stop using our platform products and services.
      </p>
      <p>III. Account Registration and Use</p>
      <p>User Qualifications:</p>
      <p>
        (1) You confirm that before you start using/registering to use our platform services, you
        should have the legal capacity to engage in civil activities suitable for your behavior.
      </p>
      <p>
        (2) If you do not possess the civil capacity to adapt to your behavior, you should obtain
        informed consent from your guardian, and you and your guardian should bear the corresponding
        responsibilities according to the provisions of this agreement. Especially if you are a
        minor, please access and/or use our platform services under the agreement and guidance of
        your guardian.
      </p>
      <p>Registration Information Management</p>
      <p>
        (1) Authentic and Legitimate: When using our platform services, you should accurately and
        completely provide your information according to the prompts on our platform page, so that
        we can contact you when necessary. You understand and agree that you have an obligation to
        maintain the authenticity and validity of the information you provide.
      </p>
      <p>
        (2) Account Name, Nickname, and Avatar: The account name, nickname, and avatar you set must
        not violate laws and regulations, as well as our platform's rules regarding account name
        management. Otherwise, we may suspend the use of your account name or take other actions,
        and report to the relevant authorities.
      </p>
      <p>
        (3) You understand and promise that your account, nickname, avatar, and other registration
        information do not contain illegal or harmful information, do not involve impersonation,
        affiliation with organizations or public figures, comply with laws and regulations, as well
        as the authenticity of information in the account registration process.
      </p>
      <p>Updating and Maintenance</p>
      <p>
        (1) You should update the information you provide in a timely manner. In cases where the law
        explicitly requires us to verify part of the user's information, we will verify and
        authenticate your information periodically according to the law. You should cooperate in
        providing the latest, true, and complete information.
      </p>
      <p>
        (2) If we fail to contact you based on the information you last provided, you fail to
        provide information in a timely manner as required by us, or the information you provided is
        obviously untrue, you bear the losses caused by this. This platform is only responsible for
        corresponding responsibilities in cases explicitly regulated by law.
      </p>
      <p>Account Security Regulations</p>
      <p>
        (1) Your account is set up and maintained by you. Our platform will never actively ask you
        to provide your account password. It is recommended that you protect the security of your
        account, ensure that you log out at the end of each online session, and leave our platform
        following the correct steps.
      </p>
      <p>
        (2) Risks and Consequences: You are responsible for the loss and consequences of your
        account caused by your voluntary disclosure or by attacks or fraud by others. This platform
        is only responsible for corresponding responsibilities in cases explicitly regulated by law.
      </p>
      <p>
        (3) Your account is for your personal use only and may not be rented or shared with others.
        When your account is used without authorization, you should immediately notify our platform.
        Otherwise, unauthorized use will be considered your own behavior, and you will bear all
        losses and consequences arising from it.
      </p>
      <p>
        (4) Except for our fault, you are responsible for all actions taken under your account
        (including but not limited to signing various agreements online, posting information,
        purchasing goods and services, disclosing information, etc.).
      </p>
      <p>
        (5) If you discover any unauthorized use of your account to log in to our platform or any
        other situation that may lead to theft or loss of your account, it is recommended that you
        notify us immediately. You understand that we need a reasonable amount of time to take
        action on any request from you, and we are not responsible for the consequences that occur
        before taking action, except for our legal fault.
      </p>
      <p>
        (6) If you encounter abnormal deductions or other situations that are not caused by your own
        intention, you can actively apply for a refund from us. If the reason for the refund is
        reasonable and genuine, our company will refund your payment.
      </p>
      <p>IV. Prohibited Content</p>
      <p>
        You understand and warrant that the content you upload, publish, or transmit on our platform
        (including your account name and other information) should comply with the Constitution,
        laws, and administrative regulations, uphold correct public opinion guidance, exercise
        public opinion supervision, promote the formation of a positive, healthy, and progressive
        online culture, and safeguard public interests. Our platform has the right to manage the
        information you upload, publish, or transmit. If there is information prohibited by laws or
        administrative regulations from being published or disseminated, or if it contains the
        following content, our platform will immediately stop the dissemination of such information,
        take measures such as removal, prevent information dissemination, keep relevant records, and
        report to the relevant authorities:
      </p>
      <p>(1) Opposes the basic principles established by the Constitution;</p>
      <p>(2) Harms the unity, sovereignty, and territorial integrity of the nation;</p>
      <p>
        (3) Leaks state secrets, endangers national security, or harms national honor and interests;
      </p>
      <p>
        (4) Incites ethnic hatred or discrimination, undermines ethnic unity, or violates ethnic
        customs and habits;
      </p>
      <p>(5) Advocates cults or superstitions;</p>
      <p>(6) Disturbs social order, disrupts social stability;</p>
      <p>
        (7) Induces minors to commit crimes or exaggerates violence, pornography, gambling, and
        terrorist activities;
      </p>
      <p>
        (8) Insults or defames others, infringing on the legal rights and interests of citizens'
        personal reputation, privacy, intellectual property, etc.;
      </p>
      <p>(9) Harms public morality, destroys the fine cultural traditions of the nation;</p>
      <p>
        (10) Contains other content prohibited by laws, administrative regulations, and national
        regulations.
      </p>
      <p>
        If the content you upload, publish, or transmit contains the above information or content
        that violates laws and regulations, or infringes on the legitimate rights and interests of
        any third party, you will directly bear all adverse consequences caused by this. This
        platform is only responsible for corresponding responsibilities in cases explicitly
        regulated by law. If you cause any adverse consequences to us, you should be responsible for
        eliminating the impact and compensating us for all losses incurred, including but not
        limited to property losses, reputation losses, lawyer's fees, transportation expenses, and
        any other reasonable expenses.
      </p>
      <p>Prohibited Actions</p>
      <p>
        You understand and warrant that you will not engage in the following prohibited actions
        related to our platform services, nor will you allow anyone to use your account for the
        following actions:
      </p>
      <p>
        (1) Impersonating others when registering an account or using our platform services, or
        falsely claiming affiliation with any person or entity (including setting up a false account
        name or accessing other users' accounts);
      </p>
      <p>
        (2) Fabricating titles or manipulating content in any other way to mislead others into
        believing that the content is disseminated by us;
      </p>
      <p>
        (3) Uploading, publishing, sending emails, or transmitting content that you are not
        authorized to transmit (such as internal data, confidential data);
      </p>
      <p>
        (4) Sending any unsolicited or unauthorized junk mail or promotional materials, or any other
        commercial communication;
      </p>
      <p>
        (5) Using our platform services for any commercial purpose or for the benefit of any third
        party without our explicit permission;
      </p>
      <p>(6) Tracking or harassing others;</p>
      <p>
        (7) Engaging in any illegal or potentially illegal activities or transactions (we have the
        right to determine user behavior and applicable rules and handle them accordingly),
        including teaching criminal methods, selling illegal drugs, money laundering, fraud, etc.;
      </p>
      <p>
        (8) Gambling, providing gambling information, or in any way inducing others to participate
        in gambling activities;
      </p>
      <p>
        (9) Using or exploiting our intellectual property (including our trademarks, brands, logos,
        any other proprietary data, or any webpage layout or design), or otherwise infringing on any
        of our intellectual property (including attempting to use our platform client or software
        for reverse engineering);
      </p>
      <p>
        (10) Accessing our platform services using any automated program, software, engine, web
        crawler, web analytics tool, data mining tool, or similar tool, to collect or process
        content provided through our platform services;
      </p>
      <p>
        (11) Participating in any activities or transactions designed to imitate the appearance and
        functionality of our platform services through "frames," "mirrors," or other techniques;
      </p>
      <p>
        (12) Interfering with or attempting to interfere with any user or any other party's access
        to our platform services;
      </p>
      <p>
        (13) Intentionally spreading viruses, worms, Trojans, damaging files, or other malicious
        code or items;
      </p>
      <p>
        (14) Sharing or publishing other people's personal identity information without their
        explicit consent;
      </p>
      <p>
        (15) Exploring or testing whether our platform services, systems, or other user systems are
        vulnerable to intrusion attacks, or attempting to bypass (or attempting to bypass) any
        security features of our platform services, systems, or other user systems;
      </p>
      <p>
        (16) Decoding, decompiling, or reverse engineering the software used in our platform
        services, or attempting to do so;
      </p>
      <p>
        (17) Opening multiple accounts for the purpose of destruction or abuse, or maliciously
        uploading repetitive or invalid large-capacity data and information.
      </p>
      <p>
        Engaging in activities over the internet that infringe upon the legitimate rights and
        interests of others, such as reputation, privacy, intellectual property, etc.
      </p>
      <p>
        Intentionally or unintentionally violating any relevant laws, regulations, rules,
        ordinances, and other legally binding norms in China. 。
      </p>
      <p>Modifying or tampering with our platform services and related functions.</p>
      <p>
        Disrupting our platform's copyright protection measures, unauthorized use of our platform's
        content, such as copying, forwarding, content accessible only to paying users, etc.
      </p>
      <p>V. Privacy Policy</p>
      <p>
        When you use our services, you agree that we will collect, store, use, disclose, and protect
        your personal information in accordance with the privacy policy published on our platform.
      </p>
      <p>
        We attach great importance to the protection of user personal information. According to the
        requirements and regulations of relevant laws, if network products and services have the
        function of collecting user information, the provider should make it clear and obtain the
        user's consent. We hereby explicitly inform you of the purpose, method, scope of collection
        and use of user personal information, channels for querying and correcting information, and
        the consequences of refusing to provide information through the separately stated "Privacy
        Policy." We hope to clarify how we handle your personal information through our privacy
        policy, so you should read the "Privacy Policy" in its entirety before using the services we
        provide, in order to better protect your privacy.
      </p>
      <p>
        If you do not agree with any content of the "Privacy Policy," you should immediately stop
        using our platform services. When you use any services provided by our platform, you agree
        that we will lawfully collect, use, and protect your personal information in accordance with
        the "Privacy Policy" of our platform.
      </p>
      <p>
        The term "personal information" in this "Privacy Policy" refers to various types of
        information recorded electronically or by other means that can independently or in
        combination with other information identify the personal identity of a natural person.
      </p>
      <p>
        When the matters listed in the "Privacy Policy" undergo changes, we will promptly update the
        "Privacy Policy" and notify you through system prompts, information pushes, and/or the
        contact information you leave on our platform.
      </p>
      <p>
        If you have any questions about the privacy policy or any complaints about the protection of
        your personal information, you can contact us at [toktvservice@outlook.com].
      </p>
      <p>VI. Intellectual Property</p>
      <p>
        Unless otherwise agreed or stated by us, all content (excluding content for which users hold
        copyrights) on our platform, as well as technologies, software, programs, data, and other
        information (including but not limited to text, images, graphics, photos, all intellectual
        property rights, such as copyrights, trademarks, patents, trade secrets, etc., and audio,
        video, graphics, colors, layouts, related rights to electronic documents) are owned by us or
        our affiliated companies. Without our permission, no one may use them (including but not
        limited to copying, distributing, displaying, mirroring, uploading, downloading, modifying,
        renting).
      </p>
      <p>
        The logos, text, and graphics, as well as combinations thereof, of our platform and platform
        content, as well as other logos, marks, product and service names on our platform, are
        trademarks of ours or our affiliated companies in China or other countries. No one may
        display them in any way without our written authorization. You may not indicate to others
        that you have the right to display, use, or otherwise deal with them.
      </p>
      <p>
        We have full intellectual property rights over our proprietary content, original content,
        and other content obtained through licensing on our platform. Without our permission, any
        unit or individual may not copy, distribute, provide viewing services, or engage in any
        other act that infringes upon our intellectual property rights, otherwise they will bear all
        relevant legal responsibilities.
      </p>
      <p>VII. User Default and Handling</p>
      <p>Determination of default: The following situations are deemed as user default:</p>
      <p>(1) Violating relevant laws and regulations while using our platform services.</p>
      <p>(2) Violating this agreement or its supplementary agreements.</p>
      <p>
        (3) To adapt to the development of the internet industry and meet the demands of users for
        efficient and high-quality services, you understand and agree that we may establish default
        determination procedures and standards in this agreement, as well as platform rules. For
        example, we may judge whether you are in default based on the relationship between your user
        data and a large amount of user data. You have the right to provide sufficient evidence and
        a reasonable explanation for any abnormality in your data, otherwise, it will be considered
        as a default.
      </p>
      <p>Default handling measures:</p>
      <p>
        (1) If the content and information you publish on our platform constitute a default, we have
        the right to immediately delete or block the corresponding content and information in
        accordance with relevant rules, or suspend, seal, or temporarily disable your account login.
        In cases where legal provisions are clearly stipulated, this platform will assume
        corresponding responsibilities.
      </p>
      <p>
        (2) If your behavior on our platform, or behavior that affects our platform and its users
        but is not actually carried out on our platform, constitutes a default, we may implement
        measures against your account according to relevant rules, such as restricting or
        temporarily suspending the provision of some or all services to you. If your behavior
        constitutes a fundamental default, we may seal your account and terminate the provision of
        services to you.
      </p>
      <p>
        (3) If your behavior on our platform violates relevant laws and regulations, we may report
        to the relevant authorities and submit information such as your usage records in accordance
        with the law.
      </p>
      <p>VIII. Liability for Compensation</p>
      <p>
        If your behavior leads to losses suffered by us and/or our affiliated companies (including
        their own direct economic losses, reputation losses, as well as compensation, settlement,
        lawyer fees, litigation fees, and other indirect economic losses), you shall bear the
        responsibility for compensation to us and/or our affiliated companies.
      </p>
      <p>
        If your behavior leads to claims against us and/or our affiliated companies by third
        parties, after we and/or our affiliated companies make monetary payments and fulfill other
        obligations to third parties, we may seek reimbursement from you for all losses.
      </p>
      <p>IX. Special Agreements</p>
      <p>
        We are responsible for providing our platform services to you "as is" and "as available." We
        fulfill corresponding obligations in accordance with the law, but bear no responsibility for
        losses caused by maintenance of information network equipment, connection failures,
        computer, communication or other system failures, hacker activities, computer viruses, power
        outages, strikes, riots, etc. We do not assume any liability for damages caused to you by
        fires, floods, storms, explosions, wars, government actions, orders from judicial and
        administrative authorities, or third-party reasons, except as explicitly provided by law.
      </p>
      <p>X. Notification</p>
      <p>You agree that we will send you various notifications in the following reasonable ways:</p>
      <p>(1) Public announcement content;</p>
      <p>(2) In-platform messages, pop-up messages, client-side push messages.</p>
      <p>
        When you register as a user on our platform and accept our platform services, you should
        provide us with a valid and true contact method (your contact number). If your contact
        method changes, you have an obligation to promptly update the relevant information and
        ensure its accessibility.
      </p>
      <p>
        We will issue various notices to you through one or more of the above contact methods. The
        content of such notices may have significant favorable or unfavorable impacts on your rights
        and obligations, so please pay timely attention.
      </p>
      <p>XI. Delivery of Notices</p>
      <p>
        We will send notices to you through the above contact methods, including but not limited to
        posting announcements on our platform, sending emails, system messages, and considering them
        as successfully delivered upon sending and receipt.
      </p>
      <p>
        You should ensure that the contact information provided is accurate, valid, and promptly
        updated. If the provided contact information is inaccurate or if changes to the contact
        information are not promptly communicated, resulting in the inability to deliver or delayed
        delivery of legal documents, you shall bear any legal consequences that may arise from this.
      </p>
      <p>XI. Termination of Agreement</p>
      <p>You have the right to terminate this agreement in any of the following ways:</p>
      <p>
        (1) When you meet the account cancellation conditions published on our platform, you can
        cancel your account through the platform.
      </p>
      <p>(2) Before the changes take effect, you cease to use and explicitly reject the changes.</p>
      <p>
        (3) You expressly choose not to continue using our platform services and meet the
        termination conditions of our platform.
      </p>
      <p>
        Under the following circumstances, we may notify you of the termination of this agreement in
        accordance with the methods specified in this agreement:
      </p>
      <p>
        (1) You violate this agreement, and we terminate this agreement according to the violation
        clause.
      </p>
      <p>
        (2) If you transfer your account, misuse someone else's account, publish prohibited content
        and information, defraud others of property, or use unfair means, we will seal your account
        according to platform rules.
      </p>
      <p>
        (3) In addition to the above situations, if you repeatedly violate the relevant provisions
        of our platform rules and the circumstances are severe, we will seal your account according
        to our platform rules.
      </p>
      <p>(4) Your account has been canceled in accordance with the provisions of this agreement.</p>
      <p>
        (5) You infringe upon the legitimate rights and interests of others or engage in other
        serious illegal activities on our platform.
      </p>
      <p>(6) Other situations where laws and regulations require us to terminate services.</p>
      <p>Handling after Agreement Termination</p>
      <p>
        (1) After the termination of this agreement, except as explicitly provided by law, we
        generally have no obligation to disclose any information in your account to you or to a
        third party specified by you.
      </p>
      <p>After the termination of this agreement, we have the following rights:</p>
      <p>
        (1) We may delete or anonymize your personal information and may continue to retain other
        content and information you have kept on our platform in accordance with the legal
        requirements for a specified period and in a specified manner.
      </p>
      <p>
        (2) For your past default behavior, we may still pursue your default liability in accordance
        with the provisions of this agreement.
      </p>
      <p>XII. Jurisdiction and Others</p>
      <p>
        Disputes related to our platform services arising from your use of our platform services
        shall be resolved through negotiation between us and you. If negotiation fails, either party
        may file a lawsuit with the people's court at the location of the defendant.
      </p>
      <p>
        If any term of this agreement is deemed invalid, void, or unenforceable, that term shall be
        considered severable and shall not affect the validity and enforceability of the remaining
        terms of this agreement.
      </p>
    </div>
  </div>
</template>
