<template>
  <div class="language">
    <div class="top__bar">
      <img @click="$router.back()" src="../../../imgs/wallet/back.png" alt="" />
      <h3>{{ $t('setting.menu1') }}</h3>
    </div>
    <div class="ul__box">
      <ul>
        <li v-for="item in list" :key="item.id" @click="handleMenuItemEvent(item.id)">
          <div class="lll">
            {{ item.name }}
          </div>
          <img
            :src="
              getAssetURL(`checkbox${item.checkbox || item.key == currentLocale ? '-a' : ''}.png`)
            "
            alt=""
            class="checkbox"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Locale } from 'vant';
import { LocaleLangEnum } from '@/locale/lang';
import useLocale from '@/composables/useLocale';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhHK from 'vant/es/locale/lang/zh-HK';
import viVN from 'vant/es/locale/lang/vi-VN';
import thTH from 'vant/es/locale/lang/th-TH';
import idID from 'vant/es/locale/lang/id-ID';
import enUS from 'vant/es/locale/lang/en-US';
import ptBR from 'vant/es/locale/lang/pt-BR';
import esES from 'vant/es/locale/lang/es-ES';
import xl from '@/utils/log';

const { currentLocale, changeLocale } = useLocale();
const getAssetURL = (image: string) => {
  return new URL(`../../../imgs/setting/${image}`, import.meta.url).href;
};
const list = ref([
  {
    id: 1,
    name: '简体中文',
    checkbox: false,
    key: 'zh-CN',
  },
  {
    id: 2,
    name: 'English',
    checkbox: false,
    key: 'en-US',
  },
  {
    id: 3,
    name: '繁體中文',
    checkbox: false,
    key: 'zh-HK',
  },
  {
    id: 4,
    name: 'Português',
    checkbox: false,
    key: 'pt-BR',
  },
  {
    id: 5,
    name: 'แบบไทย',
    checkbox: false,
    key: 'th-TH',
  },
  {
    id: 6,
    name: 'español',
    checkbox: false,
    key: 'es-ES',
  },
  // {
  //   id: 6,
  //   name: 'Indonesian',
  //   checkbox: false,
  //   key: 'id-ID',
  // },
]);

const setVantLocale = (value: LocaleLangEnum) => {
  switch (value) {
    case LocaleLangEnum.zhCN:
      Locale.use(value, zhCN);
      break;
    case LocaleLangEnum.zhHK:
      Locale.use(value, zhHK);
      break;
    case LocaleLangEnum.viVN:
      Locale.use(value, viVN);
      break;
    case LocaleLangEnum.thTH:
      Locale.use(value, thTH);
      break;
    case LocaleLangEnum.idID:
      Locale.use(value, idID);
      break;
    case LocaleLangEnum.enUS:
      Locale.use(value, enUS);
      break;
    case LocaleLangEnum.ptBR:
      Locale.use(value, ptBR);
      break;
    case LocaleLangEnum.esEs:
      Locale.use(value, esES);
      break;
    default:
      Locale.use(LocaleLangEnum.enUS, enUS);
      break;
  }
};

watch(
  () => currentLocale.value,
  (value) => {
    setVantLocale(value);
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  console.log('currentLocale', currentLocale.value);
  xl.act({ actentryid: '900002', type: 'show' });
});

const handleMenuItemEvent = (id: number) => {
  list.value.map((item: any) => {
    item.checkbox = false;
  });
  const curLanItem: any = list.value[id - 1];
  curLanItem.checkbox = !curLanItem.checkbox;
  changeLocale(curLanItem.key);
  xl.act({ actentryid: '900002', subactid: `${id}`, type: 'click' });
};
</script>
<style scoped lang="scss">
.language {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top__bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    margin: 20px 0;

    h3 {
      font-weight: bold;
      font-size: 18px;
    }

    img {
      position: absolute;
      left: 15px;
      width: 25px;
    }
  }

  .ul__box {
    width: 100%;
    margin-top: 10px;
    padding-top: 1px;
    background: #fff;
    border-radius: 15px 15px 0 0;
  }

  ul {
    margin: 0 15px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 49px;
      border-bottom: 2px solid #f5f5f5;

      &:last-child {
        border-bottom: none;
      }

      .lll {
        display: flex;
        align-items: center;

        img {
          width: 23px;
          margin-right: 9px;
        }

        span {
          color: #222;
          font-weight: 500;
          font-size: 16px;
        }
      }

      .checkbox {
        width: 17px;
      }
    }
  }
}
</style>
