<template>
  <van-popup class="popup-payway" v-model:show="show" position="bottom" :duration="0.2">
    <div class="content">
      <div class="goods-info">
        <img src="@/pages/index/imgs/logo1.png" class="logo" />
        <div class="info">
          <div class="name">
            {{ props.goods.commodityName
            }}{{ props.goods.goods_type == 3 ? `${$t('play.txt.kb')}` : '' }}
          </div>
          <div class="desc">
            {{ props.goods.commodityDesc
            }}{{
              props.goods.goods_type == 3
                ? `+${props.goods.custom?.extra_value}${$t('play.txt.kb')}`
                : ''
            }}
          </div>
        </div>
        <div class="price">{{ props.goods.custom?.unit }}{{ props.goods.price }}</div>
      </div>
      <div class="title">{{ $t('region.choose.wallet') }}</div>
      <div class="payway-list">
        <div
          :class="['item', { active: choosePaywayActive == index }]"
          v-for="(item, index) in payStore.choosePayments"
          :key="index"
          @click="handleChoosePayway(index)"
        >
          <img :src="item.icon" />
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script lang="ts" setup>
import { usePayStore } from '@/pages/index/store/modules/pay';
import { usePay } from '../usePay';
import xl from '@/utils/log';

interface IProps {
  visible: boolean;
  goods: PayType.PlyItemFormat;
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  goods: Object,
});
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    choosePaywayActive.value = -1;
    emit('update:visible', val);
  },
});
const choosePaywayActive = ref(-1);
const payStore = usePayStore();
const { handlePay } = usePay();
const paywayLogMap = {
  PayPal: '110011',
  CashPay: '110012',
  Stripe: '110013',
  PayerMax: '110014',
  OkPay: '110015',
  GooglePay_PayerMax: '110016',
  Card_PayerMax: '110017',
} as { [key: string]: string };

const handleChoosePayway = (index: number) => {
  choosePaywayActive.value = index;
  payStore.user_payment = payStore.choosePayments[index].pay_value;
  handlePay(props.goods);
  xl.act({ actentryid: '110010', type: 'click' });
  xl.act({
    actentryid: paywayLogMap[payStore.choosePayments[index].pay_label],
    subactid: payStore.user_region,
    type: 'click',
  });
};

const paywayShowLog = () => {
  payStore.choosePayments.map((item) => {
    xl.act({
      actentryid: paywayLogMap[item.pay_label],
      subactid: payStore.user_region,
      type: 'show',
    });
  });
};

watch(
  () => show.value,
  (val) => {
    if (val) {
      xl.act({ actentryid: '110010', type: 'show' });
      paywayShowLog();
    }
  }
);
</script>

<style lang="scss" scoped>
.popup-payway {
  border-radius: 15px 15px 0 0;

  .content {
    position: relative;
    width: 100%;
    min-height: 300px;
    padding: 20px 15px;
    background: #fff;

    .goods-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 2px;

      .logo {
        width: 40px;
        height: 40px;
      }

      .info {
        flex: 1;
        padding-left: 13px;

        .name {
          color: #222;
          font-weight: bold;
          font-size: 15px;
        }

        .desc {
          margin-top: 4px;
          color: #999;
          font-size: 12px;
        }
      }

      .price {
        color: #222;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .title {
      margin: 20px 0 10px;
      color: #222;
      font-weight: 500;
      font-size: 14px;
    }

    .payway-list {
      width: 100%;
      min-height: 230px;
      max-height: 370px;
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
        width: 0;
      }

      .item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        margin-bottom: 9px;
        padding-left: 20px;
        background: #fff;
        border: 1px solid #dedede;
        border-radius: 11px;

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          border: 1px solid #ff880d;
        }

        img {
          width: 100px;
          height: 30px;
        }
      }
    }
  }
}
</style>
