import { K_DRAMA_ID, K_LOG_DRAMA_ID, MY_EXTRA_INFO } from '@/pages/index/keys';
import { useUserStore } from '@/pages/index/store/modules/user';
import { wakeUp, mergeSearchParams, isPwa } from '@/utils';
import { curEpisodeMap, getCz, getLaunchSearchParams, getPixelId } from '@/utils/auth';
import { isAndroid, isChromeOrSamsung, isIOS } from '@/utils/is';
import xl from '@/utils/log';

window.toktvDeferredPrompt = null;

export const usePwa = (cacheKey = '') => {
  const KEY_INSTALL_FLAG = `__toktv_pwa_${cacheKey}_install_flag__`;
  const KEY_CAN_INSTALL_FLAG = `__toktv_pwa_${cacheKey}_can_install_flag__`;
  // !!! 后期缓存标记key需要根据应用ID动态变化
  const installFlag = useStorage(KEY_INSTALL_FLAG, '');
  // const deferredPrompt = ref<any>();
  const isInstalled = computed(() => installFlag.value === '1');
  const installing = ref(false);
  const loading = ref(isChromeOrSamsung);
  const percentNum = ref(0);
  const canInstall = useStorage(KEY_CAN_INSTALL_FLAG, false); // ref(false);
  const count = ref(10);
  const userStore = useUserStore();
  watchOnce(isInstalled, (v) => {
    if (v && isPwa()) {
      location.reload();
    }
  });
  const hasInstalled = () => {
    installFlag.value = '1';
    installing.value = false;
    // handleOpen();
  };
  // 安装倒计时（模拟）
  const handleInstallProgress = () => {
    installing.value = true;
    percentNum.value = 0;
    const intervalTimer = setInterval(() => {
      percentNum.value += 1;
      count.value = 10 - Math.floor(10 * percentNum.value * 0.01);
      count.value = count.value <= 0 ? 0 : count.value;
      // console.log(percentNum.value, count.value);
      if (percentNum.value >= 100) {
        clearInterval(intervalTimer);
        hasInstalled();
      }
      // C && (C.innerHTML = `${percent}%`);
      // E && (E.innerHTML = `${count}`);
    }, 120);
  };

  // 接收一个url
  const getHref = (u = location.href) => {
    const { appcqid, uid, accid } = xl.commonParams;
    let newHref = u;
    const tk = userStore.tokenGetter;
    const my_extra_info = tk
      ? {
          // 用户信息
          ui: userStore.state.loginInfo,
          // 公参
          cp: {
            appcqid,
            uid,
            accid,
          },
          // 播放信息
          cem: curEpisodeMap.value,
          // 其他
          cz: getCz(),
          pix: getPixelId(),
        }
      : {
          // 公参
          cp: {
            appcqid,
            uid,
          },
          // 播放信息
          cem: curEpisodeMap.value,
          // 其他
          cz: getCz(),
          pix: getPixelId(),
        };
    console.log(my_extra_info, '<<<< my_extra_info');
    try {
      const myUrl = new URL(newHref);
      let mergedParams = myUrl.searchParams;
      const dramaId = mergedParams.get(K_DRAMA_ID);
      const dramaNum = dramaId ? curEpisodeMap.value[dramaId] : 1;
      const cachedSearchParams = getLaunchSearchParams();
      if (cachedSearchParams) {
        const cached = new URLSearchParams(cachedSearchParams);
        // 用户首次进入时的剧ID
        const logDramaId = cached.get(K_LOG_DRAMA_ID) || cached.get(K_DRAMA_ID);
        if (logDramaId) {
          mergedParams.set(K_LOG_DRAMA_ID, logDramaId);
        }
        mergedParams = mergeSearchParams(cached, mergedParams);
      }
      if (!mergedParams.get('qid') && xl.commonParams.appcqid) {
        mergedParams.set('qid', xl.commonParams.appcqid);
      }
      // 当前播放的剧集
      mergedParams.set('drama_num', dramaNum);
      // 登录、用户信息、公参等信息。
      mergedParams.set(MY_EXTRA_INFO, JSON.stringify(my_extra_info));
      newHref = newHref.split('?')[0] + '?' + mergedParams.toString();
    } catch (error) {
      console.error(error);
    }
    console.log(newHref, ' <<< newHref');
    return newHref;
  };

  // 手动安装 PWA
  const handleInstall = async () => {
    if (!isChromeOrSamsung) {
      wakeUp(getHref());
      return;
    }
    if (window.toktvDeferredPrompt) {
      // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
      window.toktvDeferredPrompt.prompt();
      // Find out whether the user confirmed the installation or not
      const { outcome } = await window.toktvDeferredPrompt.userChoice;
      // The deferredPrompt can only be used once.
      window.toktvDeferredPrompt = null;
      // Act on the user's choice
      if (outcome === 'accepted') {
        handleInstallProgress();
        console.log('[my] User accepted the install prompt.');
      } else if (outcome === 'dismissed') {
        console.log('[my] User dismissed the install prompt');
      }
    } else {
      canInstall.value = false;
    }
  };

  const handleOpen = (url = location.href) => {
    // const localUrl = new URL(location.href);
    // localUrl.searchParams.set('rb_dest', 'scan');
    // localUrl.pathname = `/${e}/__roibest_startup.html`;
    // 'https://demo-prod.roibest.com/9321581570/__roibest_startup.html?channel_id=4
    // &promote_url_id=7395831494&invite_code=&fhl_page=1&fhl_time=1712638871011
    // &link_id=8068438016568430&rb_dest=scan'
    window.open(getHref(url), '_blank');
  };

  const showDownload = computed(() => {
    // 非pwa环境
    if (!isPwa()) {
      // ios环境
      if (isIOS) return true;
      // 安卓环境、能够安装且未安装
      if (isAndroid) {
        if (isChromeOrSamsung) {
          // return canInstall.value && !isInstalled.value;
          return canInstall.value || isInstalled.value;
        } else {
          return true;
        }
      }
    }
    return false;
  });

  onMounted(() => {
    // 监听 beforeinstallprompt 事件
    window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      loading.value = false;
      // 清除安装标记📌
      console.log('[my] clear pwa install flag📌');
      installFlag.value = '';
      // Stash the event so it can be triggered later.
      window.toktvDeferredPrompt = e;
      canInstall.value = true;
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`[my] 🎯 'beforeinstallprompt' event was fired.`, window.toktvDeferredPrompt);
    });

    // 检测 PWA 成功安装的时间
    window.addEventListener('appinstalled', async () => {
      xl.act({ actentryid: '100011', type: 'click' });
      // Clear the deferredPrompt so it can be garbage collected
      window.toktvDeferredPrompt = null;
      // Optionally, send analytics event to indicate successful install
      console.log('[my] PWA was installed🎆');
    });

    // setTimeout(() => {
    //   if (loading.value) {
    //     location.reload();
    //   }
    // }, 3000);
    setTimeout(() => {
      loading.value = false;
    }, 5000);
  });

  return {
    isInstalled,
    installing,
    percentNum,
    count,
    loading,
    getHref,
    handleOpen,
    handleInstall,
    canInstall,
    showDownload,
    installFlag,
  };
};
