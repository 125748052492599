import { hexMD5 } from '@/utils/md5.js';
import wechat from '@/utils/wechat';
import xl from '@/utils/log';
import { signSecretKey, paramsSecretKey } from './config';
import { getCurrentLang, getQueryParam, splitVal } from '@/utils/index';
import { isObject, isPro, isUndefined } from '@/utils/is';
import { getCachedIPInfo, getShareDramaId } from '@/utils/auth';
import { locale2lang } from '@/locale/lang';
import { K_LOG_DRAMA_ID } from '../keys';
import { useUserStore } from '../store/modules/user';

/**
 * 请求数据加密处理
 */
export function paramsEncode(params: object) {
  const ts: any = Number(parseInt(String(new Date().getTime() / 1000)));
  const userStore = useUserStore();
  const lt = userStore.tokenGetter;
  const lang = getCurrentLang();
  const applang = locale2lang(lang);
  const city = getCachedIPInfo()?.city || '';
  const country = getCachedIPInfo()?.country || '';
  // 1、优先获取 url上的 log_drama_id（因为有一个场景是从facebook跳chrome，为了避免和url上的drama_id冲突，故重命名为：log_drama_id）
  // 2、其次获取缓存的剧ID
  // 3、否则置空
  const logDramaId = getQueryParam(K_LOG_DRAMA_ID) || getShareDramaId() || '';
  //公共参数拼接
  const joinParams: { [key: string]: any } = {
    ...xl.commonParams,
    ...params,
    accid: userStore.state.loginInfo.accid || '', // XLog里面含有accid，未清空，会导致accid仍然存在问题。
    ts,
    lt,
    applang,
    city,
    country,
    logDramaId,
  };
  if (!isPro) {
    console.log(xl.commonParams, ' <<< xl.commonParams');
    console.log(joinParams);
    console.groupEnd();
  }
  //key排序
  const sortKeys = Object.keys(joinParams).sort();
  // 按照key的顺序，重新生成新的object
  const sortKeyData: { [key: string]: string } = {};
  for (const key of sortKeys) {
    sortKeyData[key] = joinParams[key];
  }
  //对象key和value以&符号拼接形成字符串
  let joinStr = splitVal(sortKeyData);
  //拼接后的字符串再拼接signSecret
  joinStr = joinStr + signSecretKey;
  //md5加密
  const sign = hexMD5(encodeURIComponent(joinStr));
  const temp: { [key: string]: string } = {};
  for (const k in joinParams) {
    if (joinParams[k] == null) {
      temp[k] = '';
    } else {
      temp[k] = joinParams[k];
    }
  }
  temp['sign'] = sign;
  return wechat.Encode(JSON.stringify(temp), paramsSecretKey);
}

/**
 * post请求参数转化
 */
export function data2formdata(data: object | FormData | undefined) {
  if (isObject(data) || isUndefined(data)) {
    const formdata = new FormData();
    formdata.append('params', paramsEncode(data || {}).replace(/\s/g, '+'));
    return formdata;
  }
  return data;
}
