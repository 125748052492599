<template>
  <div class="recharge-record-index">
    <div class="header">
      <i class="back" @click="() => goBack()"></i>
      <p class="title">{{ $t('play.item.vip') }}</p>
    </div>
    <van-list
      v-model:loading="loading"
      :finished-text="$t('play.txt.more')"
      :finished="finished"
      @load="onLoad"
    >
      <div class="items" v-if="list.length">
        <div class="item" v-for="(item, index) in list" :key="index">
          <p class="title">{{ item?.time_str }}</p>
          <p class="time">
            {{ $t('play.txt.purchase_time') }}：{{ getActivityTime(item?.create_time, -1, false) }}
          </p>
          <p class="numbers">{{ $t('play.txt.order') }}：{{ item?.orderId }}</p>
          <p class="num">{{ item?.amount }}</p>
        </div>
      </div>
    </van-list>
    <div class="empty-con" v-if="isEmpty">
      <div class="empty">
        <div class="img"></div>
        <p class="txt">{{ $t('play.txt.no_recharge') }}~</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
// 会员充值
import { getpayOrderList } from '@/pages/index/api/index';
import { getActivityTime } from '@/utils/create-time';
import { goBack } from '@/pages/index/router';
import { isPro } from '@/utils/is';
interface RechargeListItem {
  accid: string;
  amount: string;
  payment: string;
  valid: string;
  valid_time: number;
  orderId: string;
  create_time: number;
  time_str: string;
}
const loading = ref(false);
const finished = ref(false);
const isEmpty = ref(false);
const page_num = ref(0);
const router = useRouter();
const list = ref<RechargeListItem[]>([]);
onMounted(() => {});
function onLoad() {
  // ++page_num.value;
  getList();
}

// 会员订单列表
async function getList() {
  if (finished.value) return;

  loading.value = true;
  try {
    const res = await getpayOrderList({});
    !isPro && console.log('res:', res);
    // const ret = Array.isArray(data) ? data : [];
    // // list.value = page_num.value === 1 ? ret : [...list.value, ...ret];
    // list.value = ret
    list.value = res.data;
    finished.value = true;
    isEmpty.value = list.value.length <= 0;
  } catch (error) {
    finished.value = true;
  }
  loading.value = false;
}
</script>

<style lang="scss" scoped>
.recharge-record-index {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  background: #f5f5f5;

  .header {
    position: relative;
    width: 100%;
    height: 40px;
    background: #fff;

    .back {
      position: absolute;
      top: 50%;
      left: 15px;
      width: 20px;
      height: 20px;
      background: url('@/pages/index/imgs/play/back1.png') no-repeat top center;
      background-size: 100% 100%;
      transform: translateY(-50%);
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      width: max-content;
      color: #222;
      font-weight: bold;
      font-size: 18px;
      font-family: 'Source Han Sans CN';
      transform: translate(-50%, -50%);
    }
  }

  .items {
    width: 351px;
    margin: 0 auto;
    padding: 20px 0;

    .item {
      position: relative;
      width: 100%;
      height: 97px;
      margin-bottom: 12px;
      background: #fff;
      border-radius: 10px;

      .title {
        position: absolute;
        top: 19px;
        left: 19px;
        color: #222;
        font-weight: 500;
        font-size: 18px;
        font-family: 'Source Han Sans CN';
        white-space: nowrap;
      }

      .time {
        position: absolute;
        top: 47.5px;
        left: 19px;
        color: #999;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Source Han Sans CN';
        white-space: nowrap;
      }

      .numbers {
        position: absolute;
        top: 68.5px;
        left: 19px;
        color: #999;
        font-weight: 400;
        font-size: 14px;
        font-family: 'Source Han Sans CN';
        white-space: nowrap;
      }

      .num {
        position: absolute;
        top: 16.5px;
        right: 15.5px;
        color: #ff3666;
        font-weight: 500;
        font-size: 18px;
        font-family: 'Source Han Sans CN';
        white-space: nowrap;
      }
    }
  }

  .empty-con {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    background: #f5f5f5;
  }

  .empty {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 147px auto 0;

    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      height: 200px;
      background: url('../../../imgs/empty.png') no-repeat top center;
      background-size: 100% 100%;
    }

    .txt {
      position: absolute;
      top: 165px;
      left: 50%;
      color: #bebebe;
      font-weight: 400;
      font-size: 13px;
      font-family: 'Source Han Sans CN';
      transform: translateX(-50%);
    }
  }
}
</style>
