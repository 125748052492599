// Google login
import xl from '@/utils/log';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

export async function googleLogin() {
  const firebaseConfig = {
    apiKey: 'AIzaSyA214GNibdCdK65MIlSji1S_0grvhw_PcI',
    authDomain: 'toktv-1df77.firebaseapp.com',
    projectId: 'toktv-1df77',
    storageBucket: 'toktv-1df77.appspot.com',
    messagingSenderId: '900666895232',
    appId: '1:900666895232:web:ec4d86227e1b258ea26f65',
    measurementId: 'G-QPVL8T7V5C',
  };

  // Initialize Firebase
  initializeApp(firebaseConfig);
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  try {
    const googleResult = await signInWithPopup(auth, provider);
    xl.act({ actentryid: '700003', subactid: '3', type: 'click' });
    return googleResult;
  } catch (error) {
    xl.act({ actentryid: '700003', subactid: '4', type: 'click' });
    return Promise.reject(error);
  }
}
