import jsonp from 'jsonp';
import { jsonToUrlParams } from '.';

export default function (url: string, data: Record<string, any> = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    jsonp(
      `${url}?${jsonToUrlParams(data)}`,
      {
        param: 'jsonp',
        name: `__jsonp_cb_${Date.now()}__`,
      },
      function (err: any, result: any) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      }
    );
  });
}
