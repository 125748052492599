<template>
  <!-- 视频页面支付挽留弹框 -->
  <van-dialog class="payhold" v-model:show="show" :showConfirmButton="false">
    <div class="content">
      <img src="../../../imgs/payhold1/close.png" class="close" @click="handleClose(true)" />
      <div class="title">{{ $t('payhold.video.title') }}</div>
      <img src="../../../imgs/payhold1/gift.png" class="gift" />
      <div class="goodsinfo">
        <div class="mark">
          <div class="text">
            <div class="price">+{{ percent }}%</div>
            <div>More</div>
          </div>
        </div>
        <div class="othergoods" v-if="goods?.goods_type != 3">
          <div class="originprice">{{ goods?.custom?.unit }}{{ goods?.custom?.originPrice }}</div>
          <div>{{ goods?.commodityName }}</div>
        </div>
        <div class="kgoods" v-else>
          <span>{{ goods?.commodityName }}+{{ goods?.custom?.extra_value }}</span
          >{{ $t('play.txt.kb') }}
        </div>
      </div>
      <div class="btn" @click="handleNowPay">{{ goods?.custom?.unit }}{{ goods?.price }}</div>
    </div>
    <div class="countdown">
      <span>{{ $t('payhold.video.limit') }}</span>
      <van-count-down :time="limitTime" @finish="limitTime = 180000">
        <template #default="timeData">
          <span class="block">{{
            timeData.minutes < 10 ? `0${timeData.minutes}` : timeData.minutes
          }}</span>
          <span class="colon">:</span>
          <span class="block">{{
            timeData.seconds < 10 ? `0${timeData.seconds}` : timeData.seconds
          }}</span>
        </template>
      </van-count-down>
    </div>
  </van-dialog>
</template>

<script setup lang="ts">
import xl from '@/utils/log';
import { usePay } from '../usePay';
import { payItem } from '@/utils/auth';

interface IProps {
  visible: boolean;
  location: number;
  goods: PayType.PlyItemFormat;
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  location: 0,
  goods: Object,
});
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'gopay', value: PayType.PlyItemFormat): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});
const limitTime = ref(180000);
const percent = computed(() =>
  // 西班牙的小数，中间是逗号","作为小数点的，所以要兼容处理
  Math.floor(
    (Number(props.goods?.custom?.originPrice?.toString().replaceAll(',', '.')) /
      Number(props.goods?.price?.toString().replaceAll(',', '.'))) *
      100
  )
);
const { showFreeUnlock, payHoldStorageMap } = usePay();

const handleClose = (openFreeUnlock = true) => {
  show.value = false;
  payHoldStorageMap.value[props.location] += 1;
  showFreeUnlock.value = openFreeUnlock;
};

const handleNowPay = () => {
  xl.act({ actentryid: '800011', subactid: `${props.goods.goods_type}`, type: 'click' });
  payItem.value = JSON.stringify(props.goods);
  emit('gopay', props.goods);
  handleClose(false);
};

watch(
  () => show.value,
  (newVal) => {
    xl.act({
      actentryid: '800011',
      subactid: `${props.goods.goods_type}`,
      type: `${newVal ? 'show' : 'close'}`,
    });
  }
);
</script>

<style lang="scss">
.payhold {
  &.van-dialog {
    width: max-content;
    overflow: inherit;
    background: transparent;
  }

  .content {
    position: relative;
    width: 285px;
    min-height: 320.5px;
    margin: 0 auto;
    padding: 25px 0;
    background-color: #381663;
    background-image: url(../../../imgs/payhold1/bg.png);
    background-size: 100% 100%;
    border-radius: 12px;

    .close {
      position: absolute;
      top: 18px;
      right: 18px;
      width: 14px;
      height: 14px;
    }

    .title {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }

    .gift {
      display: block;
      width: 163px;
      height: 132px;
      margin: 10px auto 0;
    }

    .goodsinfo {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 70px;

      .mark {
        position: absolute;
        top: -50px;
        right: -28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 108px;
        height: 90.5px;
        background-image: url(../../../imgs/payhold1/mark.png);
        background-size: 100% 100%;

        .text {
          width: 100%;
          transform: rotate(16deg);

          div {
            width: 100%;
            color: #fff997;
            font-size: 17px;
            text-align: center;

            &.price {
              font-weight: bold;
            }
          }
        }
      }

      .kgoods {
        margin-top: 25px;
        color: #fff;
        font-size: 15px;
        text-align: center;

        span {
          margin-right: 8px;
          font-weight: bold;
          font-size: 21px;
        }
      }

      .othergoods {
        color: #fff;
        font-size: 15px;
        text-align: center;

        div {
          &.originprice {
            margin-bottom: 3px;
            font-weight: bold;
            font-size: 21px;
            text-decoration: line-through;
          }
        }
      }
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 233px;
      height: 47px;
      margin: 0 auto;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      background: #e93a57;
      border-radius: 23.5px;
    }
  }

  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    color: #f6adff;
    font-size: 14px;

    span {
      padding-bottom: 2px;
    }

    .van-count-down {
      margin-left: 6px;

      .block {
        display: inline-block;
        width: 20px;
        height: 20px;
        padding-top: 2px;
        color: #fff;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        background: #6f2da1;
        border-radius: 4px;
      }

      .colon {
        margin: 0 3px;
        color: #fff;
        font-size: 11px;
      }
    }
  }
}
</style>
