<template>
  <van-popup v-model:show="visiblePopup" class="guess-like-popup" :close-on-click-overlay="false">
    <template v-if="!!dramaList">
      <img src="@/pages/index/imgs/video/write-back.png" class="back" @click="handleGoBack" />
      <div class="title">{{ $t('video.guess.like') }}</div>
      <swiper
        class="swiper"
        :modules="modules"
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="'auto'"
        :coverflowEffect="{
          rotate: 0,
          stretch: -30,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }"
        :loop="dramaList.length >= 3"
        @slide-change="onSlideChange"
      >
        <swiper-slide class="swiper-item" v-for="item in dramaList" :key="item.drama_id">
          <g-video v-if="visiblePopup" :item="item"></g-video>
        </swiper-slide>
      </swiper>
      <div class="btn-wrap">
        <van-button class="btn-play" @click="handlePlay"
          ><img :src="playBlackIcon" />{{ $t('video.play') }}</van-button
        >
      </div>
    </template>
  </van-popup>
</template>

<script setup lang="ts" name="guess-like">
import 'swiper/scss';
import 'swiper/scss/effect-coverflow';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInstance } from 'swiper';
import { EffectCoverflow } from 'swiper/modules';
import 'xgplayer/dist/index.min.css';
import { getGuessLike } from '@/pages/index/api';
import GVideo from './g-video.vue';
import { withLoading } from '@/utils/with-loading';
import { TOKTV_BACK_RETAIN } from '@/pages/index/keys';
import playBlackIcon from '@/pages/index/imgs/video/play-black.png';
import xl from '@/utils/log';
import { goBack } from '@/pages/index/router';
import { MyDramaItem } from './types';
import { isPro } from '@/utils/is';
interface IProps {
  visible: boolean;
  currentDramaId: string | number;
}
const props = defineProps<IProps>();
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
}>();
const visiblePopup = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});
const router = useRouter();
const modules = [EffectCoverflow];
const dramaList = ref<MyDramaItem[]>();
const backRetain = useStorage(TOKTV_BACK_RETAIN, true, sessionStorage);

let timer: ReturnType<typeof setTimeout>;
const onSlideChange = (swiper: SwiperInstance) => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    !isPro && console.log(swiper.realIndex, '<<< onSlideChange realIndex');
    const realIndex = swiper.realIndex;
    dramaList.value = dramaList.value?.map((item, index) => {
      return {
        ...item,
        _active: index === realIndex,
      };
    });
  }, 300);
};

const handlePlay = () => {
  const item = dramaList.value?.find((item) => item._active);
  if (item) {
    router.push({
      name: 'video',
      query: {
        drama_id: item.drama_id,
        source: item.source,
        bigsource: '4',
      },
    });
  }
  xl.act({ actentryid: '100006', subactid: item?.drama_id, type: 'click' });
};

const loadData = async () => {
  const res = await withLoading(getGuessLike);
  dramaList.value = res.data.lists
    .map((item, index) => {
      return {
        ...item,
        _active: index === 0,
      };
    })
    .filter((item) => item.drama_id != props.currentDramaId);
};

const handleGoBack = () => {
  const historyState = window.history.state;
  if (historyState.back?.indexOf('/video') > -1 && historyState.current?.indexOf('/video') > -1) {
    router.push('/');
  } else {
    goBack();
  }
};

watch(visiblePopup, async (v) => {
  if (v) {
    xl.act({ actentryid: '100006', type: 'show' });
    await loadData();
    // 延迟的目的是防止轮播还未出来，用户就点了返回按钮退出了当前页面。
    setTimeout(() => {
      backRetain.value = false;
    }, 100);
  }
});
</script>
<!-- <style src="swiper/scss" lang="scss"></style>
<style src="swiper/scss/effect-coverflow" lang="scss"></style> -->
<style scoped lang="scss">
.guess-like-popup {
  max-width: 100vw;
  height: 100%;
  margin: 0;
  padding-top: 18%;
  background-color: rgba($color: #000, $alpha: 0.8);

  .back {
    position: absolute;
    top: 30px;
    left: 18px;
    width: 22px;
    height: 22px;
  }

  .title {
    margin-bottom: 15px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }

  .swiper {
    width: 100%;
    height: 400px;

    &-item {
      width: 265px;
      height: 100%;
      // overflow: hidden;
      // background-color: yellow;
      // border-radius: 12px;
    }
  }

  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-top: 25px;

    :deep(.btn-play) {
      min-width: 80px;
      height: 30px;
      padding: 0 10px;
      color: #222;
      font-size: 13px;
      background: #fff;
      border-radius: 6px;

      .van-button__text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      img {
        width: 11.5px;
        height: 13px;
        margin-right: 5px;
      }
    }
  }
}
</style>
