<template>
  <div v-if="visible" class="modal-wrap">
    <div class="modal">
      <h3 class="modal-header">{{ title }}</h3>
      <div class="modal-body">
        <div class="modal-body-content">
          <p>{{ content }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn-ok" @click="handleOk">{{ okText }}</button>
      </div>
    </div>
    <div class="modal-close" @click="handleClose"></div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'x-modal',
  // inheritAttrs: false,
  // customOptions: {},
};
</script>
<script lang="ts" setup>
import { computed } from 'vue';

interface IProps {
  visible: boolean;
  title: string;
  content: string;
  okText?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  okText: 'ok',
});

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'ok'): void;
  (e: 'close'): void;
}>();

const visible = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});

const handleOk = () => {
  visible.value = false;
  emit('ok');
};
const handleClose = () => {
  visible.value = false;
  emit('close');
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  width: 6.4rem;
  margin-top: 1rem;
  background-color: #fff;
  border-radius: 0.24rem;

  &-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &-header {
    margin: 0.5rem 0;
    color: #333;
    font-weight: bold;
    font-size: 0.42rem;
    text-align: center;
  }

  &-body {
    padding: 0.15rem 0.4rem;
    color: #999;
    font-size: 0.3rem;
    line-height: 0.48rem;

    p {
      margin-bottom: 0.1rem;
    }
  }

  &-footer {
    width: 100%;
    margin: 0.5rem 0;
    text-align: center;

    > .btn-ok {
      width: 5rem;
      height: 1rem;
      color: #fff;
      font-weight: bold;
      font-size: 0.34rem;
      line-height: 1rem;
      text-align: center;
      background: linear-gradient(270deg, #ec4f27 0%, #f4ab3c 100%);
      border-radius: 0.5rem;
    }
  }

  &-close {
    box-sizing: content-box;
    width: 0.48rem;
    height: 0.48rem;
    margin: 0.8rem auto 0;
    padding: 0.2rem;
    // background: url('@/assets/turntable/icon-close.png') no-repeat center center;
    background-size: 0.48rem 0.48rem;
  }
}
</style>
