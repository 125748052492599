<template>
  <div class="page login">
    <div class="back" @click="() => goBack()">
      <img src="@/pages/index/imgs/wallet/back.png" />
    </div>
    <div class="wrapper">
      <img class="logo" src="@/pages/index/imgs/login/logo.png" />
      <p class="title">{{ $t('login.description') }}</p>

      <div class="form">
        <div class="form-username">
          <input
            type="text"
            v-model.trim="formInfo.account"
            :placeholder="$t('login.account.placeholder')"
          />
        </div>
        <div class="form-password">
          <input
            type="password"
            autocomplete="new-password"
            v-model.trim="formInfo.password"
            :placeholder="$t('login.mm.placeholder')"
          />
        </div>
        <van-button class="form-btn" :loading="loginLoading" @click="handleLogin">{{
          $t('login.login')
        }}</van-button>
      </div>

      <van-divider class="divider">{{ $t('login.login.other') }}</van-divider>

      <div class="third-login">
        <van-button
          class="facebook"
          :class="{ alone: isMeta }"
          :loading="loginFacebookLoading"
          @click="handleFacebookLogin"
        ></van-button>
        <van-button
          v-if="!isMeta"
          class="google"
          :loading="loginGoogleLoading"
          @click="handleGoogleLogin"
        >
        </van-button>
      </div>
      <div class="tip">{{ $t('login.tip.term') }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { goBack } from '@/pages/index/router';
import xl from '@/utils/log';
import { isMeta, isPro } from '@/utils/is';
import { googleLogin } from './google';
import { useUserStore } from '../../store/modules/user';
import { usePayStore } from '../../store/modules/pay';
import { facebookLogin } from './facebook';
import { showToast } from 'vant';
const userStore = useUserStore();
const payStore = usePayStore();
const loginLoading = ref(false);
const loginGoogleLoading = ref(false);
const loginFacebookLoading = ref(false);
const formInfo = reactive({
  account: userStore.state.loginInfo.account || '',
  password: userStore.state.loginInfo.mm || '',
});

// 账密登录
const handleLogin = async () => {
  xl.act({ actentryid: '700001', subactid: '3', type: 'click' });
  if (!formInfo.account) {
    showToast('请输入账号');
    return;
  }
  if (!formInfo.password) {
    showToast('请输入密码');
    return;
  }
  try {
    loginLoading.value = true;
    await userStore.login({
      account: formInfo.account,
      password: formInfo.password,
    });
    handleLoginSucEvent('5');
    xl.act({ actentryid: '700003', subactid: '5', type: 'click' });
  } catch (error) {
    xl.act({ actentryid: '700003', subactid: '6', type: 'click' });
    console.error(error);
  } finally {
    loginLoading.value = false;
  }
};

// Google登录
const handleGoogleLogin = async () => {
  xl.act({ actentryid: '700001', subactid: '2', type: 'click' });
  loginGoogleLoading.value = true;
  try {
    // google授权
    const googleResult = await googleLogin();
    // 三方登录
    const res = await userStore.loginWithOther({
      unionid: googleResult.user.providerData[0].uid,
      openid: googleResult.user.providerData[0].email || '',
      figureurl: googleResult.user.photoURL || '',
      nickname: googleResult.user.displayName || '',
      usertype: '24',
    });
    !isPro && console.log('google登录', res);
    handleLoginSucEvent('1');
  } catch (error) {
    console.error(error);
    // showToast(t('common.toast.error.network'));
  }
  loginGoogleLoading.value = false;
};

// Facebook登录
const handleFacebookLogin = async () => {
  xl.act({ actentryid: '700001', subactid: '1', type: 'click' });
  loginFacebookLoading.value = true;
  try {
    // FB授权
    const fbUserInfo: FBUserInfo = await facebookLogin();
    // 三方登录
    const res = await userStore.loginWithOther({
      unionid: fbUserInfo.id,
      openid: fbUserInfo.id,
      figureurl: '',
      nickname: fbUserInfo.name,
      usertype: '23',
    });
    !isPro && console.log('facebook登录', res);
    handleLoginSucEvent('4');
  } catch (error) {
    console.error(error);
  }
  loginFacebookLoading.value = false;
};

/**
 * @description 登录成功后处理（fb回传，打点上报，路由跳转）
 * @param res 登录事件的返回
 * @param subactid 打点上报子ID
 */
const handleLoginSucEvent = (subactid: string) => {
  xl.act({ actentryid: '120001', subactid: subactid, type: 'show' });
  payStore.getVipMemberInfo();
  goBack();
};

onMounted(() => {
  xl.act({ actentryid: '700001', type: 'show' });
});
</script>
<style scoped lang="scss">
.page {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 0 20px;
  background: url('@/pages/index/imgs/login/bg.png') center top/100% auto no-repeat;

  &.login {
    .back {
      position: fixed;
      top: 30px;
      left: 18px;
      z-index: 9000;
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .wrapper {
    padding-top: 100px;

    .logo {
      display: block;
      width: 82px;
      height: 82px;
      margin: 0 auto 8px;
    }

    .title {
      margin: 0;
      color: #222;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 70px auto 50px;

      &-username,
      &-password {
        position: relative;
        width: 300px;
        height: 55px;
        padding: 15px 20px 15px 50px;
        border: 1px solid #bebebe;
        border-radius: 28px;

        input {
          width: 100%;
          height: 100%;
          // background-color: yellow;
        }

        &::before {
          position: absolute;
          top: 17px;
          left: 24px;
          width: 18px;
          height: 18px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          content: '';
        }
      }

      &-username {
        &::before {
          background-image: url(../../imgs/login/i-user.png);
        }
      }

      &-password {
        margin-top: 20px;

        &::before {
          background-image: url(../../imgs/login/i-lock.png);
        }
      }

      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 55px;
        margin-top: 50px;
        color: #fff;
        font-size: 18px;
        background: #ff3868;
        border: 0;
        border-radius: 28px;
      }
    }

    .divider {
      margin: 0;
    }

    .third-login {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 25px;

      .facebook,
      .google {
        position: relative;
        width: 48px;
        height: 48px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border: 0 none;
        border-radius: 50%;
      }

      .facebook {
        margin-right: 48px;
        background-image: url(../../imgs/login/i-facebook.png);

        &.alone {
          margin-right: 0;
        }
      }

      .google {
        background-image: url(../../imgs/login/i-google.png);
      }
    }

    .tip {
      width: 100%;
      margin-top: 34px;
      padding: 0 20px;
      color: #bebebe;
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>
