<template>
  <div class="card-item1" v-bind="$attrs" v-if="item">
    <div class="card-item1-img-wrap">
      <img v-lazy="item.cover" alt="" />
      <div class="card-item1-mask">
        <img :src="pngPlay" alt="" />
        <span>{{ Math.ceil((item.last_play_episode / Number(item.total_episode)) * 100) }}%</span>
      </div>
    </div>
    <h4 class="card-item1-title">{{ item.title }}</h4>
  </div>
</template>

<script setup lang="ts" name="card-item1">
import pngPlay from '../../imgs/i-play-4.png';
const router = useRouter();
const props = defineProps<{
  item: CollectItem;
}>();
// console.log('props >>> ', props);
</script>

<style scoped lang="scss">
.card-item1 {
  height: fit-content;

  &-img-wrap {
    position: relative;
    width: 108px;
    height: 150px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 9px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 20px;
    color: #fff;
    font-size: 11px;
    background-color: rgba($color: #000, $alpha: 0.5);
    border-radius: 8px 0;

    img {
      width: 9px;
      height: 11px;
      margin-right: 3px;
    }
  }

  &-title {
    width: 108px;
    margin-top: 8px;
    // text-align: justify;
    color: #222;
    font-size: 14px;
    line-height: 21px;
  }
}
</style>
