<template>
  <van-dialog
    class="x-dialog"
    v-model:show="show"
    round
    :show-cancel-button="false"
    :show-confirm-button="false"
  >
    <div v-if="title" class="x-dialog-title">{{ title }}</div>
    <p class="x-dialog-content">
      <template v-if="isArray(content)">
        <template v-if="contentAsHtml">
          <div class="txt" v-for="txt in content" :key="txt" v-html="txt"></div>
        </template>
        <template v-else>
          <div class="txt" v-for="txt in content" :key="txt" v-text="txt"></div>
        </template>
      </template>
      <template v-else>
        <div v-if="contentAsHtml" v-html="content"></div>
        <div v-else v-text="content"></div>
      </template>
    </p>
    <div class="x-dialog-btn">
      <div class="x-dialog-btn__cancel" @click="handleCancel">{{ $t('login.bind.cancel') }}</div>
      <div class="x-dialog-btn__ok" @click="handleConfirm">{{ $t('login.bind.ok') }}</div>
    </div>
  </van-dialog>
</template>

<script setup lang="ts" name="x-dialog">
import { isArray } from '@/utils/is';

interface IProps {
  visible: boolean;
  title?: string;
  content?: string | Array<string>;
  contentAsHtml?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  contentAsHtml: false,
});

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});

const handleCancel = () => {
  show.value = false;
  emit('cancel');
};

const handleConfirm = () => {
  emit('confirm');
};
</script>

<style lang="scss">
.x-dialog {
  width: 300px;
  padding: 25px;
  // z-index: 3000 !important;

  &-title {
    margin-bottom: 10px;
    color: #222;
    font-weight: bold;
    font-size: 19px;
    line-height: 2em;
    text-align: center;
  }

  &-content {
    color: #222;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 34px;

    &__ok,
    &__cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 47px;
      font-weight: 500;
      font-size: 15px;
      background: #ff3868;
      border-radius: 24px;
    }

    &__ok {
      margin-left: 14px;
      color: #fff;
    }

    &__cancel {
      color: #b4b6b8;
      background: #eceff1;
    }
  }
}
</style>
