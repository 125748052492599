<template>
  <div class="free-unlock-pop">
    <van-popup
      v-model:show="showFreeUnlock"
      position="center"
      :close-on-click-overlay="false"
      @close="hideFreeUnlock"
    >
      <div class="free-unlock">
        <img src="@/pages/index/imgs/close.png" class="close" @click="hideFreeUnlock" />
        <div class="title">{{ $t('free.unlock.text1') }}</div>
        <div class="sub-title">{{ $t('free.unlock.text2') }}</div>
        <div v-if="isChromeOrSamsung" class="btn" @click="handleNowFreeUnlock">
          {{ downloadBtnText }}
        </div>
        <div v-else class="btn" @click="handleNowFreeUnlock">
          {{ $t('free.unlock.text3') }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import xl from '@/utils/log';
import { wakeUp } from '@/utils';
import { usePwa } from '@/composables/usePwa';
import { isChromeOrSamsung, isIOS, isMeta } from '@/utils/is';

const { t } = useI18n();
const { getHref } = usePwa();
const { handleInstall, installing, percentNum, installFlag, isInstalled, handleOpen } = usePwa();
const props = defineProps({
  show: Boolean,
  type: Number as PropType<1 | 2>, //1-充值页关闭 2-支付失败
});
const showFreeUnlock = ref(false);
const downloadBtnText = computed(() => {
  if (installing.value) {
    return percentNum.value > 99
      ? t('download.btn3')
      : `${t('download.btn2')}...${percentNum.value}%`;
  } else {
    return installFlag.value ? t('download.btn3') : t('free.unlock.text3');
  }
});

watch(
  () => props.show,
  (newVal) => {
    showFreeUnlock.value = newVal;
    if (newVal) {
      xl.act({ actentryid: '800010', subactid: props.type?.toString(), type: 'show' });
    } else {
      xl.act({ actentryid: '800010', type: 'close' });
    }
  },
  { immediate: true }
);

const emit = defineEmits(['hide']);

function hideFreeUnlock() {
  emit('hide');
}

function handleNowFreeUnlock() {
  xl.act({ actentryid: '800010', type: 'click' });
  if (isChromeOrSamsung) {
    // chrome内
    if (isInstalled.value) {
      handleOpen();
    } else {
      handleInstall();
    }
  } else {
    if (isMeta && isIOS) {
      window.location.href = 'https://apps.apple.com/us/app/toktv/id6464023756';
    } else {
      wakeUp(getHref());
    }
  }
}
</script>

<style lang="scss" scoped>
.free-unlock-pop {
  .van-popup {
    overflow: inherit;
    background: none;
  }

  .free-unlock {
    position: relative;
    width: 300px;
    min-height: 303px;
    margin-top: -50px;
    padding: 144px 0 28px;
    background: url('@/pages/index/imgs/bg-pop.png');
    background-size: 100% 100%;

    .close {
      position: absolute;
      top: 105px;
      right: 15px;
      width: 20px;
      height: 20px;
    }

    .title {
      margin-bottom: 15px;
      color: #222;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
    }

    .sub-title {
      margin-bottom: 30px;
      padding: 0 20px;
      color: #ff3868;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      word-break: normal;
    }

    .btn {
      width: 240px;
      height: 47px;
      margin: 0 auto;
      color: #fff;
      font-weight: 500;
      font-size: 15px;
      line-height: 47px;
      text-align: center;
      background: #ff3868;
      border-radius: 23px;
    }
  }
}
</style>
