import 'animate.css';
import '@/styles/index.scss';
// 手动导入部分（unplugin-vue-components 无法自动导入的）组件样式，如Toast等组件
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import './style.scss';
import { createApp } from 'vue';
import App from './App.vue';
import { setupLocale } from '@/locale';
import router from './router';
import { setupStore } from './store';
import { Lazyload } from 'vant';
import { setupGlobalComponents } from '@/plugins';
// import { registerSW } from 'virtual:pwa-register';

// const updateSW = registerSW({
//   onOfflineReady() {},
// });

const app = createApp(App);
app.use(router);
setupStore(app);
setupLocale(app);
setupGlobalComponents(app);

app.use(Lazyload, { lazyComponent: true });

app.mount('#app');
