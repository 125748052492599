<template>
  <van-popup
    v-model:show="visible"
    class="auto-login-popup"
    position="bottom"
    :close-on-click-overlay="false"
    duration="0.2"
  >
    <div class="auto-login" :class="{ share: from == 'gift' }">
      <img src="@/pages/index/imgs/login/close.png" class="auto-login-close" @click="handleClose" />

      <div v-if="from == 'gift'" class="for-share">
        <div class="for-share-t1">{{ $t('login.share.txt1') }}</div>
        <div class="for-share-t2">{{ $t('login.share.txt2') }}</div>
        <div class="for-share-t3">
          <span class="s1">{{ $t('login.share.txt3.lf') }}</span>
          <span class="s2">$100000</span>
          <span class="s3">{{ $t('login.share.txt3.rt') }}</span>
        </div>
        <div class="for-share-line"></div>
      </div>

      <div class="auto-login-warning">
        <span>{{ $t('login.suggestion') }}</span>
        <img :src="dfPng" alt="" />
      </div>
      <div class="user-info">
        <div class="user-info-item">
          {{ $t('login.account') }}: {{ userStore.state.loginInfo.account }}
        </div>
        <div class="user-info-item">{{ $t('login.mm') }}: {{ userStore.state.loginInfo.mm }}</div>
      </div>
      <div class="auto-login-copy" @click="handleCopy">
        <span>{{ $t('login.copy') }}</span>
        <img :src="copyPng" alt="" />
      </div>
      <div class="auto-login-ok" @click="handleOk">
        {{ fromGift ? $t('login.ok.share') : $t('login.ok') }}
      </div>
      <van-divider class="divider">{{ $t('login.bind.title') }}</van-divider>

      <div class="third-login">
        <van-button
          class="facebook"
          :loading="loginFacebookLoading"
          @click="handleFacebookBind"
        ></van-button>
        <van-button
          v-if="!isMeta"
          class="google"
          :loading="loginGoogleLoading"
          @click="handleGoogleBind"
        >
        </van-button>
      </div>
    </div>
  </van-popup>
  <x-confirm
    v-model:visible="visibleBindConfirm"
    content-as-html
    :content="[
      t('login.bind.warn.txt1', { platform: platform }),
      t('login.bind.warn.txt2', { nickName: nickName }),
      t('login.bind.warn.txt3'),
    ]"
    @cancel="handleCancel"
    @confirm="handleConfirm"
  ></x-confirm>
</template>
<script lang="ts" setup>
import XConfirm from '@/components/x-confirm/index.vue';
import xl from '@/utils/log';
import { isMeta, isPro } from '@/utils/is';
import { useUserStore } from '@/pages/index/store/modules/user';
import { usePayStore } from '@/pages/index/store/modules/pay';
import { withLoading } from '@/utils/with-loading';
import { facebookLogin } from '../../login/facebook';
import { googleLogin } from '../../login/google';
import { useCopy } from '@/composables/useCopy';
import { showToast } from 'vant';
import dfPng from '@/pages/index/imgs/login/down-finger.png';
import copyPng from '@/pages/index/imgs/login/copy.png';

interface IProps {
  visible: boolean;
  curEpisode: number;
  from?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  // visible: false,
});
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'ok'): void;
  (e: 'close'): void;
  (e: 'success'): void;
}>();
const { t } = useI18n();
const userStore = useUserStore();
const payStore = usePayStore();
const loginGoogleLoading = ref(false);
const loginFacebookLoading = ref(false);
const visibleBindConfirm = ref(false);
const bindPlatform = ref<'Facebook' | 'Google'>();
const platform = computed(
  () => `<em style="color:red;font-style:normal;">${bindPlatform.value}</em>`
);
const params = ref<ApiLogin.LoginRequestParams>();
const nickName = computed(
  () => `<em style="color:red;font-style:normal;">${params.value?.nickname}</em>`
);
const visible = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});

const fromGift = computed(() => props.from === 'gift');

watch(visible, async (v) => {
  if (v) {
    xl.act({ actentryid: '130001', subactid: String(props.curEpisode), type: 'show' });
    const { account, mm } = userStore.state.loginInfo;
    await withLoading(() =>
      userStore.login(
        account && mm
          ? {
              account: account,
              password: mm,
            }
          : {}
      )
    );
  } else {
    xl.act({ actentryid: '130001', subactid: String(props.curEpisode), type: 'close' });
  }
});

watch(visibleBindConfirm, (v) => {
  if (v) {
    xl.act({ actentryid: '130006', type: 'show' });
  } else {
    xl.act({ actentryid: '130006', type: 'close' });
  }
});

const handleCopy = async () => {
  xl.act({ actentryid: '130002', type: 'click' });
  await useCopy(
    t('login.copy.content', {
      account: userStore.state.loginInfo.account,
      mm: userStore.state.loginInfo.mm,
    })
  );
  // showToast(t('record.toast.copied'));
};

const handleOk = () => {
  xl.act({ actentryid: '130003', type: 'click' });
  emit('ok');
  handleClose();
};

const handleConfirm = async () => {
  xl.act({ actentryid: '130006', type: 'click' });
  try {
    // 使用已有账号登录
    await withLoading(() =>
      params.value ? userStore.loginWithOther(params.value) : Promise.reject()
    );
    visibleBindConfirm.value = false;
    handleLoginSuccess();
  } catch (error) {
    console.error('Error: params is empty');
  }
};

const handleCancel = () => {};

// Google登录绑定
const handleGoogleBind = async () => {
  bindPlatform.value = 'Google';
  xl.act({ actentryid: '130004', type: 'click' });
  try {
    loginGoogleLoading.value = true;
    // google授权
    const googleResult = await googleLogin();
    // 三方登录
    params.value = {
      unionid: googleResult.user.providerData[0].uid,
      openid: googleResult.user.providerData[0].email || '',
      figureurl: googleResult.user.photoURL || '',
      nickname: googleResult.user.displayName || '',
      usertype: '24',
    };
    try {
      const res = await userStore.bind(params.value);
      !isPro && console.log('<google login>', res);
      handleLoginSuccess();
    } catch (error: any) {
      console.error(error);
      if (error.code == '10123') {
        visibleBindConfirm.value = true;
      } else if (error.message) {
        showToast(error.message);
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    loginGoogleLoading.value = false;
  }
};

// Facebook登录绑定
const handleFacebookBind = async () => {
  bindPlatform.value = 'Facebook';
  xl.act({ actentryid: '130005', type: 'click' });
  loginFacebookLoading.value = true;
  try {
    // FB授权
    const fbUserInfo: FBUserInfo = await facebookLogin();
    params.value = {
      unionid: fbUserInfo.id,
      openid: fbUserInfo.id,
      figureurl: '',
      nickname: fbUserInfo.name,
      usertype: '23',
    };
    // 三方绑定
    const res = await userStore.bind(params.value);
    !isPro && console.log('<facebook bind>', res);
    handleLoginSuccess();
  } catch (error: any) {
    console.error(error);
    if (error.code == '10123') {
      visibleBindConfirm.value = true;
    } else if (error.message) {
      showToast(error.message);
    }
  }
  loginFacebookLoading.value = false;
};

/**
 * @description 登录成功后处理（fb回传，打点上报等）
 * @param res 登录事件的返回
 * @param subactid 打点上报子ID
 */
const handleLoginSuccess = () => {
  emit('success');
  handleClose();
};

const handleClose = () => {
  payStore.getVipMemberInfo();
  visible.value = false;
  emit('close');
};

onMounted(() => {
  xl.act({ actentryid: '700001', type: 'show' });
});
</script>
<style lang="scss" scoped>
.auto-login-popup {
  background: #fff;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.for-share {
  width: 100%;
  padding-top: 50px;
  background-image: url('../../../imgs/login/bg-coin.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;

  &-t1,
  &-t2 {
    width: 330px;
    margin: 0 auto;
    color: transparent;
    font-size: 20px;
    line-height: 1.3em;
    text-align: center;
    text-shadow: 0 1px 1px rgba(150, 14, 136, 0.3);
    background: linear-gradient(90deg, #ff3868 0%, #6038ff 100%);
    -webkit-background-clip: text;
  }

  &-t3 {
    width: 330px;
    margin: 10px auto 0;
    color: #242424;
    font-size: 15px;
    line-height: 30px;
    text-align: center;

    .s2 {
      margin: 0 5px;
      color: transparent;
      font-size: 27px;
      line-height: 33px;
      text-shadow: 0 2px 4px rgba(150, 14, 136, 0.3);
      background: linear-gradient(90deg, #9462ff 0%, #ff005d 100%);
      -webkit-background-clip: text;
    }
  }

  &-line {
    width: 330px;
    height: 1px;
    margin: 20px auto;
    // border: 1px dashed rgba(255, 56, 104, 0.5);
    background-image: repeating-linear-gradient(
      to right,
      #ff3868,
      #6038ff 1%,
      transparent 1%,
      transparent 2%,
      #ff3868 2%,
      #6038ff 3%
    );
    opacity: 0.6;
  }
}

.auto-login {
  position: relative;
  width: 100vw;
  padding-top: 50px;
  background: url('@/pages/index/imgs/login/pop-bg.png');
  background-repeat: no-repeat;
  background-position: center top;

  &.share {
    padding-top: 0;
  }

  &-close {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 14px;
    height: 14px;
  }

  &-warning {
    width: 330px;
    margin: 0 auto;
    color: #ff3868;
    font-size: 15px;
    line-height: 21px;
    text-align: center;

    > img {
      position: absolute;
      width: 26px;
      height: 29px;
    }
  }

  .user-info {
    position: relative;
    width: 280px;
    margin: 15px auto;
    padding: 5px 10px;
    color: #222;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #f5e7e7;
    border-radius: 10px;
  }

  &-copy {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #589def;
    font-size: 14px;
    line-height: 21px;

    &::before {
      position: absolute;
      top: -25px;
      left: 120px;
      width: 21px;
      height: 31px;
      background-image: url('../../../imgs/login/up-arrow.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      content: '';
    }

    > img {
      width: 15px;
      height: 15px;
      margin-left: 2px;
    }
  }

  &-ok {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 55px;
    margin: 12px auto 0;
    color: #fff;
    font-size: 18px;
    background: #ff3868;
    border-radius: 28px;
  }

  .divider {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .third-login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;

    .facebook,
    .google {
      position: relative;
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      border: 0 none;
      border-radius: 50%;
    }

    .facebook {
      margin-right: 48px;
      background-image: url(../../../imgs/login/i-facebook.png);
    }

    .google {
      background-image: url(../../../imgs/login/i-google.png);
    }
  }
}
</style>
