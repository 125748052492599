import { defineStore } from 'pinia';
import { bindOther, loginWithAccount, loginWithOtherAccount, subscriptionCheck } from '../../api';
// import router from '../../router';
import xl from '@/utils/log';
import { isPro } from '@/utils/is';

export interface UserState {
  loginInfo: LoginInfo;
}

export interface LoginInfo {
  id?: number | string;
  accid?: string;
  figureurl?: string;
  nickname?: string;
  token?: string;
  account?: string;
  mm?: string;
  info?: ApiLogin.UserInfoItem[];
}

export const useUserStore = defineStore('user', () => {
  // 缓存的登录信息
  const cachedLoginInfo = useStorage<LoginInfo>('TOKTV_STORE_USER_INFO', {});

  // 内存state
  const state = reactive<UserState>({ loginInfo: cachedLoginInfo.value });

  const tokenGetter = computed(() => state.loginInfo.token);
  const isLogin = computed(() => !!tokenGetter.value);
  const isSubscription = ref<number | undefined>();

  function updateLoginInfo(data: LoginInfo) {
    const account = state.loginInfo.account;
    const mm = state.loginInfo.mm;
    state.loginInfo = {
      ...state.loginInfo,
      ...data,
    };
    // 永远保留账密，除非用户主动清除
    if (account && mm) {
      state.loginInfo = {
        ...state.loginInfo,
        account,
        mm,
      };
    }
    cachedLoginInfo.value = state.loginInfo;
    // 更新SDK公参
    xl.setParams({
      accid: state.loginInfo.accid || '',
      userinfo: JSON.stringify({
        id: state.loginInfo.id,
        accid: state.loginInfo.accid,
        figureurl: state.loginInfo.figureurl,
        nickname: state.loginInfo.nickname,
      }),
    });
  }

  function updateWithLogin(data: ApiLogin.Response) {
    const { userinfo, login_token, account, mm } = data;
    const targetInfo: ApiLogin.UserInfoItem | undefined =
      userinfo?.info?.find((item) => item.usertype === '24') ||
      userinfo?.info?.find((item) => item.usertype === '23') ||
      userinfo?.info?.find((item) => item.usertype === '100');
    updateLoginInfo({
      id: userinfo?.id,
      accid: userinfo?.accid,
      figureurl: targetInfo?.figureurl,
      nickname: targetInfo?.nickname,
      token: login_token,
      account: account,
      mm: mm,
      info: userinfo?.info,
    });
  }

  async function login(params: ApiLogin.CipherRequestParams) {
    const res = await loginWithAccount(params);
    !isPro && console.log({ data: res.data, state: JSON.stringify(state) }, ' <<< login res');
    updateWithLogin(res.data);
    return res;
  }

  async function loginWithOther(params: ApiLogin.LoginRequestParams) {
    const res = await loginWithOtherAccount(params);
    updateWithLogin(res.data);
    return res;
  }

  async function bind(params: ApiLogin.LoginRequestParams) {
    const res = await bindOther(params);
    updateWithLogin(res.data);
    return res;
  }

  function logout() {
    // 只保留账密
    state.loginInfo = {
      account: state.loginInfo.account,
      mm: state.loginInfo.mm,
    };
    cachedLoginInfo.value = state.loginInfo;
  }

  async function checkPushSubscription() {
    if (isSubscription.value) return isSubscription.value;
    const res = await subscriptionCheck();
    isSubscription.value = Number(res.data.is_subscription);
    return isSubscription.value;
  }

  return {
    state,
    tokenGetter,
    login,
    loginWithOther,
    logout,
    updateLoginInfo,
    bind,
    isLogin,
    checkPushSubscription,
    isSubscription,
  };
});
