<template>
  <div class="video-wrap" :data-did="item.drama_id" :style="{ height: `${contentHeight}px` }">
    <div class="video-wrap-info">
      <h3 class="title">
        {{ item.title }} <em>EP.{{ item.last_play_episode }}</em>
      </h3>
      <p class="desc">{{ item.desc }}</p>
    </div>
    <div class="video-wrap-options">
      <div class="avatar">
        <img :src="item.cover" alt="" />
      </div>
      <div class="collect" @click="handleCollect(item)">
        <img :src="item.collected ? pngCollectActive : pngCollect" alt="" />
        <span>{{ formatNumber(item.total_collect_num) }}</span>
      </div>
      <div class="episode" @click="handleEpisode()">
        <img :src="pngEpisode" alt="" />
        <span>{{ $t('video.select') }}</span>
      </div>
      <div class="share" @click="handleShare(item)">
        <img :src="pngShare" alt="" />
        <span>{{ $t('record.btn.share') }}</span>
      </div>
    </div>
    <div v-if="!playStatus" class="video-wrap-poster" @click="myPlay()">
      <div class="poster" v-lazy:background-image="item.cover" alt="">
        <img :src="pngPlay" alt="" class="icon" />
      </div>
    </div>
    <div class="xg-player-wrap" :id="domId" :style="{ height: `${contentHeight}px` }"></div>
  </div>
  <!-- 分享 -->
  <share :show="showShare" :shareInfo="shareInfo" @hide="showShare = false"></share>
</template>

<script setup lang="ts" name="k-video">
import pngCollect from '../../../imgs/recommend/i-collect.png';
import pngCollectActive from '../../../imgs/recommend/i-collect-a.png';
import pngEpisode from '../../../imgs/recommend/i-episode.png';
import pngShare from '../../../imgs/recommend/i-share.png';
import pngPlay from '../../../imgs/recommend/i-play.png';
import { formatNumber } from '@/utils';
import Player, { Events } from 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls';
import 'xgplayer/dist/index.min.css';
import { useSwiper, useSwiperSlide } from 'swiper/vue';
import { tabName, useHomeStore } from '@/pages/index/store/modules/home';
import { collection } from '@/pages/index/api';
import xl from '@/utils/log';
import share from '@/components/share/index.vue';
import { tabBarHeight } from '@/utils/auth';
import { ShareItem } from '@/components/share/type';
import { TOKTV_MOVIETASK_ID } from '@/pages/index/keys';
import { useTaskStore } from '@/pages/index/store/modules/task';
import { vlog } from '@/utils/vlog';
import { usePayStore } from '@/pages/index/store/modules/pay';
import { isIOS, isPro } from '@/utils/is';

const taskStore = useTaskStore();
const movieTaskId = useStorage(TOKTV_MOVIETASK_ID, '', sessionStorage);

const contentHeight = computed(() => window.innerHeight - tabBarHeight.value);

const showShare = ref(false);
const shareInfo = ref<ShareItem>({
  drama_id: '',
  drama_num: '',
  title: '',
  source: '',
});
const swiper = useSwiper();
const swiperSlide = useSwiperSlide();
const router = useRouter();
const homeStore = useHomeStore();
const payStore = usePayStore();

const isRecommendTab = computed(() => homeStore.currentTab === tabName.recommend);

const props = defineProps<{ item: DramaItem }>();
// const emit =

// 播放状态
const playStatus = ref(false);
// DOM id
const domId = computed(() => `'xg_player_'${props.item.drama_id}`);
// xgPlayer对象
const xgPlayer = ref<Player | null>(null);
// 记录开始播放时间
let starttime = 0;
// 记录加载时间
let loadedtime = 0;
// 记录播放次数
let playCount = 0;
let videoLoadedDataFlag = false;

const onVideoLoadStart = () => {
  !isPro && console.log('[VIDEO LOADED]', props.item);
  loadedtime = Date.now();
};
const onVideoLoadedData = () => {
  !isPro && console.log('[VIDEO LOADED_DATA]', props.item);
  loadedtime = Date.now() - loadedtime;
  videoLoadedDataFlag = true;
};
const onVideoPlay = () => {
  !isPro && console.log('[VIDEO PLAY]', props.item);
  starttime = Date.now();
  playStatus.value = true;
  xl.act({ actentryid: '200001', subactid: props.item.drama_id, type: 'click' });
  const { drama_id, title, total_episode, source, last_play_episode } = props.item;
  vlog({
    dramaid: drama_id, // 剧id
    dramaname: title, // 剧名
    episode: last_play_episode, // 第N集
    dramasource: source, // 短剧来源 取source字段
    totalepisodes: total_episode, // 剧的总集数
    playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
    duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
    viewduration: xgPlayer.value?.currentTime, // 进度条时间
    operate: '3', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
    bigsource: '5', // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
    loadingtime: playCount == 0 ? (videoLoadedDataFlag ? loadedtime : starttime - loadedtime) : '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
    starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
    // endtime: '', // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
    // unlocked: '', // 解锁集数
    // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  });
  playCount++;
};
const onVideoPause = () => {
  if (xgPlayer.value?.currentTime === xgPlayer.value?.duration) {
    // 播放完成导致的暂停（非用户行为）
    return;
  }
  !isPro &&
    console.log('[VIDEO PAUSE]', props.item, xgPlayer.value?.currentTime, xgPlayer.value?.duration);
  playStatus.value = false;
  xl.act({ actentryid: '200001', subactid: props.item.drama_id, type: 'close' });
  const { drama_id, title, total_episode, source, last_play_episode } = props.item;
  const endtime = Date.now();
  // const viewduration = endtime - starttime;
  vlog({
    dramaid: drama_id, // 剧id
    dramaname: title, // 剧名
    episode: last_play_episode, // 第N集
    dramasource: source, // 短剧来源 取source字段
    totalepisodes: total_episode, // 剧的总集数
    playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
    duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
    viewduration: xgPlayer.value?.currentTime, // 进度条时间
    operate: '5', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
    bigsource: '5', // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
    starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
    endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
    // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
    // unlocked: '', // 解锁集数
    // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  });
};
const onVideoEnded = () => {
  !isPro && console.log('[VIDEO ENDED]', props.item);
  const { drama_id, title, total_episode, source, last_play_episode } = props.item;
  const endtime = Date.now();
  vlog({
    dramaid: drama_id, // 剧id
    dramaname: title, // 剧名
    episode: last_play_episode, // 第N集
    dramasource: source, // 短剧来源 取source字段
    totalepisodes: total_episode, // 剧的总集数
    duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
    viewduration: xgPlayer.value?.currentTime, // 进度条时间
    operate: '4', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
    bigsource: '5', // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
    playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
    starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
    endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
    // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
    // unlocked: '', // 解锁集数
    // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  });
  swiper.value.slideNext();
  if (movieTaskId.value) {
    taskStore.reqReportTaskSuc(movieTaskId.value, `${props.item.drama_id}-${1}`);
  }
};
const onVideoError = () => {
  !isPro && console.log('[VIDEO ERROR]', props.item);
};
const onVideoSeeking = () => {
  !isPro && console.log('[VIDEO SEEKING]', props.item);
};

const initPlayer = (autoplay = false) => {
  if (xgPlayer.value) {
    return;
  }
  nextTick(() => {
    const item = props.item;
    const tabbarHeight = document.querySelector('.tabbar')?.clientHeight || 0;
    xgPlayer.value = new Player({
      id: domId.value,
      url: item.last_play_episode_url,
      plugins: isIOS ? [] : [HlsJsPlayer],
      poster: item.cover,
      width: window.innerWidth,
      height: window.innerHeight - tabbarHeight,
      autoplay: autoplay, //自动播放
      loop: false, //循环播放
      fluid: true,
      icons: {
        startPlay: `<div class='play-icon'><img src="${pngPlay}"/></div>`,
      },
      // volume: 0,
      closeVideoDblclick: true,
      start: {
        isShowPause: true,
      },
      hls: {
        preloadTime: 20, // 默认值
        autoStartLoad: true,
        startPosition: 0,
        maxBufferLength: 40,
      },
      // inactive: 0,
      // autoplayMuted: true, //是否自动静音自动播放
      controls: true, //控制器
      mobile: {
        gestureX: false,
        gestureY: false,
        closedbClick: true,
        focusVideoClick: true,
        gradient: 'none',
      },
      // errorTips: `请<span>刷新</span>试试`,
      'x5-video-player-type': 'h5',
      'x5-video-player-fullscreen': 'true',
      'x5-video-orientation': 'portraint',
    });
    // 视频内容开始加载
    xgPlayer.value.on(Events.LOAD_START, onVideoLoadStart);
    // 视频起播数据加载完成
    xgPlayer.value.on(Events.LOADED_DATA, onVideoLoadedData);
    // 播放
    xgPlayer.value.on(Events.PLAY, onVideoPlay);
    // 暂停
    xgPlayer.value.on(Events.PAUSE, onVideoPause);
    // 播放结束
    xgPlayer.value.on(Events.ENDED, onVideoEnded);
    // 出错
    xgPlayer.value.on(Events.ERROR, onVideoError);
    // seek播放
    xgPlayer.value.on(Events.SEEKING, onVideoSeeking);
  });
};

const myPlay = () => {
  if (xgPlayer.value) {
    xgPlayer.value.play();
  } else {
    // 注册播放器
    initPlayer(true);
  }
};

const handleCollect = async (item: DramaItem) => {
  // console.log('收藏');
  xl.act({ actentryid: '200003', type: 'click' });
  const collect = item.collected;
  try {
    item.collected = collect == 1 ? 0 : 1;
    await collection({
      drama_id: item.drama_id, // 剧ID
      collected: item.collected, // 0取消收藏 1收藏
    });
  } catch (error) {
    console.error(error);
    item.collected = collect;
  }
};

const handleEpisode = () => {
  // console.log('选集');
  xl.act({ actentryid: '200004', type: 'click' });
  router.push({
    name: 'video',
    query: {
      drama_id: props.item.drama_id,
      source: props.item.source,
      episode: 1,
    },
  });
};

const handleShare = (item: DramaItem) => {
  // console.log('分享');
  xl.act({ actentryid: '200005', type: 'click' });
  showShare.value = true;
  shareInfo.value = {
    drama_id: item.drama_id,
    drama_num: item.episode,
    title: item.title,
    source: item.source,
  };
};

watch(
  swiperSlide,
  (v) => {
    // console.log(props.item.drama_id, { ...v });
    if (v.isActive || v.isNext || v.isPrev) {
      !isPro && console.log('[init player]', props.item.drama_id);
      initPlayer();
    } else {
      xgPlayer.value?.destroy();
      xgPlayer.value = null;
    }
    if (v.isActive) {
      xgPlayer.value?.play();
    } else {
      playStatus.value = false;
      xgPlayer.value?.pause();
    }
  },
  { immediate: true }
);

watch(isRecommendTab, (v) => {
  if (v && swiperSlide.value.isActive) {
    myPlay();
  } else {
    xgPlayer.value?.pause();
  }
});

onMounted(() => {
  const movieTaskId = useStorage(TOKTV_MOVIETASK_ID, '', sessionStorage);
  if (!movieTaskId.value) {
    // 如果用户未从看剧任务入口跳转，需要存一个不变的任务ID，用于看剧任务上报
    movieTaskId.value = '7';
  }
});
</script>

<style lang="scss" scoped>
.video-wrap {
  position: relative;
  display: flex;
  width: 100%;
  // height: calc(100vh - 50px);
  color: #fff;
  background-color: #000;

  .xg-player-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    // height: calc(100vh - 50px);
    background-color: #000;

    video {
      background-color: #000;
    }
  }

  &-poster {
    position: relative;
    width: 100%;
    height: 100%;

    .poster {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      .icon {
        width: 53px;
        height: 61px;
      }
    }
  }

  &-info {
    position: absolute;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 0 15px 20px;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.2) 30%,
      rgba(0, 0, 0, 0.6) 100%
    );

    .title {
      color: #fff;
      font-size: 17px;
      line-height: 1em;

      em {
        font-size: 11px;
        font-style: normal;
      }
    }

    .desc {
      display: -webkit-box;
      margin-top: 4px;
      overflow: hidden;
      color: #999;
      font-size: 13px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &-options {
    position: absolute;
    right: 10px;
    bottom: 90px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 60px;
    height: 250px;
    font-size: 13px;
    text-align: center;

    .avatar {
      width: 43px;
      height: 43px;
      overflow: hidden;
      background-color: #000;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .collect,
    .episode,
    .share {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        margin-bottom: 4px;
      }

      // span {
      //   max-width: 70px;
      //   overflow: hidden;
      //   white-space: nowrap;
      //   text-overflow: ellipsis;
      // }
    }
  }
}

:deep(.xgplayer) {
  xg-start-inner {
    overflow: inherit;
    background: transparent;
  }

  video {
    object-fit: cover;
  }

  .xgplayer-poster {
    background-size: contain;
    opacity: 0.7;
  }

  .xgplayer-replay {
    opacity: 0;
  }
}

:deep(.play-icon) {
  width: 53px;
  height: 61px;

  img {
    width: 100%;
    height: 100%;
  }
}

:deep(.xgplayer-controls) {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 4px;
  visibility: inherit !important;
  opacity: 1 !important;

  .xg-left-grid,
  .xgplayer-time,
  .xg-right-grid {
    display: none !important;
  }

  .xg-pos {
    right: 0;
    left: 0;
  }

  .xg-center-grid,
  .flex-controls {
    padding: 0 !important;
  }

  .xgplayer.xgplayer-mobile .flex-controls .xg-center-grid {
    padding: 0 !important;
  }

  .xg-inner-controls {
    bottom: 0;
    height: 100%;
  }

  .xgplayer-progress {
    height: 4px;

    .xgplayer-progress-outer {
      height: 100%;
    }

    .xgplayer-progress-cache {
      background: rgba(255, 255, 255, 0.3);
    }

    .xgplayer-progress-played {
      background: rgba(255, 255, 255, 1);
    }

    .xgplayer-progress-btn {
      left: 4px;
      width: 7px;
      height: 7px;
      background: #fff;
      border: none;
      border-radius: 50%;
      box-shadow: -1px 0 1.5px 0 rgba(0, 0, 0, 0.1);

      &::before {
        width: 7px;
        height: 7px;
      }
    }
  }
}

// :deep(.xg-text-track) {
//   bottom: var(--bottom) !important;
// }
</style>
