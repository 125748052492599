import { defineStore } from 'pinia';

export enum tabName {
  discover = 'discover',
  recommend = 'recommend',
}

export interface State {
  currentTab: tabName; // 首页顶部tab选项
  topScroll: boolean; // 首页顶部tab状态（背景效果）
}

export const useHomeStore = defineStore('home', () => {
  const currentTab = useStorage<tabName>('TOKTV_HOME_TAB_NAME', tabName.discover);
  const topScroll = ref(false);
  const scrollPosition = useStorage('TOKTV_HOME_SCROLL_POS', 0);
  const SCROLL_THRESHOLD = 100;

  watch(
    currentTab,
    (v) => {
      if (v === tabName.recommend) {
        topScroll.value = false;
      } else {
        if (scrollPosition.value > SCROLL_THRESHOLD) {
          topScroll.value = true;
        }
      }
    },
    { immediate: true }
  );

  watch(scrollPosition, (v) => {
    if (v >= SCROLL_THRESHOLD) {
      topScroll.value = true;
    } else {
      topScroll.value = false;
    }
  });

  return { currentTab, topScroll, scrollPosition };
});
