import { showLoadingToast, type ToastWrapperInstance } from 'vant';

interface WithLoadingOptions {
  delay?: number; // 延迟多久显示loading，0 不延迟
  message?: string; // loading message
}

export async function withLoading<T extends (...args: any[]) => Promise<any>>(
  cb: T,
  opts?: WithLoadingOptions
): Promise<ReturnType<T>> {
  const delay = opts?.delay || 300;
  const message = opts?.message || '';

  let toast: ToastWrapperInstance | undefined;
  let timer;
  let res;
  const loadingOptions = {
    message,
    forbidClick: true,
    duration: 0,
  };
  if (delay == 0) {
    toast = showLoadingToast(loadingOptions);
  } else {
    timer = setTimeout(() => {
      toast = showLoadingToast(loadingOptions);
    }, delay);
  }
  try {
    res = await cb();
  } catch (error) {
    console.error('withLoading :>> ', error);
    res = Promise.reject(error);
  }
  timer && clearTimeout(timer);
  toast && toast.close();
  return res;
}
