<template>
  <div class="detail-index">
    <div class="header">
      <i class="back" @click="() => goBack()"></i>
      <p class="title">{{ $t('play.item.gold') }}</p>
    </div>
    <van-list
      v-model:loading="loading"
      :finished-text="$t('play.txt.more')"
      :finished="finished"
      @load="onLoad"
    >
      <div class="items">
        <div class="item" v-for="(item, index) in list" :key="index">
          <p class="title">
            {{ item.memo2 }}
            <!-- <span v-if="item.memo2">（{{ item.memo2 }}）</span> -->
          </p>
          <p class="time">{{ getActivityTime(item.tradetime, -1, true) }}</p>
          <p :class="['num', { num1: item.logtype !== 1 }]">
            {{ item.logtype !== 1 ? `-` : '+' }}{{ item.all }} {{ $t('task.gold') }}
          </p>
        </div>
      </div>
    </van-list>
    <div class="empty-con" v-if="isEmpty">
      <div class="empty">
        <div class="img"></div>
        <p class="txt">{{ $t('play.txt.no_gold') }}~</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
//k币明细
import { getGoldInoutList } from '@/pages/index/api/index';
import { getActivityTime } from '@/utils/create-time';
import { goBack } from '@/pages/index/router';
import { isPro } from '@/utils/is';
interface DetailListItem {
  memo2: string;
  tradetime: number;
  logtype: number;
  all: number;
}
const loading = ref(false);
const finished = ref(false);
const page_num = ref('');
const router = useRouter();
const list = ref<DetailListItem[]>([]);
const isEmpty = ref(false);
onMounted(() => {});
function onLoad() {
  getList();
}

// 减k币
async function getList() {
  if (finished.value) return;

  loading.value = true;
  try {
    const { data } = await getGoldInoutList({
      pagekey: page_num.value,
      logtype: '0',
    });
    page_num.value = data.g_list.pagekey || '0';
    !isPro && console.log('使用明细:', data);
    list.value = data.g_list.details || [];
    finished.value = true;
    isEmpty.value = list.value.length <= 0;
  } catch (error) {
    finished.value = true;
  }
  loading.value = false;
}
// onMounted(()=>{
//   getList()
// })
</script>
<style lang="scss" scoped>
.detail-index {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  padding-bottom: 5px;
  background: #fff;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  .header {
    position: relative;
    z-index: 5;
    width: max-content;
    width: 100%;
    height: 40px;
    background: #fff;

    .back {
      position: absolute;
      top: 50%;
      left: 15px;
      width: 20px;
      height: 20px;
      background: url('@/pages/index/imgs/play/back1.png') no-repeat top center;
      background-size: 100% 100%;
      transform: translateY(-50%);
    }

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #222;
      font-weight: bold;
      font-size: 18px;
      font-family: 'Source Han Sans CN';
      white-space: nowrap;
      transform: translate(-50%, -50%);
    }
  }

  .items {
    width: 345px;
    margin: 0 auto;

    .item {
      position: relative;
      width: 100%;
      height: 62.5px;
      border-bottom: 1px solid #f5f5f5;

      .title {
        position: absolute;
        top: 12px;
        left: 0.5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #222;
        font-weight: 500;
        font-size: 16px;
        font-family: 'Source Han Sans CN';
        white-space: nowrap;

        span {
          display: inline-block;
          margin-left: 13.5px;
          color: #999;
          font-weight: 400;
          font-size: 13px;
          font-family: 'Source Han Sans CN';
          white-space: nowrap;
        }
      }

      .time {
        position: absolute;
        top: 34.5px;
        left: 0.5px;
        color: #999;
        font-weight: 400;
        font-size: 13px;
        font-family: 'Source Han Sans CN';
        white-space: nowrap;
      }

      .num {
        position: absolute;
        top: 24px;
        right: 1px;
        color: #ff3666;
        font-weight: 500;
        font-size: 16px;
        font-family: 'Source Han Sans CN';
        white-space: nowrap;

        &.num1 {
          color: #97c7ff;
        }
      }
    }
  }

  .empty-con {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    background: #fff;
  }

  .empty {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 147px auto 0;

    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      height: 200px;
      background: url('../../../imgs/empty.png') no-repeat top center;
      background-size: 100% 100%;
    }

    .txt {
      position: absolute;
      top: 165px;
      left: 50%;
      color: #bebebe;
      font-weight: 400;
      font-size: 13px;
      font-family: 'Source Han Sans CN';
      transform: translateX(-50%);
    }
  }
}
</style>
