import req from './base';

const URL_USER = import.meta.env.VITE_APP_API_USER;
const URL_DRAMA = import.meta.env.VITE_APP_API_DRAMA;
const URL_PAY = import.meta.env.VITE_API_BASE_URL_PAY;
const URL_BASIC = import.meta.env.VITE_APP_API_BASIC;
const URL_ACTIVITY = import.meta.env.VITE_APP_API_ACTIVITY;

// 分类数据
export const getClassifyList: IRequest<any, any> = (data) => {
  return req.post(`${URL_DRAMA}/index/get_classify_list`, data);
};

// banner数据
export const getBannerList: IRequest<any, any> = (data) => {
  return req.post(`${URL_DRAMA}/index/get_recommend_list`, data);
};

// 剧集列表
export const getDramaList: IRequest<any, any> = (data) => {
  return req.post(`${URL_DRAMA}/index_wxs/drama_list`, data);
};

// 收藏列表
export const getCollectionList: IRequest<any, any> = (data) => {
  return req.post(`${URL_DRAMA}/index/collection_list`, data);
};

// 观看记录
export const getRecorded: IRequest<any, any> = (data) => {
  return req.post(`${URL_DRAMA}/index/recorded`, data);
};

// 获取剧集播放详情
export const getDramaInfos: IRequest<any, any> = (data) => {
  return req.post(`${URL_DRAMA}/index/get_drama_infos`, data);
};

// 获取剧集播放详情
export const getGuessLike: IRequest<any, { lists: DramaItem[] }> = (data) => {
  return req.post(`${URL_DRAMA}/index/guess_like_v2`, data);
};

// 账密获取和登录
export const loginWithAccount: IRequest<ApiLogin.CipherRequestParams, ApiLogin.Response> = (
  data
) => {
  return req.post(`${URL_USER}/login/main_login/loginwithcipher`, data);
};

// 账密获取和登录
export const bindOther: IRequest<ApiLogin.LoginRequestParams, ApiLogin.Response> = (data) => {
  return req.post(`${URL_USER}/login/account_api/bind_other`, data, { noToast: true });
};

// 三方登录
export const loginWithOtherAccount: IRequest<ApiLogin.LoginRequestParams, ApiLogin.Response> = (
  data
) => {
  return req.post(`${URL_USER}/login/main_login/loginwithotheraccount`, data);
};

//查询用户绑定账号的状态
export function checkBindStatus(params: { usertype: ApiLogin.UserType }) {
  return req.post(`${URL_USER}/login/account_api/check_bind_status`, params);
}

//获取视频字幕srt文件
export const getSrtContent: IRequest<any, any> = (url: string) => {
  return req.get(url, {});
};

//获取单集链接
export function getEpisodeUrl(params: {
  drama_id: string;
  episode: string | number;
  source: string;
  isErr: number;
}) {
  return req.post(`${URL_DRAMA}/index_wxs/get_episode_url`, params, { noLogin: true });
}

//解锁剧集
export function getUnlockEpisode(params: {
  drama_id: string;
  episode: string | number;
  ecpm_info?: string;
  source: string;
  vip_unlock: number;
  use_k: number;
  isErr?: number;
}) {
  return req.post(`${URL_DRAMA}/index/unlock_episode`, params, { noToast: true });
}

//获取会员商品列表
export function getAppMemberList(params: { goods_ver: number }) {
  return req.post(`${URL_PAY}/goods/app_member_list`, params);
}

//获取k点商品列表
export function getAppRechargeList(params?: any) {
  return req.post(`${URL_PAY}/goods/app_recharge_list`, params);
}
//视频页面列表
export function getJumpGoodsList(params?: any) {
  return req.post(`${URL_PAY}/goods/jump_goods_list`, params);
}

//获取k点付费记录
export function getRechargeOrderList(params?: any) {
  return req.post(`${URL_PAY}/recharge/recharge_order_list`, params);
}

//会员订单列表
export function getpayOrderList(params?: any) {
  return req.post(`${URL_PAY}/member/pay_order_list`, params);
}

//获取K点使用记录
export function getRechargeInoutList(params: { pagekey: string; logtype: string }) {
  return req.post(`${URL_PAY}/recharge/recharge_inout_list`, params);
}

//获取金币使用记录
export function getGoldInoutList(params: { pagekey: string; logtype: string }) {
  return req.post(`${URL_PAY}/recharge/gold_inout_list`, params);
}

//APP下单接口
export function getPay(params: PayType.playItemFormat) {
  return req.post(`${URL_PAY}/order/pay`, params);
}

//查询支付结果
export function getCheck(params: { orderId: string; entranceId?: string }) {
  return req.post(`${URL_PAY}/order/check_fd`, params);
}

//会员信息
export function getUserInfo(params?: any) {
  return req.post(`${URL_PAY}/member/user_info`, params);
}

//收藏/取消收藏
export function collection(params?: any) {
  return req.post(`${URL_DRAMA}/index/collection`, params);
}

// 查询IP所在地
export const getIpInfo: IRequest<void, IpInfo> = () => {
  return req.post(`${URL_BASIC}/ip_infos/get_ip_info/loc`);
};

//商品列表V2
export function getJumpGoodsListV2(params?: any) {
  return req.post(`${URL_PAY}/goods/jump_goods_list_v2`, params);
}

//挽留弹框 retention_type 1-首页挽留 2-视频页面挽留
export function getRetentionGoods(params: { retention_type: 1 | 2 }) {
  return req.post(`${URL_PAY}/goods/retention_goods`, params);
}

//签到-入口配置
export function getSignInfo() {
  return req.post(`${URL_ACTIVITY}/sign/index/index`);
}

//签到-去签到
export function signReq(params: { now_days: number }) {
  return req.post(`${URL_ACTIVITY}/sign/index/sign`, params);
}

//签到-领取额外奖励
export function signdbReq(params: { now_days: number }) {
  return req.post(`${URL_ACTIVITY}/sign/index/get_sign_db`, params);
}

//任务列表
export function getTaskList() {
  return req.post(`${URL_ACTIVITY}/task/task/task_list`);
}

//任务-领取任务奖励
export function taskRewardReq(params: { task_id: string }) {
  return req.post(`${URL_ACTIVITY}/task/task/get_task_reward`, params);
}

//任务-上报完成
export function reportTaskSuc(params: { task_id: string; event_id?: string }) {
  return req.post(`${URL_ACTIVITY}/task/task/task_success`, params);
}

//大转盘-入口配置
export function getTurntableConfig() {
  return req.post(`${URL_ACTIVITY}/turntable/index/get_conf`);
}

//大转盘-抽奖
export function turntableLuckDraw(params: { number: number }) {
  return req.post(`${URL_ACTIVITY}/turntable/index/luck_draw`, params);
}

//大转盘-领取翻倍奖励
export function turntableGetDouble(params: { number: number }) {
  return req.post(`${URL_ACTIVITY}/turntable/index/ext_reward`, params);
}

// 公共配置
export function commonConfig() {
  return req.post(`${URL_BASIC}/config/common_conf/index`);
}

// 允许订阅
export function subscriptionAllow(params: { subscription: string; country_code: string }) {
  return req.post(`${URL_PAY}/subscription/allow`, params);
}

// 获取订阅状态
export function subscriptionCheck() {
  return req.post(`${URL_PAY}/subscription/check`);
}
