// const publicVapidKey =
//   'BGDzhyZA4oxLFHbYjFr0psVcZ__D0ZFIl647MCj8kdBaDAFlv_gFcJJXl_D1EFHIjSmw0GkN1SRRLz_Fut-brn8';

import { subscriptionAllow } from '@/pages/index/api';
import { getCachedIPInfo, swRegistered } from './auth';

// 请求通知权限
export async function requestNotificationPermission() {
  if (!window.Notification) {
    return Promise.reject('浏览器不支持桌面通知');
  }
  return Notification.requestPermission();
  // if (permission === 'granted') {
  //   return permission;
  // }
  // return Promise.reject('用户未授权通知权限');
}

// 将 VAPID 公钥从 URL Base64 字符串转换为 Uint8Array
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// 订阅推送服务
export async function subscribeUserToPush() {
  if ('serviceWorker' in navigator) {
    const isPro = import.meta.env.VITE_APP_ENV === 'production';
    const publicVapidKey = isPro
      ? 'BIMMkhnlppqlddxOVrm3gnpqwiheG5vbpcvsb-oUCFjS2ClqN4V35tnDrFuhUN2JqPZK3C5CUU66IOeUMn_yqzE'
      : 'BGDzhyZA4oxLFHbYjFr0psVcZ__D0ZFIl647MCj8kdBaDAFlv_gFcJJXl_D1EFHIjSmw0GkN1SRRLz_Fut-brn8';

    console.log('[WEB-PUSH] publicVapidKey >>', publicVapidKey);
    const convertedVapidKey = urlBase64ToUint8Array(publicVapidKey);

    const registration = await navigator.serviceWorker.ready;
    let subscription = await registration.pushManager.getSubscription();
    console.log('[WEB-PUSH] subscription before >>', subscription);
    if (!subscription) {
      // 用户还没有订阅，进行订阅
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });
    }
    console.log('[WEB-PUSH] subscription after >>', subscription);
    return subscription;
  }
  return Promise.reject();
}

// 发送订阅信息到服务器
export function sendSubscriptionToServer(subscription: PushSubscription) {
  const { country_code } = getCachedIPInfo();
  return subscriptionAllow({ subscription: JSON.stringify(subscription), country_code });
}

export async function subscribeToServer() {
  const subscription = await subscribeUserToPush();
  console.log('[WEB-PUSH] User is subscribed:', subscription, JSON.stringify(subscription));
  if (subscription) {
    return sendSubscriptionToServer(subscription);
  }
  return Promise.reject('[WEB-PUSH] 未获取到订阅信息subscription');
}

// 检查通知权限
export function checkNotificationPermission() {
  console.log('[WEB-PUSH] checkNotificationPermission:', window.Notification?.permission);
  // 用户明确同意接收通知。
  return window.Notification?.permission === 'granted';
}

// 检查推送订阅状态
export async function checkPushSubscription() {
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      console.log('[WEB-PUSH] getSubscription:', subscription);
      return subscription !== null;
    }
  } catch (error) {
    console.error('[WEB-PUSH] checkPushSubscription:', error);
  }
  return false;
}

// 综合检查用户是否允许接收推送通知
export async function isPushNotificationEnabled() {
  const hasNotificationPermission = checkNotificationPermission();
  const isSubscribed = await checkPushSubscription();
  console.log('[WEB-PUSH] isPushNotificationEnabled:', hasNotificationPermission, isSubscribed);
  return hasNotificationPermission && isSubscribed;
}

export async function registerSWMessage(cb: (data: { type: string; data: any }) => void) {
  console.log('[SW] registerSWMsg swRegistered', swRegistered.value);
  navigator.serviceWorker.addEventListener('message', function (event) {
    console.log('[WEB-PUSH] Received message from Service Worker:', event.data);
    // 可以在这里处理接收到的消息
    cb && cb(event.data);
  });
}

export function myPostMessage(data: { type: string; data: any; [k: string]: any }) {
  // 向已注册的 Service Worker 发送消息
  // navigator.serviceWorker.ready.then(function (registration) {
  //   if (registration.active) {
  //     registration.active.postMessage(data);
  //   }
  // });

  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(data);
  }
}
