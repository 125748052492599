<template>
  <van-popup class="x-popup" v-model:show="show" round>
    <div class="x-popup-title">{{ title || $t('login.bind.popup.title') }}</div>
    <p class="x-popup-content">{{ content || $t('login.bind.st1') }}</p>
    <div class="x-popup-btn" @click="handleClose">{{ $t('login.ok2') }}</div>
  </van-popup>
</template>

<script setup lang="ts" name="x-popup">
interface IProps {
  visible: boolean;
  title?: string;
  content?: string;
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
});

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'close'): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});

const handleClose = () => {
  show.value = false;
  emit('close');
};
</script>

<style scoped lang="scss">
.x-popup {
  width: 300px;
  padding: 25px;
  // z-index: 3000 !important;

  &-title {
    margin-bottom: 10px;
    color: #222;
    font-weight: bold;
    font-size: 19px;
    line-height: 2em;
    text-align: center;
  }

  &-content {
    color: #999;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5em;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 47px;
    margin: 30px auto 0;
    color: #fff;
    font-size: 18px;
    background: #ff3868;
    border-radius: 28px;
  }
}
</style>
