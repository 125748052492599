<template>
  <div class="content">
    <span class="right first">本版本發佈日期：2023年08月04日</span>
    <span class="right">本版本生效日期：2023年08月04日</span>
    <div class="panel">
      <p>歡迎您使用Toktv APP！</p>
      <p>
        Toktv
        APP由香港星能永勝科技有限公司（“我们”）開發運營，注册地址：香港九龍灣新蒲崗大有街3號萬迪廣場19H。本隱私政策（“本政策”）解釋了我們是誰，我們如何收集、共享和使用您的個人信息，以及您如何行使您的隱私權。本隱私政策僅適用於通過我們的Toktv
        APP上提供的所有服務收集的個人信息。
      </p>
      <p>
        您同意本政策或繼續使用Toktv服務將被視爲同意並授權我們按照本政策收集、使用、存儲和處理您的個人信息。我們會不時更新本政策，並會以推送通知、公告或彈窗等一種或多種方式通知您。
      </p>
      <p>
        在使用我們的服務之前，請仔細閱讀本隱私政策並充分理解本隱私政策的全部內容。如果您對我們使用您的個人信息有任何疑問或疑慮，請使用本隱私政策底部提供的聯系方式與我們聯系。
      </p>
      <p>一、我們收集和處理的關於您的哪些個人信息</p>
      <p>二、使用您的個人資料</p>
      <p>三、與誰分享您的個人信息</p>
      <p>四、cookie和類似技術的使用</p>
      <p>五、保護您的個人信息安全</p>
      <p>六、國際數據傳輸</p>
      <p>七、保留您的個人信息時間</p>
      <p>八、您的數據保護權利</p>
      <p>九、與兒童有關的信息</p>
      <p>十、本隱私政策的更新</p>
      <p>十一、如何聯系我們</p>
      <p>一、我們收集和處理的關於您的哪些個人信息</p>
      <p>1．您自願提供的信息</p>
      <p>
        我們服務的某些部分會要求您自願提供個人信息。例如，我們可能會要求您提供您的聯系方式，以便在我們這裏注冊一個帳戶。您被要求提供的個人信息，以及要求您提供這些信息的原因，將在我們要求您提供您的個人信息時向您說明。
      </p>
      <p>（1）注冊爲我們服務的用戶</p>
      <p>
        您可以注冊成爲我們服務的用戶。請注意，注冊不是使用我們服務的先決條件。當您注冊並登錄Toktv帳戶時，您需要按照指示步驟完成一系列注冊程序。在此過程中，需要您向我們提供設備信息，我們將在Toktv上生成您的用戶ID號。您可以在注冊時或日後使用時填寫您的暱稱、頭像等相關信息。您應確保此類信息的正確性。我們收集這些信息是爲了提供賬戶注冊和登錄服務，保護您的賬戶，防範安全風險。
      </p>
      <p>（2）在Toktv上創建您的個人資料</p>
      <p>
        您可以進入賬戶設置提供更多關於您自己的個人信息，例如出生數據、暱稱、頭像、個性籤名等。請注意，此步驟對於您使用我們的服務是完全可選的。
      </p>
      <p>2．我們自動收集的信息</p>
      <p>
        （1）當您使用我們的服務時，我們可能會自動從您的設備收集某些信息。在某些情況下，我們使用您的個人信息可能會導致做出自動決策（包括了解您的偏好並根據您表達的觀看偏好向您提示相關視頻）。自動決策意味着有關您的決策是根據計算機確定（使用軟件算法）自動做出的，無需我們的人工審核。您可以使用Toktv上的設置來表達您對某些視頻的偏好，算法會記錄您的選擇，並相應地減少與您分享此類類似視頻的次數。
      </p>
      <p>（2）我們服務平臺的安全、行政和管理</p>
      <p>
        1）在向您提供我們的服務時，我們會自動收集您的設備信息（包括硬件型號、操作系統版本號、MAC地址、網絡設備硬件地址、唯一設備標識符、IP地址、軟件版本號、網絡訪問方式和類型、網絡質量數據、操作日志、使用和服務、設備名稱、系統語言）和您的IP地址、您的應用程序崩潰消息、您的登錄時間和嘗試、您的行爲信息。我們收集此類信息是爲了
        (i) 確保您的安全，包括審查
        UGC、消息和相關元數據是否違反我們的平臺用戶行爲準則和其他不當內容； (ii)
        幫助我們檢測和打擊Toktv上的濫用、有害活動、欺詐、垃圾郵件和非法活動； (iii)
        對平臺進行數據分析和測試，以確保平臺的穩定性和安全性； (iv) 證明爲您提供用戶支持；
      </p>
      <p>2）數據分析</p>
      <p>
        當您輸入搜索詞搜索視頻時，我們將在我們服務的其他用戶中分析您的搜索詞和搜索日志，以便我們找到。請放心，您的搜索詞不會在我們的分析中透露給任何人，您可以選擇刪除搜索詞。
      </p>
      <p>3）視頻顯示和播放</p>
      <p>
        您可以在Toktv上使用視頻顯示和播放，我們會收集您的設備信息，包括您的IP地址、設備類型、型號、設備名稱、通用唯一標識符、操作系統、語言設置、APP版本和您的行爲信息，以記錄您暫停的位置在特定視頻上。
      </p>
      <p>3．我們從第三方來源獲得的信息</p>
      <p>
        1）有時，我們可能會從第三方來源獲得您的個人信息，包括某些 SDK
        提供商，例如他們記錄潛在的IP地址或設備
        ID被識別爲潛在的安全問題，如果此類IP地址或設備ID已發起網絡安全攻擊。在接收有關您的個人信息前，我們專業的安全團隊將加強個人信息的安全（包括敏感信息的上報、敏感信息的加密存儲、訪問控制等。
      </p>
      <p>
        2）您了解，維護Toktv
        APP的運行和我們的服務的安全，對我們來說是重要且合法的利益，例如運營我們的平臺並在必要時，我們可能會與您溝通以向您提供我們的服務並維護我們的合法權益商業利益，例如，在回復您的查詢、改進我們的平臺建議、進行營銷或用於檢測或防止非法活動時。在某些情況下，我們可能還有法律義務從您那裏收集個人信息，或者可能需要這些個人信息來保護您或他人的切身利益。
      </p>
      <p>二、使用您的個人資料</p>
      <p>1．我們將通過以下方式使用我們收集的關於您的信息：</p>
      <p>1）執行我們的條款、條件和政策；</p>
      <p>2）提供、處理、維護、研發和改進我們的服務；</p>
      <p>3）您收到的內容；</p>
      <p>4）與您的溝通，包括發送商業推廣信息、運營活動通知和更新協議；</p>
      <p>
        5）確保您的安全和保障，包括審查用戶內容、消息和相關數據是否違反我們的社區政策和其他不當內容；
      </p>
      <p>6）爲您提供基於位置的服務（如果這些服務在您的管轄範圍內可用）；</p>
      <p>7）進行市場調查和趨勢分析，以及與我們的服務相關的綜合統計、數據分析、處理和調查。</p>
      <p>
        2．一般情況下，我們只會將向您收集的個人信息用於本隱私政策中所述的目的或我們在收集您的個人信息時向您解釋的目的。但是，如果合法允許的情況下，我們也可能將您的個人信息用於與我們向您披露的目的（例如出於公共利益、科學或歷史研究目的或統計目的、存檔目的）或不相抵觸的其他目的。
      </p>
      <p>三、與誰分享您的個人信息</p>
      <p>
        1．您可以基於我們的服務與任何第三方（包括未指定對象）共享您的個人信息或其他信息。例如，您在使用我們服務的分享功能時，可以選擇社交APP（如
        Twitter
        、Facebook等）或其他應用分享相關信息。您分享給任何第三方應用程序的信息將由該第三方控制和處理，但須遵守其服務條款和隱私政策。因您的分享而導致的任何披露、濫用或類似情況，您應自行承擔責任，我們對此不承擔任何責任。
      </p>
      <p>2．我們可能會將您的個人信息披露給以下類別的收件人：</p>
      <p>
        1）業務合作夥伴：如果您選擇使用您的社交網絡帳戶詳細信息（包括
        Facebook、Twitter）注冊使用平臺，您將向我們提供或允許您的社交網絡向我們提供您的用戶名和公開資料。
      </p>
      <p>
        2）服務提供商：我們會向支持我們業務的服務提供商提供信息和內容，例如雲服務提供商和內容審核服務提供商，以確保Toktv是一個安全和愉快的平臺。
      </p>
      <p>3）分析提供商：我們使用分析提供商來幫助我們優化和改進我們的服務。</p>
      <p>
        4）我們認爲有必要披露的任何主管執法機構、監管機構、政府機構、法院或其他第三方 (i)
        根據適用法律或法規，(ii) 行使、確立或捍衛我們的合法權利，或(iii)
        保護您或任何其他人的切身利益。 (iv) 檢測、預防或以其他方式解決安全、欺詐或技術問題； (v)
        根據法律要求或許可保護我們、我們的用戶、第三方或公衆的權利、財產或安全（包括與其他公司和組織交換信息以防止欺詐和降低信用風險）；
        (vi) 執行我們的服務條款和其他協議、政策和標準，包括調查任何可能的違規行爲等。
      </p>
      <p>5）經您同意披露的任何其他人。</p>
      <p>四、 Cookies 和類似的跟蹤技術</p>
      <p>
        1．我們和我們的供應商和服務提供商可能會使用 cookie 和其他類似技術（例如，網絡信標、flash
        cookie
        等）（“cookies”）來自動收集信息、衡量和分析您點擊了哪些網頁以及您如何使用我們的服務，增強您使用Toktv的體驗，改進我們的服務。
        Cookie是小文件，當放置在您的設備上時，平臺能夠提供某些特性和功能。網絡信標是非常小的圖像或嵌入圖像的小塊數據，也稱爲“像素標籤”或“透明
        GIF”，可以識別
        Cookie、查看頁面的時間和日期、像素所在頁面的描述標籤已放置，並且來自您的計算機或設備的類似信息。例如，我們使用谷歌提供的
        Cookie 和類似的跟蹤技術。
      </p>
      <p>五、保護您的個人信息安全</p>
      <p>
        1．我們將會使用適當的技術和組織措施來保護我們收集和處理的有關您的個人信息。我們使用的措施旨在提供與處理您的個人信息的風險相適應的安全級別。例如，我們使用
        SSL
        來加密和保護數據；我們建立了訪問控制機制，確保只有授權人員才能訪問我們用戶的個人信息；我們不定期舉辦安全和隱私保護培訓課程，以提高員工對保護個人信息重要性的認識。
      </p>
      <p>
        2．爲防止安全事故的發生，我們成立了專門的數據安全部門，並制定了適當的預警機制和應急預案。如果發生個人信息安全事件，我們將按照適用法律法規的要求，以適當且足以引起您注意的方式及時通知您，並立即啓動應急預案，以盡量減少損失。不幸的是，通過互聯網傳輸信息並不完全安全。雖然我們會保護您的個人數據，例如通過加密，但我們不能保證您通過平臺傳輸的信息的安全性；任何傳輸都需要您自擔風險。
      </p>
      <p>六、國際數據傳輸</p>
      <p>
        1．您的個人信息將被存儲和保留在新加坡，並且可能會轉移到您居住的國家以外的其他國家/地區並在這些國家/地區進行處理。這些國家/地區的數據保護法可能與您所在國家/地區的法律不同。但是，我們已採取適當的保護措施，要求您的個人信息將根據本隱私政策得到保護。
      </p>
      <p>七、保留您的個人信息時間</p>
      <p>
        1．一般情況下，在我們提供服務的可能期限內，我們只需要在最短的時間內存儲您的個人信息，在此期限之後，我們將根據相關法律法規的要求對您的個人信息進行刪除或匿名化處理。如果我們將此類信息分享給任何第三方，我們也將要求該第三方也這樣做。但是，在以下情況下，我們可能會因相關法律要求而更改信息的存儲期限：
      </p>
      <p>1）遵守適用法律法規的相關規定；</p>
      <p>2）遵循法院判決、裁定或其他法律程序；</p>
      <p>3）遵循有關政府機關或法定授權組織的要求；</p>
      <p>4）我們認爲有必要遵守法律法規的相關規定；</p>
      <p>
        5）爲履行相關服務協議或本政策，維護社會公共利益，保護我們、我們的關聯公司、我們的合作夥伴、其他用戶或員工的人身和財產安全，或保護其他合法權益的正當、必要的目的，利益。
      </p>
      <p>八、您的數據保護權利</p>
      <p>1．作爲我們服務的用戶，您擁有以下數據保護權利：</p>
      <p>1）訪問權</p>
      <p>您可以在我們的服務中查詢或訪問您的相關個人信息，包括：</p>
      <p>
        a．賬戶信息：您可以隨時通過相關產品頁面登錄您的個人賬戶，訪問您賬戶中的個人信息，包括頭像、暱稱、生日等；
      </p>
      <p>
        b．使用信息：您可以通過相關產品頁面隨時查詢您的部分使用信息，包括收藏歷史、查看歷史和下載；
      </p>
      <p>2）更正權</p>
      <p>
        您可以按照Toktv相關產品頁面的操作指南和設置來更正/更改您的部分個人信息，也可以聯系我們直接修改或更改您的數據。
      </p>
      <p>3）刪除權</p>
      <p>
        a．您可以通過發送電子郵件至【toktvservice@outlook.com】要求刪除您的個人信息。如果您想刪除我們服務中與您的第三方帳戶相關的信息，您可以按照我們的
        URL
        數據刪除說明進行操作。對於您的部分個人信息，您也可以自願刪除，您可以通過我們產品設置頁中通過賬號安全-賬號注銷刪除個人信息。
      </p>
      <p>
        b．如果我們違反相關法律法規收集、使用或分享您的個人信息，您可以直接要求我們刪除您的個人信息或通知接收第三方刪除，除非該等數據已經匿名化或相關法律另有規定。法律法規。
      </p>
      <p>
        c．您理解並同意，一旦您自行或在我們的幫助下從我們的服務中刪除您的個人信息，在適用法律法規和相關安全技術的前提下，我們可能不會立即從備份系統中刪除您的信息，在這種情況下，我們將存儲以安全的方式將您的個人信息與任何進一步的數據處理分開，直到在我們的備份系統更新期間刪除或匿名化此類信息。
      </p>
      <p>4）限制或拒絕處理個人信息的權利</p>
      <p>
        如果根據您所在司法管轄區的法律授予您部分個人信息的權利，您可以限制或拒絕處理您的部分個人信息，我們將根據您的實際情況根據該法律處理您的請求。
      </p>
      <p>5）取消您的帳戶的權利</p>
      <p>
        您也可以注銷您的帳戶。我們將通過聯系渠道與您聯系。請在我們驗證您的身份並獲得您的確認後，我們將根據相關法律法規對您在Toktv中產生的所有相關個人數據進行刪除或匿名處理，除非法律法規另有規定。您也可以通過【toktvservice@outlook.com】給我們發送電子郵件以行使權利。
      </p>
      <p>6） 獲取個人信息副本的權利</p>
      <p>
        您可以通過本政策中的聯系方式與我們聯系，在核實您的身份後，我們將在規定的期限內按照我們的規定在我們的服務中爲您提供該等信息的副本，法律法規另有規定或本政策另有約定的除外。
      </p>
      <p>7）投訴權</p>
      <p>
        您有權就我們收集和使用您的個人信息向數據保護機構投訴。如需更多信息，請聯系您當地的數據保護機構。
      </p>
      <p>
        2．爲了行使您的上述權利，您還可以使用第十一部分“如何聯系我們”的聯系方式與我們聯系。我們會根據適用的數據保護法律回應希望行使數據保護權利的個人提出的所有請求。爲了保護您的帳戶和個人信息的安全，我們可能會要求您提供某些附加信息以驗證您的身份，然後再回復您的請求。
      </p>
      <p>九、與兒童有關的信息</p>
      <p>
        1．14歲以下的兒童不得爲服務注冊和創建自己的帳戶。如果您年滿14周歲但在您所在國家/地區被視爲未成年人，您必須讓您的父母或法定監護人與您一起通讀本隱私政策，並且您聲明您已獲得父母或法定監護人的許可監護人創建您的Toktv帳戶。如果父母或法定監護人允許，所有年齡段的兒童都可以使用服務。如果您是您所在國家/地區未成年人的父母或法定監護人，請確保您在允許您的孩子創建自己的帳戶或使用服務之前已閱讀本隱私政策。您同意您將遵守本隱私政策並對您孩子在使用服務時的活動負責。您同意監督您的孩子對服務的使用，並確保您的孩子只查看適合您孩子年齡的內容。
      </p>
      <p>十、本隱私政策的更新</p>
      <p>
        1．我們可能會不時更新本隱私政策以應對不斷變化的法律、技術或業務發展。當我們更新我們的隱私政策時，我們將根據我們所做更改的重要性採取適當措施通知您。如果適用的數據保護法有要求，我們將征得您對本隱私政策的任何重大更改的同意。
      </p>
      <p>十一、如何聯系我們</p>
      <p>
        如果您對本隱私政策和我們對您個人信息的使用有任何疑問或疑慮，請通過【toktvservice@outlook.com】聯系我們。
      </p>
    </div>
  </div>
</template>
