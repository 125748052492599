<template>
  <div class="page wallet">
    <div class="container">
      <div class="top__bar">
        <img @click="() => goBack()" src="../../imgs/wallet/back.png" alt="" />
        <h3>{{ $t('play.txt.wallet') }}</h3>
      </div>
      <div class="gold__des">
        <div class="gold__des__top">
          <div class="li">
            <img src="../../imgs/wallet/icon1.png" alt="" />
            <div class="li__right">
              <div class="num">{{ payStore.memberInfo.k_value }}</div>
              <div class="txt">{{ $t('task.k') }}</div>
            </div>
          </div>
          <div class="li" v-show="isAppcqidOrganic()">
            <img src="../../imgs/wallet/icon2.png" alt="" />
            <div class="li__right">
              <div class="num">{{ payStore.memberInfo.g_value }}</div>
              <div class="txt">{{ $t('task.gold') }}</div>
            </div>
          </div>
        </div>
        <button @click="handleJumpPay">{{ $t('play.txt.recharge') }}</button>
      </div>
      <div class="ul__box">
        <ul>
          <li
            v-for="item in list"
            :key="item.id"
            @click="handleMenuItemEvent(item.id)"
            v-show="item.show"
          >
            <div class="lll">
              {{ $t(item.i18n) }}
            </div>
            <div class="btn" v-if="item.id === 5">
              <img :src="item?.open ? getAssetURL('icon4.png') : getAssetURL('icon5.png')" alt="" />
            </div>
            <img src="../../imgs/wallet/icon3.png" alt="" class="rrr" v-else />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePayStore } from '@/pages/index/store/modules/pay';
import xl, { isAppcqidOrganic } from '@/utils/log';
import { goBack } from '../../router';
import { useUserStore } from '../../store/modules/user';

const router = useRouter();
const payStore = usePayStore();
const userStore = useUserStore();
const isLogin = computed(() => !!userStore.state.loginInfo.token);
const getAssetURL = (image: string) => {
  return new URL(`../../imgs/wallet/${image}`, import.meta.url).href;
};
const list: any = ref([
  {
    id: 1,
    name: '会员充值',
    i18n: 'play.item.vip',
    show: true,
  },
  {
    id: 2,
    name: 'K币充值',
    i18n: 'play.item.recharge',
    show: true,
  },
  {
    id: 3,
    name: 'K币明细',
    i18n: 'play.item.details',
    show: true,
  },
  {
    id: 4,
    name: '金币明细',
    i18n: 'play.item.gold',
    show: isAppcqidOrganic(),
  },
  // {
  //   id: 5,
  //   name: '自动解锁下一集',
  //   open: false,
  // },
]);

onMounted(() => {
  xl.act({ actentryid: '500001', type: 'show' });
});

const handleMenuItemEvent = (id: number) => {
  if (id === 1) {
    isLogin.value ? router.push('/log-vip') : router.push('/login');
    xl.act({ actentryid: '500003', type: 'click' });
  }
  if (id === 2) {
    isLogin.value ? router.push('/log-k') : router.push('/login');
    xl.act({ actentryid: '500004', type: 'click' });
  }
  if (id === 3) {
    isLogin.value ? router.push('/use-k') : router.push('/login');
    xl.act({ actentryid: '500005', type: 'click' });
  }
  if (id === 4) {
    isLogin.value ? router.push('/use-gold') : router.push('/login');
    xl.act({ actentryid: '500006', type: 'click' });
  }
};

const handleJumpPay = () => {
  router.push('/pay');
  xl.act({ actentryid: '500002', type: 'click' });
};
</script>

<style scoped lang="scss">
.container {
  overflow: hidden;
  background: url('../../imgs/wallet/bg.png') no-repeat top/100% #fff;

  .top__bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 40px;

    h3 {
      font-weight: bold;
      font-size: 18px;
    }

    img {
      position: absolute;
      left: 15px;
      width: 25px;
    }
  }

  .gold__des {
    .gold__des__top {
      display: flex;
      justify-content: center;

      .li {
        display: flex;
        align-items: center;
        margin-right: 40px;

        img {
          width: 30px;
          margin-right: 6px;
        }

        .li__right {
          line-height: 1;

          .num {
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 20px;
          }

          .txt {
            color: #956512;
            font-size: 13px;
          }
        }
      }

      .li:last-child {
        margin-right: 0;
      }
    }

    button {
      display: block;
      width: 320px;
      height: 47px;
      margin: 30px auto 0;
      color: #fff;
      font-size: 16px;
      text-align: center;
      background: #222;
      border-radius: 24px;
    }
  }

  .ul__box {
    width: 100%;
    margin-top: 25px;
    padding-top: 1px;
    background: #fff;
    border-radius: 15px 15px 0 0;
  }

  ul {
    margin: 14px 15px 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 53px;
      border-bottom: 1px solid #f2f2f2;

      .lll {
        font-size: 16px;
      }

      .rrr {
        width: 20px;
      }

      .btn {
        img {
          width: 47px;
        }
      }
    }

    li:last-child {
      border: none;
    }
  }
}
</style>
