<template>
  <div class="privacy-policy">
    <div class="top__bar">
      <img @click="$router.back()" src="../../../imgs/wallet/back.png" alt="" />
      <h3>{{ $t('setting.menu3') }}</h3>
    </div>
    <div class="main">
      <hk-content v-if="isZhLanguage"></hk-content>
      <en-content v-else></en-content>
    </div>
  </div>
</template>
<script setup lang="ts">
import useLocale from '@/composables/useLocale';
import enContent from '../privacy-policy/en-content.vue';
import hkContent from '../privacy-policy/hk-content.vue';

const { currentLocale } = useLocale();
const isZhLanguage = computed(() => {
  return currentLocale.value.indexOf('zh') > -1;
});
</script>
<style lang="scss">
.privacy-policy {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top__bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    margin: 20px 0;

    h3 {
      font-weight: bold;
      font-size: 18px;
    }

    img {
      position: absolute;
      left: 15px;
      width: 25px;
    }
  }

  .main {
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
    overflow-x: hidden;
    color: #333;
    font-size: 16px;
    font-family: Avenir, Helvetica, Arial, sans-serif;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    .content {
      width: 100%;
      height: 100%;

      > h3 {
        margin: 34px 0 0;
        color: #333;
        font-weight: 700;
        font-size: 19px;
        text-align: center;
      }

      > span {
        display: block;
        margin-right: 15px;
        color: #333;
        font-size: 14px;
        text-align: right;

        &.right {
          text-align: right;
        }

        &.first {
          margin: 24px 15px 0 0;
          text-align: right;
        }
      }

      .panel {
        width: 345px;
        margin: 0 auto;
        padding-bottom: 20px;
        text-align: justify;

        > h3 {
          margin: 16px 0 0;
          color: #333;
          font-weight: bold;
          font-size: 17px;
        }

        > div {
          margin: 13px 0 0;
          color: #333;
          font-size: 16px;
          text-indent: 2em;
        }

        > p {
          margin: 9px 0 0;
          color: #333;
          font-size: 16px;
          text-indent: 2em;

          &.right {
            text-align: right;
          }

          a {
            color: blue;
          }
        }
      }
    }
  }
}
</style>
