import { defineStore } from 'pinia';
import {
  getUserInfo,
  getJumpGoodsListV2,
  getRetentionGoods,
  getCheck,
} from '@/pages/index/api/index';
import { useUserStore } from './user';
import { isPro } from '@/utils/is';

export const usePayStore = defineStore('pay', {
  state: () => ({
    memberInfo: {
      k_value: 0,
      is_vip: 0,
      is_shop: 0,
      valid: 0,
      g_value: 0,
    } as UserType.UserVipInfo, //用户会员信息字段
    retentionGoods: {} as PayType.PlyItemFormat, //视频详情页面挽留弹框的商品信息
    retentionGoodsNew: {} as PayType.PlyItemFormat, //新的挽留弹框的商品信息
    specialGoods: {} as PayType.PlyItemFormat, //特殊商品（用于解锁全集；当配置了整部剧解锁优先显示手势动画，充值K币中不显示，若未配置整部解锁，只在充值K币中展示第一个位置的手势）
    vipGoodsList: [] as PayType.PlyItemFormat[], //VIP商品
    kGoodsList: [] as PayType.PlyItemFormat[], //K点商品
    kGoodsSpecial: undefined as PayType.PlyItemFormat | undefined, // K点特殊商品，first_commodity === 1
    goodsMixedList: [] as PayType.PlyItemFormat[], //混合商品列表包含以及顺序为（整部剧解锁，会员，K点）
    u_style: 0 | 1 | 2 | 3, //充值面板样式，默认为0，根据OC后台配置切成不同的面板样式，
    user_payment: '', //用户选择的支付方式
    user_region: '', //用户所属地区简写(用户IP所在的国家)
    default_payment: [] as PayType.PaymentFormat[], //默认的支付方式列表
    regionPayment: [] as PayType.RegionPaymentFormat[], //地区信息包含地区简写,地区全称,地区旗帜以及国家支持的支付方式
    userRegionName: '', //从国家/地区页面选择的国家地区
    choosePayments: [] as PayType.PaymentFormat[], //从国家/地区页面选择的国家支持的支付方式
    jump_location: 0, //0-首页 1-未购买过任何档位商品 2-充值过K币 3-充值过整部剧
    resultStatus: 0,
  }),
  getters: {
    isVip(state) {
      return state.memberInfo.is_vip == 1;
    },
    paySuccess(state) {
      return state.resultStatus == 1;
    },
  },
  actions: {
    //获取会员相关信息
    async getVipMemberInfo() {
      const userStore = useUserStore();
      if (!userStore.isLogin) return;
      const res = await getUserInfo();
      !isPro && console.log('用户会员信息====>', res);
      this.memberInfo = res.data.member_info;
    },
    //获取商品列表(vip, K点, 挽留, 解锁全集)
    async getGoodsList(params?: any) {
      const res = await getJumpGoodsListV2(params);
      !isPro && console.log('获取商品列表(vip, K点, 挽留, 解锁全集)====>', res);
      const {
        u_style,
        v_goods,
        k_goods,
        r_goods,
        retention_goods,
        region_payment,
        default_payment,
        user_region,
      } = res.data;
      const ustyle = Number(u_style);
      // 防止后台配置了不支持的样式（默认用样式1）
      this.u_style = [1, 2, 3].includes(ustyle) ? ustyle : 1;
      // 调试用 - start
      // this.u_style = 1;
      // 调试用 - end
      this.vipGoodsList = v_goods;
      this.kGoodsList = k_goods;
      this.kGoodsSpecial = this.kGoodsList.find((item) => item.custom.first_commodity == '1');
      this.kGoodsList = this.kGoodsList.filter((item) => {
        return item.custom.first_commodity != '1';
      });
      this.goodsMixedList = [...this.vipGoodsList, ...this.kGoodsList];
      if (retention_goods[0]) this.retentionGoods = retention_goods[0];
      if (r_goods[0]) {
        this.specialGoods = r_goods[0];
        this.goodsMixedList.unshift(this.specialGoods);
      }
      //从当前国家列表查当前IP所在地，如果不在列表里取默认的支付方式，如果在则取国家列表里支持的支付方式
      const payments = region_payment.find(
        (item: PayType.RegionPaymentFormat) => item.region_info.region === user_region
      );
      !isPro && console.log('是否在国家列表中payments=====>', payments);
      this.user_region = user_region;
      this.userRegionName = payments ? payments.region_info.region_name : '';
      this.choosePayments = payments ? payments.payments : default_payment;
      this.user_payment = this.choosePayments[0].pay_value;
      !isPro && console.log('默认的user_payment=====>', this.user_payment);
    },
    //获取支付挽留信息（1-首页，2-视频播放页）
    async getPayHoldGoods(type: 1 | 2) {
      try {
        const userStore = useUserStore();
        if (!userStore.isLogin) return;
        const res = await getRetentionGoods({ retention_type: type });
        !isPro && console.log(res, '获取支付挽留信息');
        const { user_region, default_payment, region_payment, retention_goods, jump_location } =
          res.data;
        //从当前国家列表查当前IP所在地，如果不在列表里取默认的支付方式，如果在则取国家列表里支持的支付方式
        const payments = region_payment.find(
          (item: PayType.RegionPaymentFormat) => item.region_info.region === user_region
        );
        !isPro && console.log('是否在国家列表中payments=====>', payments);
        this.jump_location = jump_location;
        this.user_region = user_region;
        this.userRegionName = payments ? payments.region_info.region_name : '';
        this.choosePayments = payments ? payments.payments : default_payment;
        this.user_payment = this.choosePayments[0].pay_value;
        !isPro && console.log('默认的user_payment=====>', this.user_payment);
        if (retention_goods[0]) this.retentionGoodsNew = retention_goods[0];
      } catch (error) {
        console.error(error);
      }
    },
    async checkPayStatus(orderId: string, entranceId?: string) {
      const res = await getCheck({ orderId, entranceId });
      this.resultStatus = res.data.status;
      return res;
    },
    //重置用户会员信息字段
    resetmemberInfo() {
      this.memberInfo = {
        is_trial: 0,
        is_vip: 0,
        is_shop: 0,
        k_less_value: 0,
        k_value: 0,
        valid: 0,
        vip_type: 0,
        g_value: 0,
        g_less_value: 0,
      };
    },
  },
});
