<template>
  <div class="country">
    <div class="top-bar">
      <img src="../../imgs/wallet/back.png" alt="" @click="goBack()" />
      <h3>{{ $t('region.title') }}</h3>
    </div>
    <div class="ul-box">
      <ul>
        <li
          v-for="(item, index) in payStore.regionPayment"
          :key="item.region_info.region"
          @click="handelChoosePayment(item, index)"
        >
          <div class="flag">
            <img :src="item.region_info.region_flag" />
          </div>
          <div class="name">{{ item.region_info.region_name }}</div>
          <div class="checkbox">
            <img
              :src="
                payIndex === index || payStore.user_region === item.region_info.region
                  ? checkBoxActiveIcon
                  : checkBoxIcon
              "
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { usePayStore } from '@/pages/index/store/modules/pay';
import checkBoxIcon from '@/pages/index/imgs/pay/checkbox.png';
import checkBoxActiveIcon from '@/pages/index/imgs/pay/checkbox-active.png';
import { goBack } from '../../router';

const payStore = usePayStore();
const payIndex = ref(-1);

const handelChoosePayment = (item: PayType.RegionPaymentFormat, index: number) => {
  console.log('<<<<handelChoosePayment', item, index);
  payStore.user_region = item.region_info.region;
  payStore.choosePayments = item.payments;
  payStore.userRegionName = item.region_info.region_name;
  payIndex.value = index;
};

onMounted(() => {
  payStore.getGoodsList();
});
</script>
<style lang="scss" scope>
.country {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f5f7;

  .top-bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    margin: 20px 0;

    h3 {
      font-weight: bold;
      font-size: 18px;
    }

    img {
      position: absolute;
      left: 15px;
      width: 25px;
    }
  }

  .ul-box {
    width: 100%;
    height: calc(100% - 60px);
    padding: 0 12px 12px;
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    ul {
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        margin-bottom: 8px;
        padding: 0 12px 0 11px;
        background-color: #fff;
        border-radius: 12px;

        .flag {
          width: 18px;
          height: 18px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .name {
          flex: 1;
          padding-left: 6px;
        }

        .checkbox {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
</style>
