<template>
  <!-- 发分弹框 -->
  <van-dialog class="servicepoint" v-model:show="show" :showConfirmButton="false">
    <div class="light"></div>
    <div class="servicepoint-content">
      <div class="servicepoint-content-main">
        <i class="main-close" @click="handleClose"></i>
        <div class="main-title">{{ $t('task.get.title') }}</div>
        <div class="main-reward">+{{ gold }}</div>
        <div class="main-btn" @click="handleClose">{{ $t('task.btn.ok') }}</div>
      </div>
    </div>
  </van-dialog>
</template>
<script lang="ts" setup>
import xl from '@/utils/log';

interface IProps {
  visible: boolean;
  gold: number;
  type: number;
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  gold: 0,
  type: 1, //1-签到再领取 2-其他任务再领取
});
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'close'): void;
  (e: 'double'): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});

watch(
  () => show.value,
  (newVal) => {
    if (newVal) {
      if (props.type == 1) {
        xl.act({ actentryid: '600005', type: 'show' });
      }
    }
  }
);

const handleClose = () => {
  show.value = false;
  emit('close');
  if (props.type == 1) {
    xl.act({ actentryid: '600005', type: 'click' });
  }
};
</script>
<style lang="scss" scope>
.servicepoint {
  &.van-dialog {
    background: transparent;
  }

  .light {
    width: 285px;
    height: 285px;
    margin: 0 auto;
    background-image: url(../../../imgs/task/light.png);
    background-size: 100% 100%;
    animation: spin 3s linear infinite;
  }

  &-content {
    position: relative;
    margin: -126px auto 0;

    &::before {
      position: absolute;
      top: -53.5px;
      right: 0;
      left: 0;
      z-index: 1;
      display: inline-block;
      width: 194.5px;
      height: 120.5px;
      margin: 0 auto;
      background-image: url(../../../imgs/task/othertask/popicon.png);
      background-size: 100%;
      content: '';
    }

    &-main {
      position: relative;
      width: 300px;
      min-height: 270px;
      margin: -185px auto 0;
      padding-top: 73px;
      padding-bottom: 25px;
      background-color: #fff;
      background-image: url(../../../imgs/task/othertask/popbg.png);
      background-size: 100% 100%;
      border-radius: 20px;

      .main-close {
        position: absolute;
        top: 11px;
        right: 11px;
        width: 20px;
        height: 20px;
        background-image: url(../../../imgs/task/close.png);
        background-size: 100%;
      }

      .main-title {
        color: #222;
        font-weight: 500;
        font-size: 19px;
        text-align: center;
      }

      .main-reward {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        color: #7c2123;
        font-weight: bold;
        font-size: 40px;
        text-align: center;

        &::after {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-left: 10px;
          background-image: url(../../../imgs/task/gcoin.png);
          background-size: 100% 100%;
          content: '';
        }
      }

      .main-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 47px;
        margin: 40px auto 0;
        padding: 0 22px;
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        word-break: normal;
        background: #ff3868;
        border-radius: 24px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
