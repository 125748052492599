<template>
  <div class="join-us" :style="{ backgroundImage: `url(${bgImage})` }">
    <div class="back">
      <img @click="goBack('/mine')" :src="pngBack" alt="" />
    </div>

    <!-- box -->
    <div class="join-us-info">
      <div class="title">{{ $t('act.title1') }}:</div>
      <div class="desc">{{ $t('act.desc1') }} <em>52%</em> {{ $t('act.desc2') }}.</div>
      <div class="footer">
        <div class="footer-title">{{ $t('act.title2') }}:<img :src="pngXin" /></div>
        <div class="footer-tel">
          {{ $t('act.tg.chat') }}：<a href="https://t.me/+m1Uq5t98Vqc5Mjk1"
            >https://t.me/+m1Uq5t98Vqc5Mjk1</a
          >
        </div>
        <div class="footer-tel">
          {{ $t('act.tg.channel') }}：<a href="https://t.me/+J1O8cEg5ggM0ZjI1"
            >https://t.me/+J1O8cEg5ggM0ZjI1</a
          >
        </div>
        <div class="footer-tel">
          {{ $t('act.tg.bot') }}：<a href="https://t.me/TOKTV_Official_Bot"
            >https://t.me/TOKTV_Official_Bot</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import pngBack from '../../imgs/wallet/back.png';
import pngXin from '../../imgs/act/xin.png';
import { goBack } from '@/pages/index/router';
import { getCurrentLang } from '@/utils';
import { locale2lang } from '@/locale/lang';

const getAssetURL = (image: string) => {
  return new URL(`../../imgs/act/${image}`, import.meta.url).href;
};

const supportedUrlLang = ['en', 'th', 'pt'];
const lang = getCurrentLang();
const serverLang = locale2lang(lang);
const applang = supportedUrlLang.includes(serverLang) ? serverLang : 'en';
console.log('applang::::', applang);
const bgImage = getAssetURL(`bg_${applang}.png`);
</script>

<style scoped lang="scss">
.back {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px;

  img {
    width: 25px;
  }
}

.join-us {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-top: 315px;
  background-image: url('../../imgs/act/bg_en.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;

  &-info {
    width: 100%;
    height: 428px;
    padding: 70px 30px 0;
    background-image: url('../../imgs/act/box.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;

    .title {
      width: 100%;
      height: 50px;
      color: transparent;
      font-weight: bold;
      font-size: 24px;
      line-height: 50px;
      text-shadow: 0 2px 5px rgba(26, 116, 252, 0.21);
      background: linear-gradient(90deg, #1c8367 0%, #a57ab4 100%);
      -webkit-background-clip: text;
      border-bottom: 1px dotted #6bb19a;
    }

    .desc {
      width: 305px;
      padding: 10px 0;
      color: #2d8a85;
      font-size: 20px;
      line-height: 30px;
      border-bottom: 1px dotted #6bb19a;

      em {
        position: relative;
        color: #f10076;
        font-weight: bold;
        font-size: 25px;

        &::after {
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 80%;
          height: 3px;
          background-image: url('../../imgs/act/line.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          content: '';
        }
      }
    }

    .footer {
      padding: 10px 0;
      color: #4a8ca0;
      font-size: 14px;
      line-height: 30px;

      &-title {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;

        img {
          width: 40px;
          height: 40px;
        }

        // &::after {
        //   position: absolute;
        //   top: -2px;
        //   left: 106px;
        //   width: 40px;
        //   height: 40px;
        //   background-image: url('../../imgs/act/xin.png');
        //   background-repeat: no-repeat;
        //   background-position: center;
        //   background-size: 100%;
        //   content: '';
        // }
      }

      a {
        color: #4a8ca0;
        line-height: 25px;
        text-decoration: underline;
      }
    }
  }
}
</style>
