<template>
  <van-share-sheet
    class="share-sheet"
    v-model:show="showShare"
    :title="$t('record.title.share')"
    :options="options"
    teleport="body"
    @select="onSelectShare"
    @closed="closeShare"
  >
    <template v-slot:cancel>
      <img src="@/pages/index/imgs/icon-close.png" alt="" />
    </template>
  </van-share-sheet>
</template>

<script lang="ts" setup>
import { useCopy } from '@/composables/useCopy';
import IconTwitter from '@/pages/index/imgs/share-twitter.png';
import IconFacebook from '@/pages/index/imgs/share-facebook.png';
import IconLink from '@/pages/index/imgs/share-link.png';

type ShareOption = (typeof options)[number];
type ShareType = ShareOption['name'];
interface ShareItem {
  drama_id: string;
  drama_num: number | string;
  title: string;
  source: string;
}
const props = defineProps<{
  show: boolean;
  shareInfo: ShareItem;
}>();

const router = useRouter();
const showShare = ref(false);
const shareItem = ref<ShareItem>();
const emit = defineEmits(['hide']);
const options = [
  {
    name: 'Facebook',
    icon: IconFacebook,
  },
  {
    name: 'Twitter',
    icon: IconTwitter,
  },
  {
    name: 'Copy Link',
    icon: IconLink,
  },
];

watch(
  () => props.show,
  (newVal, oldVal) => {
    showShare.value = newVal;
  }
);

watch(
  () => props.shareInfo,
  (newVal, oldVal) => {
    shareItem.value = newVal;
  }
);

const onSelectShare = (option: ShareOption) => {
  showShare.value = false;
  toShare(option.name);
};

const closeShare = () => {
  emit('hide');
};

const toShare = (type: ShareType) => {
  if (shareItem.value) {
    const { href } = router.resolve({
      path: '/video',
      query: {
        drama_id: shareItem.value.drama_id,
        drama_num: shareItem.value.drama_num,
        source: shareItem.value.source,
      },
    });

    const shareUrl = `${location.origin}${href}`;
    const title = encodeURIComponent(shareItem.value.title);

    if (type === 'Copy Link') {
      useCopy(shareUrl);
      return;
    }

    let link = '';
    switch (type) {
      case 'Facebook':
        link = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&title=${title}`;
        break;
      case 'Twitter':
        link = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}`;
        break;
    }

    window.open(link, '_blank');
  }
};
</script>

<style lang="scss" scope>
.share-sheet {
  &.van-popup {
    background: #fff;
  }

  .van-share-sheet__header {
    border-bottom: 1px solid #f5f5f5;
  }

  .van-share-sheet__title {
    color: #251c1b;
    font-weight: bold;
    font-size: 18px;
  }

  .van-share-sheet__options {
    padding: 30px 10px;
  }

  .van-share-sheet__cancel {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
  }

  .van-share-sheet__cancel::before {
    display: none;
  }

  .van-share-sheet__cancel img {
    width: 20px;
    height: 20px;
  }
}
</style>
