<template>
  <div class="content">
    <div class="buy__vip" v-if="Object.values(payStore.vipGoodsList).length">
      <card-vip :list="payStore.vipGoodsList" @buy="(item) => handleBuyGoods(item, 1)" />
    </div>
    <div
      :class="['k__special', { focus: payStore.kGoodsSpecial }]"
      v-if="payStore.kGoodsSpecial"
      @click="payStore.kGoodsSpecial && handleBuyGoods(payStore.kGoodsSpecial, 3)"
    >
      <div class="k__info">
        <div class="price">
          <span>{{ kgsCustom?.value }}</span>
          {{ $t('play.txt.kb') }}
        </div>
        <div class="text" v-show="kgsCustom?.extra_value && Number(kgsCustom?.extra_value) > 0">
          +{{ kgsCustom?.extra_value }} {{ $t('play.txt.kb') }}
        </div>
      </div>
      <div class="k__price">{{ kgsCustom?.unit }} {{ payStore.kGoodsSpecial.price }}</div>
      <div class="mark" v-show="kgsCustom?.markText && Number(kgsCustom?.extra_value) != 0">
        {{ kgsCustom?.markText }}
      </div>
      <div class="mark2">
        <span class="hh">{{ current.hh }}</span
        >&nbsp;:&nbsp;<span class="mm">{{ current.mm }}</span
        >&nbsp;:&nbsp;<span class="ss">{{ current.ss }}</span>
        <!-- &nbsp;:&nbsp;<span class="ms">{{ current.value.milliseconds }}</span> -->
      </div>
      <div class="finger"><ani :src="svgaFinger"></ani></div>
    </div>
    <div class="buy__k">
      <card-k2 :type="1" :list="payStore.kGoodsList" @buy="(item) => handleBuyGoods(item, 3)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import CardVip from './card-vip.vue';
import CardK2 from './card-k2.vue';
import ani from '@/components/ani-player/index.vue';
import svgaFinger from '@/pages/index/imgs/svga/finger.svga?url';
import { usePayStore } from '@/pages/index/store/modules/pay';
import { CurrentTime, useCountDown } from '@vant/use';
import { TOKTV_K_GOODS_COUNT_DOWN_TIME } from '@/pages/index/keys';

const payStore = usePayStore();
const emit = defineEmits(['buy']);
// const props = defineProps({
//   list: Array<PayType.PlyItemFormat>,
// });
const countDownTime =
  import.meta.env.VITE_APP_ENV === 'production' ? 24 * 60 * 60 * 1000 : 60 * 1000;
const time = useLocalStorage(TOKTV_K_GOODS_COUNT_DOWN_TIME, countDownTime);
const countDown = useCountDown({
  // 倒计时 24 小时
  time: time.value,
  millisecond: false,
  onChange(current: CurrentTime) {
    time.value = current.total;
  },
  onFinish() {
    // console.log('[countDown onFinish]');
    time.value = countDownTime;
    countDown.reset(time.value);
    countDown.start();
  },
});
const kgsCustom = computed(() => payStore.kGoodsSpecial?.custom);
const current = reactive({
  hh: '00',
  mm: '00',
  ss: '00',
});

const handleBuyGoods = (item: PayType.PlyItemFormat, type: 1 | 2 | 3) => {
  emit('buy', item, type);
};

watchEffect(() => {
  const { hours, minutes, seconds } = countDown.current.value;
  let hh = `${hours}`;
  let mm = `${minutes}`;
  let ss = `${seconds}`;
  if (hh.length < 2) hh = `0${hh}`;
  if (mm.length < 2) mm = `0${mm}`;
  if (ss.length < 2) ss = `0${ss}`;
  current.hh = hh;
  current.mm = mm;
  current.ss = ss;
});

onMounted(() => {
  countDown.start();
});
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  min-height: 280px;
  max-height: 430px;
  padding-top: 9px;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  .buy__vip {
    position: relative;
    width: 100%;
    height: 80px;
    margin-bottom: 15px;
  }

  .k__special {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 345px;
    height: 80px;
    margin: 40px auto 15px;
    padding: 0 25px 0 16px;
    background: #fff;
    border-radius: 0 0 11px 11px;
    box-shadow: 0 1px 7px 0 rgba(220, 222, 225, 0.8);

    &::before {
      display: inline-block;
      width: 67px;
      height: 41px;
      background: url('@/pages/index/imgs/pay/kcoin.png');
      background-size: 100% 100%;
      content: '';
    }

    &.focus {
      .finger {
        position: absolute;
        right: -30px;
        bottom: -50px;
        display: block;
        width: 110px;
      }
    }

    .finger {
      display: none;
      pointer-events: none;
    }

    .k__info {
      flex: 1;
      align-items: flex-start;
      padding-right: 10px;
      padding-left: 13px;

      .price {
        color: #7c9094;
        font-size: 14px;

        span {
          color: #222;
          font-weight: bold;
          font-size: 20px;
        }
      }

      .text {
        color: #ff7a00;
        font-size: 14px;
        word-break: normal;
      }
    }

    .k__price {
      min-width: 62px;
      height: 30px;
      padding: 0 10px;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      background: #ff3868;
      border-radius: 5.5px;
    }

    // .special {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   min-width: 45px;
    //   height: 21px;
    //   padding: 0 10px;
    //   color: #fff;
    //   font-size: 12px;
    //   line-height: 21px;
    //   text-align: center;
    //   background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
    //   border-radius: 11px 0;
    // }

    .mark {
      position: absolute;
      top: -25px;
      left: 0;
      display: flex;
      align-items: center;
      min-width: 43px;
      height: 25px;
      padding: 0 10px;
      color: #fff;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
      border-radius: 11px 11px 11px 0;
    }

    .mark2 {
      position: absolute;
      top: -25px;
      right: 0;
      display: flex;
      align-items: center;
      height: 25px;
      color: #2a2e31;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;

      span {
        width: 25px;
        padding: 5px 0;
        color: #fff;
        font-weight: normal;
        text-align: center;
        background: #2a2e31;
        border-radius: 5px;
        // &.ms {
        //   width: 35px;
        //   padding: 5px;
        // }
      }
    }
  }

  .buy__k {
    padding: 0 15px;
  }

  .buy__goods {
    padding: 0 15px;
  }
}

@keyframes move-up-down {
  0% {
    /* 初始位置 */
    transform: translate(30px, 0) scale(1);
    opacity: 1;
  }

  50% {
    transform: translate(50px, 15px) scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: translate(30px, 0) scale(1);
    opacity: 1;
  }
}
</style>
