<template>
  <van-list
    v-model:loading="loading"
    v-model:error="error"
    :error-text="$t('message.desc1')"
    :finished="finished"
    :finished-text="list.length > 0 ? `~ ${$t('video.nomore')} ~` : ''"
    :offset="100"
    :immediate-check="false"
    @load="load"
  >
    <div class="watch-list" v-if="list.length > 0">
      <div
        class="watch-list-item"
        v-for="item in list"
        :key="item.drama_id"
        @click="handleClick(item)"
      >
        <card-item1 :item="item"></card-item1>
      </div>
    </div>
    <van-empty
      v-else-if="!loading && !error"
      :image="pngEmpty"
      image-size="200"
      :description="$t('video.nolog')"
    />
  </van-list>
</template>

<script setup lang="ts" name="XWatch">
import CardItem1 from './card-item1.vue';
import { getCollectionList } from '../../api';
import { useLoadList } from '../../hooks/useLoadList';
import pngEmpty from '../../imgs/empty.png';
import { tabName, useDramaStore } from '../../store/modules/drama';
import xl from '@/utils/log';

const { currentTab } = toRefs(useDramaStore());
const router = useRouter();

const { loading, finished, error, list, load, refreshing, refreshLoad } = useLoadList(
  getCollectionList,
  {
    immediate: true,
  }
);

const handleClick = (item: CollectItem) => {
  xl.act({ actentryid: '300001', subactid: item.drama_id, type: 'click' });
  router.push({
    name: 'video',
    query: {
      drama_id: item.drama_id,
      source: item.source,
      bigsource: '6',
    },
  });
};

watch(currentTab, (v) => {
  if (v === tabName.watch) {
    refreshLoad();
  }
});
</script>

<style scoped lang="scss">
.watch-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: calc(100vh - 50px - 60px);
  padding: 15px;
  overflow: hidden auto;

  &-item {
    margin-bottom: 15px;

    &:not(:nth-child(3n + 1)) {
      margin-left: 10px;
    }
  }
}

:deep(.van-empty__description) {
  margin-top: -30px;
}
</style>
