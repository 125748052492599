/*!
 * axios封装，参照：https://juejin.cn/post/7107047280133275678
 */
import wechat from '@/utils/wechat';
import axios, {
  type AxiosInstance,
  AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import { showToast } from 'vant';
import { data2formdata } from './params';
import { paramsSecretKey } from './config';
import { useUserStore } from '../store/modules/user';
import router from '../router';
import { isPro } from '@/utils/is';

// 数据返回的接口
// 定义请求响应参数，不含data
interface IResult {
  code: any;
  message: string;
}

// 请求响应参数，包含data
export interface IResultData<T = any> extends IResult {
  result?: T;
  [k: string]: any;
}

const URL = import.meta.env.VITE_APP_API_DRAMA;
// const isDev = import.meta.env.MODE === "development";
// const devToken = "";

!isPro && console.log('URL :>> ', URL);

const config = {
  // 默认地址
  baseURL: URL as string,
  // 设置超时时间
  timeout: 10000,
  // 跨域时候允许携带凭证
  // withCredentials: true,
};

// let loginDialogFlag = false;

class RequestHttp {
  // 定义成员变量并指定类型
  service: AxiosInstance;
  public constructor(config: AxiosRequestConfig) {
    // 实例化axios
    this.service = axios.create(config);
    /**
     * 请求拦截器
     * 客户端发送请求 -> [请求拦截器] -> 服务器
     * token校验(JWT) : 接受服务器返回的token,存储到vuex/pinia/本地储存当中
     */

    this.service.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        !isPro && console.groupCollapsed(`#### [request] ${config.url} ####`);
        config.data = data2formdata(config.data);
        return config;
      },
      (error: AxiosError) => {
        // 请求报错
        Promise.reject(error);
      }
    );
    /**
     * 响应拦截器
     * 服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
     */
    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        const userStore = useUserStore();
        const res = response.data;
        // 响应数据为二进制流
        if (res instanceof ArrayBuffer) return res;
        //响应数据解密处理
        if (response.config.method === 'get') {
          return res;
        } else {
          // console.table({ paramsSecretKey });
          const decodedData = wechat.Decode(res.code, paramsSecretKey);
          let decodeRes;
          try {
            decodeRes = JSON.parse(decodedData);
            if (!isPro) {
              console.groupCollapsed(`####  [response] ${response.config.url} ####`);
              console.log(decodeRes);
              console.groupEnd();
            }
          } catch (error) {
            console.error('[err: decodeRes]');
          }
          if (decodeRes.code == -1001) {
            userStore.logout();
            if (!(response.config as unknown as MyAxiosRequestConfig).noLogin) {
              router.push('/login');
            }
            // if (!(response.config as unknown as MyAxiosRequestConfig).noLogin) {
            //   if (!loginDialogFlag) {
            //     loginDialogFlag = true;
            //     Login.open({
            //       onSuccess() {
            //         location.reload();
            //       },
            //       onClose() {
            //         loginDialogFlag = false;
            //       },
            //     });
            //   }
            // }
            return Promise.reject(decodeRes || 'Request Error');
          } else if (decodeRes.code !== 0) {
            if (!(response.config as unknown as MyAxiosRequestConfig).noToast) {
              // 响应错误
              showToast(decodeRes.message || 'Error');
            }
            return Promise.reject(decodeRes || 'Request Error');
          }
          return decodeRes;
        }
      },
      (error: AxiosError) => {
        const { response } = error;
        if (response) {
          this.handleStatus(response.status);
        }
        if (!window.navigator.onLine) {
          showToast('Please check the network');
          // 可以跳转到错误页面，也可以不做操作
          // return router.replace({  path: '/404' });
        }
      }
    );
  }
  handleStatus(status: number) {
    showToast('Request failed, please try again later');
    console.log('status :>> ', status);
  }

  // 常用方法封装
  get<T = any>(url: string, params?: object): RequestRes<T> {
    return this.service.get(url, { params });
  }
  post<T = any>(
    url: string,
    params?: object | string,
    config?: MyAxiosRequestConfig
  ): RequestRes<T> {
    return this.service.post(url, params, config as any);
  }
}

// 导出一个实例对象
export default new RequestHttp(config);
