<template>
  <div class="video" :data-did="item.drama_id" @click="handleClick">
    <div
      v-if="!isPlaying"
      class="video-poster"
      ref="coverRef"
      v-lazy:background-image="item.cover"
    ></div>
    <div class="video-mask"></div>
    <div class="video-xgplayer" :id="domId"></div>
    <div class="video-title">{{ item.title }}</div>
  </div>
</template>

<script setup lang="ts" name="g-video">
import Player, { Events } from 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls';
import 'xgplayer/dist/index.min.css';
import xl from '@/utils/log';
import { isIOS, isPro } from '@/utils/is';
import { vlog } from '@/utils/vlog';
import { usePayStore } from '@/pages/index/store/modules/pay';
import { useSwiperSlide } from 'swiper/vue';
import { MyDramaItem } from './types';

const props = defineProps<{ item: MyDramaItem }>();
const router = useRouter();
const coverRef = ref<HTMLDivElement | null>(null);
const payStore = usePayStore();

// 播放状态
const isPlaying = ref(false);
// DOM id
const domId = computed(() => `'gl_player_'${props.item.drama_id}`);
// xgPlayer对象
const xgPlayer = ref<Player | null>(null);
// 记录开始播放时间
let starttime = 0;
// 记录加载时间
let loadedtime = 0;
// 记录播放次数
let playCount = 0;
let videoLoadedDataFlag = false;
const swiperSlide = useSwiperSlide();

const onVideoLoadStart = () => {
  !isPro && console.log('[VIDEO LOADED]', props.item);
  loadedtime = Date.now();
};
const onVideoLoadedData = () => {
  !isPro && console.log('[VIDEO LOADED_DATA]', props.item);
  loadedtime = Date.now() - loadedtime;
  videoLoadedDataFlag = true;
};
const onVideoPlay = () => {
  !isPro && console.log('[VIDEO PLAY]', props.item);
  isPlaying.value = true;
  starttime = Date.now();
  xl.act({ actentryid: '200001', subactid: props.item.drama_id, type: 'click' });
  const { drama_id, title, total_episode, source, last_play_episode } = props.item;
  vlog({
    dramaid: drama_id, // 剧id
    dramaname: title, // 剧名
    episode: last_play_episode, // 第N集
    dramasource: source, // 短剧来源 取source字段
    totalepisodes: total_episode, // 剧的总集数
    playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
    duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
    viewduration: xgPlayer.value?.currentTime, // 进度条时间
    operate: '3', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
    bigsource: '4', // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
    loadingtime: playCount == 0 ? (videoLoadedDataFlag ? loadedtime : starttime - loadedtime) : '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
    starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
    // endtime: '', // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
    // unlocked: '', // 解锁集数
    // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  });
  playCount++;
};
const onVideoPause = () => {
  isPlaying.value = false;
  if (xgPlayer.value?.currentTime === xgPlayer.value?.duration) {
    // 播放完成导致的暂停（非用户行为）
    return;
  }
  !isPro &&
    console.log('[VIDEO PAUSE]', props.item, xgPlayer.value?.currentTime, xgPlayer.value?.duration);
  xl.act({ actentryid: '200001', subactid: props.item.drama_id, type: 'close' });
  const { drama_id, title, total_episode, source, last_play_episode } = props.item;
  const endtime = Date.now();
  vlog({
    dramaid: drama_id, // 剧id
    dramaname: title, // 剧名
    episode: last_play_episode, // 第N集
    dramasource: source, // 短剧来源 取source字段
    totalepisodes: total_episode, // 剧的总集数
    playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
    duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
    viewduration: xgPlayer.value?.currentTime, // 进度条时间
    operate: '5', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
    bigsource: '4', // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
    starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
    endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
    // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
    // unlocked: '', // 解锁集数
    // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  });
};
const onVideoEnded = () => {
  !isPro && console.log('[VIDEO ENDED]', props.item);
  const { drama_id, title, total_episode, source, last_play_episode } = props.item;
  const endtime = Date.now();
  vlog({
    dramaid: drama_id, // 剧id
    dramaname: title, // 剧名
    episode: last_play_episode, // 第N集
    dramasource: source, // 短剧来源 取source字段
    totalepisodes: total_episode, // 剧的总集数
    duration: xgPlayer.value?.duration || 0, // 集时长 秒（解锁时拿不到可以为空）
    viewduration: xgPlayer.value?.currentTime, // 进度条时间
    operate: '4', // 操作 1、解锁弹窗 2、解锁成功 3、播放 4、结束播放 5 暂停
    bigsource: '4', // 播放来源大类 1、首页推荐轮播。2、新片上架。3、现在热播。4、猜你喜欢。5、推荐页播放。6、追剧页播放。7、历史页播放。8、底部剧集弹窗播放。9、详细页播放。
    playtype: payStore.isVip ? '2' : '1', // 是否是限时免费播放 0、是；1、否； 2、 vip会员
    starttime: starttime.toString(), // 开始播放时间 毫秒时间戳（解锁的时候可空）
    endtime: endtime.toString(), // 结束播放时间 毫秒时间戳（只有结束播放或暂停的时候报）
    // loadingtime: '', // 该集首次播放loading耗时（毫秒ms），每一集首次播放时上报(暂停后再播放不报)
    // unlocked: '', // 解锁集数
    // smallsource: '', // 播放来源小类 （中文，列表：）推荐，热血，逆袭，……
  });
};
const onVideoError = () => {
  !isPro && console.log('[VIDEO ERROR]', props.item);
};
const onVideoSeeking = () => {
  !isPro && console.log('[VIDEO SEEKING]', props.item);
};

const initPlayer = () => {
  nextTick(() => {
    if (!coverRef.value) return;
    const item = props.item;
    const { clientWidth, clientHeight } = coverRef.value;
    !isPro && console.log(coverRef, ' <<< coverRef');
    xgPlayer.value = new Player({
      id: domId.value,
      url: item.last_play_episode_url,
      plugins: isIOS ? [] : [HlsJsPlayer],
      autoplay: true, //自动播放
      loop: true, //循环播放
      fluid: true,
      width: clientWidth,
      height: clientHeight,
      controls: false,
      mobile: {
        gestureX: false,
        gestureY: false,
        closedbClick: true,
        focusVideoClick: true,
        gradient: 'none',
      },
    });
    // 视频内容开始加载
    xgPlayer.value.on(Events.LOAD_START, onVideoLoadStart);
    // 视频起播数据加载完成
    xgPlayer.value.on(Events.LOADED_DATA, onVideoLoadedData);
    // 播放
    xgPlayer.value.on(Events.PLAY, onVideoPlay);
    // 暂停
    xgPlayer.value.on(Events.PAUSE, onVideoPause);
    // 播放结束
    xgPlayer.value.on(Events.ENDED, onVideoEnded);
    // 出错
    xgPlayer.value.on(Events.ERROR, onVideoError);
    // seek播放
    xgPlayer.value.on(Events.SEEKING, onVideoSeeking);
  });
};

const handleClick = () => {
  !isPro && console.log(props.item, ' <<<< handleClick');
  xl.act({ actentryid: '100006', subactid: props.item.drama_id, type: 'click' });
  if (swiperSlide.value.isActive) {
    router.push({
      name: 'video',
      query: {
        drama_id: props.item.drama_id,
        source: props.item.source,
        bigsource: '4',
      },
    });
  }
};

watch(
  () => swiperSlide.value.isActive,
  (v) => {
    // console.log('realIndex >>> ', props.item.drama_id, v);
    if (v) {
      if (!xgPlayer.value) {
        initPlayer();
      } else {
        xgPlayer.value?.play();
      }
    } else {
      isPlaying.value = false;
      xgPlayer.value?.pause();
    }
  },
  { immediate: true }
);

// watch(
//   () => props.item._active,
//   (v) => {
//     console.log(v, props.item.title);
//     if (v) {
//       // isPlaying.value = true;
//       if (!xgPlayer.value) {
//         initPlayer();
//       } else {
//         xgPlayer.value?.play();
//       }
//     } else {
//       isPlaying.value = false;
//       xgPlayer.value?.pause();
//       xgPlayer.value = null;
//     }
//   },
//   { immediate: true }
// );

// onMounted(() => {});
onBeforeUnmount(() => {
  xgPlayer.value?.destroy();
  xgPlayer.value = null;
});
</script>

<style lang="scss" scoped>
.video {
  position: relative;
  width: 100%;
  height: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 0;
  // padding-top: 139.2452%;

  &-poster {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 369px;
    overflow: hidden;
    background-color: #222;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
  }

  &-mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    height: 369px;
  }

  &-xgplayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 369px;
    overflow: hidden;
    background-color: #000;
    border-radius: 12px;

    video {
      background-color: #000;
    }
  }

  &-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 13px;
    text-align: center;
  }
}

:deep(.xgplayer) {
  overflow: hidden;
  border-radius: 12px;

  xg-start-inner {
    overflow: inherit;
    background: transparent;
  }

  video {
    object-fit: cover;
  }

  .xgplayer-poster {
    background-size: contain;
    opacity: 0.7;
  }

  .xgplayer-replay {
    opacity: 0;
  }
}

:deep(.play-icon) {
  width: 53px;
  height: 61px;

  img {
    width: 100%;
    height: 100%;
  }
}

:deep(.xgplayer-controls) {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 4px;
  visibility: inherit !important;
  opacity: 1 !important;

  .xg-left-grid,
  .xgplayer-time,
  .xg-right-grid {
    display: none !important;
  }

  .xg-pos {
    right: 0;
    left: 0;
  }

  .xg-center-grid,
  .flex-controls {
    padding: 0 !important;
  }

  .xgplayer.xgplayer-mobile .flex-controls .xg-center-grid {
    padding: 0 !important;
  }

  .xg-inner-controls {
    bottom: 0;
    height: 100%;
  }

  .xgplayer-progress {
    height: 4px;

    .xgplayer-progress-outer {
      height: 100%;
    }

    .xgplayer-progress-cache {
      background: rgba(255, 255, 255, 0.3);
    }

    .xgplayer-progress-played {
      background: rgba(255, 255, 255, 1);
    }

    .xgplayer-progress-btn {
      left: 4px;
      width: 7px;
      height: 7px;
      background: #fff;
      border: none;
      border-radius: 50%;
      box-shadow: -1px 0 1.5px 0 rgba(0, 0, 0, 0.1);

      &::before {
        width: 7px;
        height: 7px;
      }
    }
  }
}
</style>
