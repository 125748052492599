<template>
  <div class="content">
    <span class="right first">本版本發佈日期：2024年06月11日</span>
    <span class="right">本版本生效日期：2024年06月11日</span>
    <div class="panel">
      <p>
        歡迎您與我們籤訂本《用戶服務協議》（以下簡稱“本協議”）並使用我們的平臺服務。爲保障您自身的權益，建議您仔細閱讀各條款的具體表述，您在申請注冊過程中點擊同意本協議前，應仔細閱讀本協議。
      </p>
      <p>
        請務必仔細閱讀並充分理解各條款的內容，尤其是免除或限制責任的條款、適用的法律和爭議解決條款。如果您對協議有任何疑問，可以諮詢我們的平臺客服。
      </p>
      <p>
        當您按照登錄頁面提示填寫信息，閱讀並同意本協議，並完成所有注冊程序時，即表示您已充分閱讀、理解並接受本協議全部內容，並與我們達成一致，成爲Toktv的用戶。在閱讀本協議的過程中，如果您不同意本協議或其任何條款，您應該停止注冊、使用。
      </p>
      <p>如果您不申請注冊流程，您仍然可以瀏覽平臺內容。</p>
      <p>一、定義</p>
      <p>
        1．我們：是提供互聯網視頻內容服務的平臺運營商，向用戶推出的短視頻客戶端（“Toktv”APP），即香港星能永勝科技有限公司）。我們爲您提供基於互聯網的各項服務，包括所有規則、用戶服務協議、解釋、在我們所有平臺網站上已經發布和隨後發布的公告和其他內容，以及各平臺在頻道、活動頁面、幫助中心、公告等上發布的各種規則和實施。
      </p>
      <p>
        2．如果使用相同的身份認證信息或多個用戶賬戶的實際控制人被我方平臺確定爲同一人，均視爲同一用戶。
      </p>
      <p>二、協議範圍</p>
      <p>1．合同標的：本協議由您與我們共同訂立，本協議在您與我們的平臺運營商之間具有合同效力。</p>
      <p>
        2．我們的平臺運營商是指運營我們平臺的法人實體，您可以根據上述定義（見定義中的1.“我們”）確定我們與您履行合同的實體。根據本協議，我們平臺的運營商可能會根據我們平臺的業務調整進行變更。變更後，我們平臺的運營商將與您一起履行本協議並爲您提供服務。我們平臺運營商的變更不會影響您在本協議項下的權利。由於我們提供新的平臺服務，我們的平臺運營商也可能被添加。如果您使用新添加的我們的平臺服務，則視爲您同意新添加的我們的平臺運營商與您履行本協議。發生爭議時，您可以根據您使用的具體服務和影響您權益的具體行爲對象，確定與您及爭議對方的合同標的。
      </p>
      <p>
        3．由於互聯網行業的快速發展，您與我們籤訂的本協議中所載條款並不能完整列出和涵蓋您與我們之間的所有權利和義務，現有協議也不能保證完全滿足您與我們之間的需要，以及未來的發展。因此，我們平臺的《隱私政策》和我們平臺的規則是對本協議的補充協議，與本協議密不可分，具有同等法律效力。如果您使用我們的平臺服務，則視爲您同意上述補充協議。
      </p>
      <p>
        4．用戶服務協議變更，我們的平臺可以更新我們平臺上的用戶服務協議、隱私政策及相關平臺規則（統稱“用戶協議”）。屆時，將通過系統提示、信息推送和/或您在我們平臺留下的聯系方式等方式通知協議的更新。並提醒您注意：用戶協議變更後，如果您繼續使用我們的平臺服務，即表示您同意接受我們更新後的用戶協議。如果您不同意變更後的用戶協議，請停止使用我們的平臺產品和服務。
      </p>
      <p>三、賬號注冊與使用</p>
      <p>1．用戶資格：</p>
      <p>（1）您確認在您開始使用/注冊使用我們的平臺服務之前，您應具備適合您行爲的民事行爲能力。</p>
      <p>
        （2）如果您不具備與您的行爲相適應的民事行爲能力，您應當徵得您的監護人的知情同意，您和您的監護人應當按照本協議的規定承擔相應的責任。特別是，如果您是未成年人，請在您的監護人同意和指導下訪問和/或使用我們的平臺服務。
      </p>
      <p>2．注冊信息管理</p>
      <p>
        （1）真實合法，您在使用我們的平臺服務時，應根據我們平臺頁面的提示準確、完整地提供您的信息，以便我們在必要時與您取得聯系。您理解並同意，您有義務維護您所提供信息的真實性和有效性。
      </p>
      <p>
        （2）您設置的賬號名稱、暱稱和頭像不得違反法律法規和我們平臺關於賬號名稱管理的規則，否則我們可能會对您的賬號名稱暫停使用或注銷等處理，並向主管部門報告。
      </p>
      <p>
        （3）您理解並承諾，您的賬號、暱稱、頭像、個人資料等注冊信息中不包含非法或不良信息，不存在冒用、附屬組織或社會名流，遵守法律法規，以及賬號注冊過程中的制度、國家利益、公民合法權益、公共秩序、社會公德和信息真實性。
      </p>
      <p>3．更新維護</p>
      <p>
        （1）您應及時更新您提供的信息。在法律明確要求我們對部分用戶信息進行核實的情況下，我們將依法不定期對您的信息進行核查和核實。您應配合提供最新、真實、完整的信息。
      </p>
      <p>
        （2）如果我們未能根據您上次提供的信息與您取得聯系，您未按照我們的要求及時提供信息，或者您提供的信息明顯不實，給您造成的損失由您承擔。本平臺仅在法律明確規定的情形下承擔相應責任。
      </p>
      <p>4．賬戶安全規範</p>
      <p>
        （1）您的帳戶是爲您設置並由您保管的。我們的平臺在任何時候都不會主動要求您提供賬戶密碼。建議您保護您的帳戶安全，並確保您在每個在線期間結束時退出，並按照正確的步驟離開我們的平臺。
      </p>
      <p>
        （2）因您主動披露或被他人攻擊或欺詐而造成的賬戶損失及後果，由您自行承擔風險和責任。本平臺仅在法律明確規定的情形下承擔相應責任。
      </p>
      <p>
        （3）您的帳戶僅供您自己使用，不得出租或共享給他人使用。當您的賬戶被未經授權使用時，您應立即通知我們的平臺，否則未經授權的使用將被視爲您自己的行爲，由此造成的一切損失和後果由您自行承擔。
      </p>
      <p>
        （4）除我們的過錯外，您應對您賬戶下的所有行爲結果負責（包括但不限於在線籤署各類協議、發布信息、購買商品和服務、披露信息等）。
      </p>
      <p>
        （5）如果您發現任何未經授權使用您的賬戶登錄我們的平臺或其他可能導致您的賬戶被盜或丟失的情況，建議您立即通知我們。您了解我們需要合理的時間對您的任何請求採取行動，並且我們不對採取行動之前發生的後果負責，但我們的法定過錯除外。
      </p>
      <p>
        （6）如您遇到非正常扣款或其他非您本人意願導致扣款的情形，您可主動向我們提出退款申請，若退款理由系合理且真實的，我司會對您的支付進行退款。
      </p>
      <p>四、禁止內容</p>
      <p>
        1．您理解並保證您在我們平臺上傳、發布或傳播的內容（包括您的賬號名等信息）應遵守憲法、法律、行政法規，堅持正確的輿論導向，發揮輿論監督，促進形成積極、健康、積極的網絡文化，維護社會公共利益。我們平臺有權管理您上傳、發布或傳輸的信息。如有法律、行政法規禁止發布、傳播的信息，或含有以下內容的，我們平臺將立即停止傳播該信息，並採取消除措施等措施，防止信息傳播，保存相關記錄，向有關當局報告：
      </p>
      <p>（1）反對憲法確定的基本原則的；</p>
      <p>（2）危害國家統一、主權和領土完整的；</p>
      <p>（3）泄露國家祕密，危害國家安全或者損害國家榮譽和利益的；</p>
      <p>（4）煽動民族仇恨、民族歧視，破壞民族團結，或者侵害民族風俗習慣的；</p>
      <p>（5）宣揚邪教、迷信的；</p>
      <p>（6）擾亂社會秩序，破壞社會穩定的；</p>
      <p>（7）誘導未成年人犯罪犯罪和誇大暴力、色情、賭博和恐怖活動；</p>
      <p>（8）侮辱或者誹謗他人，侵害公民個人名譽、隱私、知識產權等合法權益的；</p>
      <p>（9）危害社會公德，破壞民族優良文化傳統的；</p>
      <p>（10）有關法律、行政法規和國家規定禁止的其他內容。</p>
      <p>
        2．如果您上傳、發布或傳輸的內容中包含上述信息或內容違反法律法規，或侵犯任何第三方的合法權益，您將直接承擔由此造成的一切不良後果。本平臺僅在法律明確規定的情形下承擔相應責任。如果給我們造成不利後果，您應負責消除影響並賠償我們由此造成的一切損失，包括但不限於財產損失、名譽損失、律師費、交通費等費用或一切其他合理費用。
      </p>
      <p>3．禁止行爲</p>
      <p>
        您理解並保證您不會做出與我們平臺服務相關的以下禁止行爲，也不會允許任何人使用您的賬戶進行以下行爲：
      </p>
      <p>
        （1）在注冊賬戶或使用我們的平臺服務時，冒充他人，或謊稱與任何人或實體有關聯（包括設置虛假賬戶名或訪問其他用戶的賬戶）；
      </p>
      <p>（2）僞造標題或以其他方式操縱內容以誤導他人相信該內容是由我們傳播的；</p>
      <p>（3）上傳、發布、發送電子郵件或以其他方式傳輸您無權傳輸的內容（如內部數據、機密數據）；</p>
      <p>（4）發送任何未經請求或未經授權的垃圾郵件或促銷材料，或任何其他商業通訊；</p>
      <p>（5）未經我們明確許可，將我們的平臺服務用於任何商業目的或爲任何第三方謀取利益；</p>
      <p>（6）跟蹤或以其他方式騷擾他人；</p>
      <p>
        （7）參與任何非法或潛在非法（我們有權確定用戶行爲和適用規則，並據此處理）的活動或交易，包括傳授犯罪方法、出售任何非法藥物、洗錢、欺詐等；
      </p>
      <p>（8）賭博、提供賭博信息或以任何方式誘導他人參與賭博活動；</p>
      <p>
        （9）使用或利用我們的知識產權（包括我們的商標、品牌、徽標、任何其他專有數據或任何網頁的布局或設計），或以其他方式侵犯我們的任何知識產權（包括試圖利用我們的平臺客戶端或用於逆向工程的軟件）；
      </p>
      <p>
        （10）
        使用任何自動化程序、軟件、引擎、網絡爬蟲、網絡分析工具、數據挖掘工具或類似工具訪問我們的平臺服務，收集或處理通過我們的平臺服務提供的內容；
      </p>
      <p>（11）參與任何旨在模仿我們平臺服務的外觀和功能的“框架”、“鏡像”或其他技術；</p>
      <p>（12）幹擾或試圖幹擾任何用戶或任何其他方訪問我們的平臺服務；</p>
      <p>（13）故意傳播病毒、網絡蠕蟲、木馬、損壞文件或其他惡意代碼或項目；</p>
      <p>（14）未經他人明確同意，共享或發布他人的個人身份信息；</p>
      <p>
        （15）探索或測試我們的平臺服務、系統或其他用戶系統是否容易受到入侵攻擊，或以其他方式規避（或試圖規避）我們平臺服務、系統或其他用戶系統的任何安全功能；
      </p>
      <p>（16）對我們平臺服務中使用的軟件進行解碼、反編譯或逆向工程，或嘗試進行上述行爲；</p>
      <p>（17）以破壞或濫用爲目的開立多個賬戶，或惡意上傳重復、無效的大容量數據和信息；</p>
      <p>（18）利用互聯網從事侵害他人名譽、隱私、知識產權等合法權益的活動；</p>
      <p>（19）故意或無意違反中國任何相關法律、法規、規章、條例和其他具有法律約束力的規範。</p>
      <p>（20）修改或篡改我們的平臺服務及相關功能。</p>
      <p>
        （21）破壞我們平臺的版權技術保護措施，擅自盜用我們平臺的內容，如復制、轉發、付費用戶才能獲取、觀看的內容等。
      </p>
      <p>五、隱私政策</p>
      <p>
        1．當您使用我們的服務時，您同意我們按照我們平臺上發布的隱私政策收集、存儲、使用、披露和保護您的個人信息。
      </p>
      <p>
        2．我們非常重視用戶個人信息的保護。根據相關法律的要求和規定，網絡產品和服務具有收集用戶信息功能的，其提供者應當明示並徵得用戶同意。我們特此通過單獨明示的《<a
          @click="$router.push('/privacy-policy')"
          >隱私政策</a
        >》明確告知您收集和使用用戶個人信息的目的、方式和範圍、查詢和更正信息的渠道以及拒絕提供信息的後果。我們希望通過我們的隱私政策向您明確介紹我們如何處理您的個人信息，因此您在使用我們提供的服務前應完整閱讀《<a
          @click="$router.push('/privacy-policy')"
          >隱私政策</a
        >》，以幫助您更好地保護您的隱私權。
      </p>
      <p>
        3．如果您不同意《<a @click="$router.push('/privacy-policy')">隱私政策</a
        >》的任何內容，您應立即停止使用我們的平臺服務。當您使用我們平臺提供的任何服務時，您同意我們將按照我們平臺的《隱私政策》合法收集、使用和保護您的個人信息。
      </p>
      <p>
        4．本《隱私政策》所稱個人信息，是指以電子或其他方式記錄的能夠單獨或與其他信息結合識別自然人個人身份的各類信息。
      </p>
      <p>
        5．當《隱私政策》所列事項發生變更時，我們將及時更新《隱私政策》，並通過系統提示、信息推送和/或您在我們平臺留下的聯系方式通知您。
      </p>
      <p>
        6．如果您對隱私政策有任何疑問或對您的個人信息保護有任何投訴，您可以通過【toktvservice@outlook.com】郵箱與我們取得聯系。
      </p>
      <p>六、知識產權</p>
      <p>
        1．除非我們另有約定或另有說明，否則我們平臺上的所有內容（用戶依法享有版權的內容除外）、技術、軟件、程序、數據和其他信息（包括但不限於文字、圖像、圖片、照片、所有知識產權（包括但不限於版權、商標、專利、商業祕密等）和音頻、視頻、圖形、顏色、布局、電子文檔的相關權利）均歸我們或我們的關聯公司所有。未經我們許可，任何人不得擅自使用（包括但不限於復制、傳播、展示、鏡像、上傳、下載、修改、出租）。
      </p>
      <p>
        2．我們平臺及平臺內容的標志等文字和圖形及其組合，以及我們平臺上的其他標志、標志、產品和服務名稱，是我們或我們的關聯公司在中國或其他國家的商標，任何人不得未經我們書面授權以任何方式展示它們。並且您不得向他人表明您有權展示、使用或以其他方式處理。
      </p>
      <p>
        3．我們對我們的專有內容、原創內容和其他通過許可獲得的獨家或獨家內容擁有全部知識產權。未經我們許可，任何單位和個人不得擅自復制、傳播、提供觀看服務或有其他侵犯我們知識產權的行爲，否則將承擔一切相關法律責任。
      </p>
      <p>七、用戶違約及處理</p>
      <p>1．違約的認定，出現下列情形之一的，視爲您違約：</p>
      <p>（1）使用我們平臺服務時違反相關法律法規的；</p>
      <p>（2）違反本協議或本協議補充協議</p>
      <p>
        （3）爲適應互聯網行業的發展，滿足廣大用戶對高效優質服務的需求，您理解並同意我們可以在本協議中規定違約認定的程序和標準及平臺規則。例如：我們可以根據您的用戶數據與海量用戶數據的關系判斷您是否構成違約；您有權對您的數據異常提供充分的證據和合理的解釋，否則將被視爲違約。
      </p>
      <p>2．違約處理措施</p>
      <p>
        （1）如果您在我們平臺發布的內容和信息構成違約，我們平臺有權按照相應規則立即刪除或屏蔽相應的內容和信息，或暫停、查封、暫停您的賬號登录等。在法律明確規定的情形下，本平臺承擔相應責任。
      </p>
      <p>
        （2）如果您在我們平臺上的行爲，或您在我們平臺上未實施但對我們平臺及其用戶產生影響的行爲構成違約，我們可能會按照相應規則執行您的賬戶，如限制或暫停向您提供部分或全部服務等。如果您的行爲構成根本違約，我們可能會查封您的賬戶並終止向您提供服務。
      </p>
      <p>
        （3）如果您在我們平臺上的行爲違反相關法律法規，我們可能會依法向有關部門報告和提交您的使用記錄等信息。
      </p>
      <p>八、賠償責任</p>
      <p>
        1．如果您的行爲導致我們和/或其關聯公司遭受損失（包括他們自身的直接經濟損失、商譽損失，以及賠償、和解、律師費、訴訟費等間接經濟損失），上述所有損失將由您向我們和/或其關聯公司承擔賠償責任。
      </p>
      <p>
        2．如果您的行爲導致我們和/或其關聯公司被第三方索賠，我們和/或其關聯公司在對第三方承擔金錢支付和其他義務後，可以向您追償所有損失。
      </p>
      <p>九、特別協議</p>
      <p>
        1．我們負責“按原樣”和“可用”向您提供我們的平臺服務。我們依法承擔相應義務，但對因信息網絡設備維護、連接故障、計算機、通訊或其他系統故障、黑客活動、計算機病毒、停電、罷工、騷亂等造成的損失不承擔任何責任。因火災、洪水、風暴、爆炸、戰爭、政府行爲、司法行政機關的命令或第三方原因給您造成的損害，我們不承擔法律明確規定以外的任何責任。
      </p>
      <p>十、通知</p>
      <p>1．有效聯系人</p>
      <p>您同意我們將通過以下合理方式向您發出各種通知：</p>
      <p>（1）公告正文；</p>
      <p>（2）站內消息、彈窗消息、客戶端推送消息；</p>
      <p>
        2．當您注冊成爲我們平臺的用戶並接受我們的平臺服務時，您應向我們提供真實有效的聯系方式（您的聯系電話）。如果聯系方式發生變化，您有義務及時更新相關信息。並保持可訪問性。
      </p>
      <p>
        3．我們會針對您的上述一種或多種聯系方式向您發出各種通知，此類通知的內容可能會對您的權利和義務產生重大的有利或不利影響，請您及時關注。
      </p>
      <p>4．通知的傳遞</p>
      <p>
        （1）我們將通過上述聯系方式向您發送通知，包括但不限於在我們的平臺上發布公告，電子郵件、系統消息，並在發送送達時視爲已成功送達。
      </p>
      <p>
        （2）您應確保所提供的聯系信息準確、有效並實時更新。如提供的聯系方式不準確，或變更後的聯系方式未及時通知，導致法律文書無法送達或未及時送達，由此可能產生的法律後果由您自行承擔。
      </p>
      <p>十一、協議終止</p>
      <p>1．您有權以下列任何方式終止本協議</p>
      <p>（1）您在符合我們平臺公布的賬戶注銷等條件時，通過平臺注銷賬戶；</p>
      <p>（2）變更生效前，您停止使用並明確不接受變更；</p>
      <p>（3）您明示不想繼續使用我們的平臺服務並滿足我們平臺的終止條件。</p>
      <p>2．在下列情況下，我們可以按照本協議所列方式通知您終止本協議：</p>
      <p>（1）您違反本協議，我們按照違反條款終止本協議；</p>
      <p>
        （2）如您轉讓自己的賬號、盜用他人賬號、發布違禁內容和信息、詐騙他人財物或利用不正當手段，我們將按照平臺規則查封您的賬號；
      </p>
      <p>
        （3）除上述情形外，因您多次違反我們平臺規則相關規定且情節嚴重的，我們將按照我們平臺規則查封您的賬戶；
      </p>
      <p>（4）您的賬戶已被我們按照本協議等規定注銷；</p>
      <p>（5）您在我們平臺上侵犯他人合法權益或有其他嚴重違法行爲；</p>
      <p>（6）其他法律法規規定我們應當終止服務的情形。</p>
      <p>3．協議終止後的處理</p>
      <p>
        （1）本協議終止後，除法律明確規定外，我們原則上沒有義務向您或您指定的第三方披露您賬戶中的任何信息。
      </p>
      <p>4．本協議終止後，我們享有以下權利：</p>
      <p>
        （1）我們可能會刪除或匿名化您的個人信息，也可以按照法律規定的期限和方式繼續保存您在我們平臺上保留的其他內容和信息。
      </p>
      <p>（2）對於您過去的違約行爲，我們仍可能按照本協議的規定追究您的違約責任。</p>
      <p>十二、管轄權及其他</p>
      <p>
        1．您因使用我們的平臺服務而產生的與我們平臺服務相關的爭議，由我們與您協商解決。協商不成的，任何一方均可向被告所在地人民法院提起訴訟。
      </p>
      <p>
        2．如果本協議的任何條款被視爲無效、無效或不可執行，則該條款應被視爲可分割且不影響本協議其餘條款的有效性和可執行性。
      </p>
    </div>
  </div>
</template>
