<template>
  <div class="history-list" v-if="list.length > 0">
    <div
      class="history-list-item"
      v-for="item in list"
      :key="item.drama_id"
      @click="handleClick(item)"
    >
      <card-item2 :item="item"></card-item2>
    </div>
  </div>
  <van-empty
    v-else-if="!loading"
    :image="pngEmpty"
    image-size="200"
    :description="t('record.empty')"
  />
</template>

<script setup lang="ts" name="XHistory">
import CardItem2 from './card-item2.vue';
import pngEmpty from '../../imgs/empty.png';
import { getRecorded } from '../../api';
import { tabName, useDramaStore } from '../../store/modules/drama';
import xl from '@/utils/log';
const router = useRouter();
const { currentTab } = toRefs(useDramaStore());

const { t } = useI18n();

const list = ref<Array<RecordItem>>([]);
const loading = ref(false);
const loadData = async () => {
  console.log('loadData');
  try {
    loading.value = true;
    const res = await getRecorded({});
    console.log({ res });
    list.value = res.data;
  } catch (error) {
    loading.value = false;
  }
};

const handleClick = (item: RecordItem) => {
  xl.act({ actentryid: '300002', subactid: item.drama_id, type: 'click' });
  router.push({
    name: 'video',
    query: {
      drama_id: item.drama_id,
      source: item.source,
      bigsource: '7',
    },
  });
};

watch(
  currentTab,
  (v) => {
    console.log({ v });
    if (v === tabName.history) {
      loadData();
    }
  },
  { immediate: true }
);

onMounted(() => {
  xl.act({ actentryid: '300002', type: 'show' });
});
</script>

<style scoped lang="scss">
.history-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 50px - 60px);
  padding: 15px;
  overflow: hidden auto;

  &-item {
    &:not(:first-of-type) {
      margin-top: 15px;
    }
  }
}

:deep(.van-empty__description) {
  margin-top: -30px;
}
</style>
