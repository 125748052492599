<template>
  <van-popup class="x-popup-ad" v-model:show="show" :close-on-click-overlay="false">
    <div class="content" v-if="loadAd">
      <div class="content-ad">
        <ins
          class="adsbygoogle content-ad-ins"
          :data-ad-client="adClient"
          :data-ad-slot="adId"
        ></ins>
        <!-- data-ad-format="rectangle, horizontal" -->
        <!-- data-ad-format="auto"
          data-full-width-responsive="true" -->
        <div class="content-ad-loading"><span class="loader"></span></div>
      </div>
      <!-- <div class="content-mask" data-ad-click></div> -->
      <!-- <div v-if="showCloseMask" class="content-close" @click.stop="handleClose"></div> -->
    </div>
    <div class="x-popup-ad-close" v-show="showClose" @click.stop="handleClose">
      <img :src="pngClose" alt="close" />
    </div>
  </van-popup>
</template>

<script setup lang="ts" name="x-ad">
import { loadAdsByGoogle } from '@/utils/ads';
import pngClose from './imgs/close.png';
import xl from '@/utils/log';
import { generateUniqueId } from '@/utils';

interface IProps {
  visible: boolean;
  // title?: string;
  // content?: string;
  // showCloseMask?: boolean;
  pagetype?: string; // 日志参数（一级广告位，产品提供）
  gametype?: string; // 日志参数（二级广告位，产品提供）
  // 展示广告 display  信息流广告 flow  文章内嵌广告 insert  多重广告 grid
  adSlot?: string; // 日志参数（广告位类型，产品提供）
  adClient?: string; // 广告账号ID
  adId?: string; // 广告单元ID
}
const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  // showCloseMask: true,
  pagetype: '',
  gametype: '',
  adSlot: 'display',
  adClient: 'ca-pub-2241140876477932',
  adId: '6281117440',
});

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'close'): void;
  // (e: 'ad-click'): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});

const loadAd = ref(false);
const showClose = ref(false);
// 本次广告批次号
let adbatchid: string;

watch(
  show,
  (s) => {
    const { pagetype, gametype, adSlot, adId } = props;
    if (s) {
      // 展现广告时生成批次号
      adbatchid = generateUniqueId();
      // 广告加载
      xl.ad({
        type: 'show',
        adunitid: adId,
        adslot: adSlot,
        pagetype,
        gametype,
        adbatchid,
        adfrom: 'google',
      });
      loadAd.value = true;
      loadAdsByGoogle();
      setTimeout(() => {
        showClose.value = true;
      }, 3000);
    } else {
      // 广告关闭
      if (adbatchid) {
        // 有批次号才上报close（避免未展现的广告就上报close日志）
        xl.ad({
          type: 'close',
          adunitid: adId,
          adslot: adSlot,
          pagetype,
          gametype,
          adbatchid,
          adfrom: 'google',
        });
        // 关闭广告时，清空批次号
        adbatchid = '';
      }
      loadAd.value = false;
      showClose.value = false;
    }
  },
  {
    immediate: true,
  }
);

const handleClose = () => {
  // 点击了关闭
  show.value = false;
  emit('close');
};
</script>

<style scoped lang="scss">
.x-popup-ad {
  width: 300px;
  overflow-y: inherit;
  background-color: transparent;

  .content {
    position: relative;
    width: 300px;
    height: 0;
    // padding-top: 83.3333%;
    padding-top: 177.7777%;

    &-ad {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;

      &-ins {
        position: relative;
        z-index: 9;
        display: block;
        width: 100%;
        height: 100%;
      }

      &-loading {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .loader {
          position: relative;
          width: 80px;
          height: 12px;
        }

        .loader::before,
        .loader::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 12px;
          height: 12px;
          background: #e0e0e0;
          border-radius: 50%;
          box-shadow: 32px 0 #e0e0e0;
          animation: ball-move-x 2s linear infinite;
          content: '';
        }

        .loader::after {
          z-index: 2;
          box-shadow: none;
          transform: translateX(64px) scale(1);
          animation: none;
          animation: trf-loader 2s linear infinite;
        }
      }
    }

    // &-mask {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: 2;
    //   width: 100%;
    //   height: 100%;
    //   pointer-events: none;
    //   background-color: rgba($color: #ffff00, $alpha: 0.5);
    // }
    &-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 30px;
    }
  }

  &-close {
    position: fixed;
    bottom: -40px;
    left: 50%;
    width: 25px;
    height: 25px;
    margin-left: -10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes trf-loader {
  0%,
  5% {
    background: #e0e0e0;
    transform: translateX(64px) scale(1);
  }

  10% {
    background: #ff376a;
    transform: translateX(64px) scale(1);
  }

  40% {
    background: #ff376a;
    transform: translateX(32px) scale(1.5);
  }

  90%,
  95% {
    background: #ff376a;
    transform: translateX(0) scale(1);
  }

  100% {
    background: #e0e0e0;
    transform: translateX(0) scale(1);
  }
}

@keyframes ball-move-x {
  0%,
  10% {
    transform: translateX(0);
  }

  90%,
  100% {
    transform: translateX(32px);
  }
}
</style>
