<template>
  <div class="container setting">
    <div class="top__bar">
      <img @click="handleBack" src="../../imgs/wallet/back.png" alt="" />
      <h3>{{ $t('setting.title') }}</h3>
    </div>
    <div class="ul__box">
      <ul>
        <li v-for="item in list" :key="item.id">
          <div class="lll">
            <img :src="item.pic" class="pic" />
            <span>{{ $t(item.name) }}</span>
          </div>
          <div class="rrr" v-if="item.account">
            <span>{{ item.account }}</span>
          </div>
          <div class="rrr" @click="handleBind(item.id)" v-else>
            <span>{{ $t('login.bind.to') }}</span>
            <img src="../../imgs/wallet/icon3.png" />
          </div>
        </li>
      </ul>
    </div>
    <div class="logout" @click="handleLogout" v-show="isLogin">
      {{ $t('home.nav.logout') }}
    </div>
  </div>
  <x-popup
    v-model:visible="visibleBindPopup"
    :title="$t('login.bind.popup.title')"
    :content="$t('login.bind.st2')"
    @close="() => xl.act({ actentryid: '130007', type: 'close' })"
  ></x-popup>
</template>
<script setup lang="ts">
import XPopup from '@/components/x-popup/index.vue';
import { usePayStore } from '@/pages/index/store/modules/pay';
import xl from '@/utils/log';
import { useUserStore } from '../../store/modules/user';
import { goBack } from '../../router';
import { showToast } from 'vant/es';
import { googleLogin } from '../login/google';
import { facebookLogin } from '../login/facebook';
import { logoutClearStorage } from '@/utils/auth';
const getAssetURL = (image: string) => {
  return new URL(`../../imgs/bind/${image}`, import.meta.url).href;
};
const router = useRouter();
const payStore = usePayStore();
const userStore = useUserStore();
const loginGoogleLoading = ref(false);
const loginFacebookLoading = ref(false);
const visibleBindPopup = ref(false);
const list = computed<{ id: 1 | 2 | 3; pic: string; name: string; account?: string | number }[]>(
  () => {
    const googleAccount = userStore.state.loginInfo.info?.find((item) => item.usertype == '24');
    const facebookAccount = userStore.state.loginInfo.info?.find((item) => item.usertype == '23');
    return [
      {
        id: 1,
        pic: getAssetURL('i-logo.png'),
        name: 'UID',
        account: userStore.state.loginInfo.id,
      },
      {
        id: 2,
        pic: getAssetURL('i-google.png'),
        name: 'Google',
        account: googleAccount?.nickname,
      },
      {
        id: 3,
        pic: getAssetURL('i-facebook.png'),
        name: 'Facebook',
        account: facebookAccount?.nickname,
      },
    ];
  }
);
const isLogin = computed(() => !!userStore.tokenGetter);

const handleBind = (type: 1 | 2 | 3) => {
  if (type == 2) {
    // google绑定逻辑
    handleGoogleBind();
  } else if (type == 3) {
    // facebook绑定逻辑
    handleFacebookBind();
  }
};

// Google登录绑定
const handleGoogleBind = async () => {
  xl.act({ actentryid: '400009', subactid: '2', type: 'click' });
  loginGoogleLoading.value = true;
  try {
    // google授权
    const googleResult = await googleLogin();
    // 三方登录
    const res = await userStore.bind({
      unionid: googleResult.user.providerData[0].uid,
      openid: googleResult.user.providerData[0].email || '',
      figureurl: googleResult.user.photoURL || '',
      nickname: googleResult.user.displayName || '',
      usertype: '24',
    });
    console.log('<google login>', res);
    // handleLoginSucEvent();
  } catch (error: any) {
    console.error(error);
    if (error.code == '10123') {
      visibleBindPopup.value = true;
      xl.act({ actentryid: '130007', type: 'show' });
    } else if (error.message) {
      showToast(error.message);
    }
  }
  loginGoogleLoading.value = false;
};

// Facebook登录绑定
const handleFacebookBind = async () => {
  xl.act({ actentryid: '400009', subactid: '3', type: 'click' });
  loginFacebookLoading.value = true;
  try {
    // FB授权
    const fbUserInfo: FBUserInfo = await facebookLogin();
    // 三方绑定
    const res = await userStore.bind({
      unionid: fbUserInfo.id,
      openid: fbUserInfo.id,
      figureurl: '',
      nickname: fbUserInfo.name,
      usertype: '23',
    });
    console.log('<facebook bind>', res);
    // handleLoginSucEvent();
  } catch (error: any) {
    console.error(error);
    if (error.code == '10123') {
      visibleBindPopup.value = true;
    } else if (error.message) {
      showToast(error.message);
    }
  }
  loginFacebookLoading.value = false;
};

const handleLogout = () => {
  payStore.resetmemberInfo();
  xl.act({ actentryid: '400009', type: 'close' });
  userStore.logout();
  goBack('/mine');
  logoutClearStorage();
};

const handleBack = () => {
  xl.act({ actentryid: '400009', type: 'close' });
  goBack('/mine');
};

onMounted(() => {
  xl.act({ actentryid: '400009', type: 'show' });
});
</script>
<style scoped lang="scss">
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &.setting {
    .top__bar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      margin: 20px 0;

      h3 {
        font-weight: bold;
        font-size: 18px;
      }

      img {
        position: absolute;
        left: 15px;
        width: 25px;
      }
    }

    .ul__box {
      width: 100%;
      margin-top: 10px;
      padding-top: 1px;
      background: #fff;
      border-radius: 15px 15px 0 0;
    }

    ul {
      margin: 0 15px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 49px;
        border-bottom: 2px solid #f5f5f5;

        &:last-child {
          border-bottom: none;
        }

        .lll {
          display: flex;
          align-items: center;

          img {
            width: 23px;
            margin-right: 9px;
          }

          span {
            color: #222;
            font-weight: 500;
            font-size: 16px;
          }
        }

        .rrr {
          display: flex;
          align-items: center;

          span {
            margin-right: 4px;
            color: #999;
            font-size: 12px;
          }

          img {
            width: 20px;
          }
        }
      }
    }

    .logout {
      position: fixed;
      bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 49px;
      color: #222;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>
