<template>
  <div class="page home" :class="{ scroll: homeStore.topScroll }">
    <!-- <x-header></x-header> -->
    <van-tabs
      class="tabs"
      :class="{ padding: isPadding }"
      v-model:active="active"
      swipeable
      animated
      lazy-render
    >
      <van-tab
        :style="{ height: `${contentHeight}px` }"
        v-for="(item, index) in tabs"
        :title="$t(item.i18n)"
        :key="index"
        :name="item.name"
      >
        <component :is="item.component"></component>
      </van-tab>
    </van-tabs>
    <!-- 非pwa环境下常驻 -->
    <div v-if="showDownload" class="download" @click.stop="handleAddToScreen">
      <img :src="pngLogo" alt="" />
      <span v-if="isChromeOrSamsung">{{ downloadBtnText }}</span>
      <span v-else>{{ $t('download.btn1') }}</span>
    </div>
  </div>
  <guide :show="showGuide" @hide="showGuide = false" />
  <!-- <div v-if="!myFullscreen" ref="maskRef" class="mask"></div> -->
  <!-- 首页支付挽留 -->
  <pay-hold-home
    v-model:visible="showHomePayHold"
    :location="payStore.jump_location"
    :goods="payStore.retentionGoodsNew"
    @gopay="handleVideoNowPay"
  />
  <!-- 支付方式 -->
  <pay-way v-model:visible="showPayWay" :goods="payGoods" />
  <!-- 支付结果 -->
  <pay-result v-model:visible="showPayResult" :order_id="payOrderId" @pay="userPayResult" />
</template>

<script setup lang="ts">
import { type Component } from 'vue';
import pngLogo from '../../imgs/logo.png';
import XDiscover from './discover/index.vue';
import XRecommend from './recommend/index.vue';
import { tabName, useHomeStore } from '../../store/modules/home';
import { LocaleLangEnum } from '@/locale/lang';
import useLocale from '@/composables/useLocale';
import xl from '@/utils/log';
import { installPopSubactid, isPwa } from '@/utils';
import Guide from '@/components/guide/index.vue';
import PayHoldHome from '../pay/component/pay-hold-home.vue';
import PayWay from '../pay/component/pay-way.vue';
import PayResult from '../pay/component/pay-result.vue';
import { usePwa } from '@/composables/usePwa';
import { tabBarHeight } from '@/utils/auth';
import { isAndroid, isChromeOrSamsung, isIOS, isMeta } from '@/utils/is';
import { usePay } from '../pay/usePay';
import { usePayStore } from '../../store/modules/pay';
import { useUserStore } from '../../store/modules/user';

const router = useRouter();
const { t } = useI18n();
const {
  showDownload,
  handleInstall,
  installing,
  percentNum,
  isInstalled,
  handleOpen,
  installFlag,
} = usePwa();
const {
  payOrderId,
  showHomePayHold,
  payGoods,
  showPayWay,
  showPayResult,
  handlePay,
  fetchPayHold,
  userPayResult,
} = usePay();
const myFullscreen = useStorage('_TOKTV_FULLSCREEN_', false, sessionStorage);
const { isFullscreen, enter, exit, toggle } = useFullscreen(document.documentElement);
// const maskRef = ref<HTMLElement>();
const contentHeight = computed(() => window.innerHeight - tabBarHeight.value);
const showGuide = ref(false);
const { currentLocale } = useLocale();
const isPadding = computed(() =>
  [LocaleLangEnum.zhCN, LocaleLangEnum.zhHK].includes(currentLocale.value)
);
const homeStore = useHomeStore();
const payStore = usePayStore();
const userStore = useUserStore();
const tabs: { title: string; name: tabName; i18n: string; component: Component }[] = [
  {
    title: '发现',
    name: tabName.discover,
    i18n: 'home.tabs.tab2',
    component: XDiscover,
  },
  {
    title: '推荐',
    name: tabName.recommend,
    i18n: 'home.tabs.tab3',
    component: XRecommend,
  },
];
const active = computed({
  get() {
    return homeStore.currentTab;
  },
  set(v) {
    homeStore.currentTab = v;
  },
});
const downloadBtnText = computed(() => {
  if (installing.value) {
    return percentNum.value > 99
      ? t('download.btn3')
      : `${t('download.btn2')}...${percentNum.value}%`;
  } else {
    return installFlag.value ? t('download.btn3') : t('download.btn1');
  }
});

const handleAddToScreen = () => {
  xl.act({ actentryid: '100008', subactid: installPopSubactid(), type: 'click' });
  if (isIOS) {
    if (isMeta) {
      window.location.href = 'https://apps.apple.com/us/app/toktv/id6464023756';
    } else {
      // 如果是ios打开，弹出引导动画（注意区分语言）
      // 1. 判断是否safari浏览器，
      // 否：弹出引导safari打开的动画，
      // 是：弹出引导添加到主屏幕动画 （有一张静态图优先展示，gif加载完替换静态图）
      showGuide.value = true;
    }
  } else {
    if (installing.value) return;
    // 如果是安卓打开，直接掉安装方法
    console.log('isInstalled.value=======>', isInstalled.value);
    console.log('installFlag.value=======>', installFlag.value);
    console.log('percentNum=========>', percentNum.value);
    if (isInstalled.value) {
      handleOpen();
    } else {
      handleInstall();
    }
    if (isChromeOrSamsung) {
      xl.act({ actentryid: '100013', subactid: isAndroid ? '1' : '2', type: 'show' });
    }
  }
};

const tsFn = async () => {
  if (import.meta.env.DEV || isPwa()) return;
  if (myFullscreen.value) return;
  console.log('tsFn ..........');
  try {
    await enter();
    myFullscreen.value = true;
  } catch (error) {
    // console.log(error);
  }
};

const handleVideoNowPay = (goods: PayType.PlyItemFormat) => {
  //如果只有一种支付方式则直接调用接口创建订单，否则让用户选择支付方式
  if (payStore.choosePayments.length > 1) {
    payGoods.value = goods;
    showPayWay.value = true;
  } else {
    handlePay(goods);
  }
};

const checkPayHold = () => {
  if (!userStore.isLogin) return;
  const historyState = window.history.state;
  if (historyState.forward?.indexOf('/video') > -1) {
    fetchPayHold(1);
  }
};

watch(
  () => homeStore.currentTab,
  (newVal) => {
    if (newVal === tabName.recommend) {
      xl.act({ actentryid: '200001', type: 'show' });
    }
  }
);

onMounted(() => {
  xl.act({ actentryid: '100001', type: 'show' });
  xl.act({ actentryid: '100008', subactid: installPopSubactid(), type: 'show' });
  //初始化时尝试全屏
  useEventListener(document.body, 'click', tsFn);
  //从视频页面到首页今日是否打开支付挽留弹框
  if (userStore.isLogin) {
    if (payOrderId.value) {
      showPayResult.value = true;
    } else {
      checkPayHold();
    }
  }
});
</script>

<style scoped lang="scss">
.page.home {
  position: relative;

  .tabs {
    &.padding {
      :deep(.van-tabs__wrap) {
        padding: 0 130px;
      }
    }
  }

  &.scroll {
    :deep(.van-tabs) {
      .van-tabs__wrap {
        background-image: url(../../imgs/bg-header.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }

      .van-tabs__nav {
        .van-tab {
          color: #222;
        }

        .van-tabs__line {
          background-color: #222;
        }
      }
    }
  }

  :deep(.van-tabs) {
    .van-tabs__wrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100vw;
      height: 60px;
      padding: 0 40px;
      background-image: linear-gradient(
        to top,
        transparent 0%,
        rgba(0, 0, 0, 0.2) 30%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }

    .van-tabs__nav {
      padding-bottom: 0;
      background-color: transparent;

      .van-tab {
        padding-bottom: 8px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        opacity: 0.6;

        &--active {
          opacity: 1;
        }
      }

      .van-tabs__line {
        width: 20px;
        height: 4px;
        background-color: white;
      }
    }

    .van-tabs__content {
      // height: calc(100% - 49px);
    }

    .van-swipe-item {
      height: auto;
      // overflow-y: auto;
    }
  }

  .download {
    position: fixed;
    right: 0;
    bottom: 80px;
    left: 50%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 85%;
    height: 55px;
    margin: 0 auto;
    padding: 0 20px;
    color: #fff;
    font-size: 16px;
    background: linear-gradient(90deg, #ff3465 0%, #ff4572 100%);
    border-radius: 28px;
    box-shadow: 0 3px 9px 0 rgba(255, 69, 113, 0.3);
    animation: breathe 1s infinite alternate;

    /* 设置呼吸灯效果的动画 */

    // &.open {
    //   min-width: 120px;
    //   width: 120px;
    // }

    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }

    // span {
    // }
  }
}

// .mask {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 9999;
//   width: 100vw;
//   height: 100vh;
// }
@keyframes breathe {
  0% {
    transform: scale(1);

    /* 初始状态 */
  }

  100% {
    transform: scale(1.1);

    /* 最终状态 */
  }
}
</style>
