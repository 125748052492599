<template>
  <div class="card-list">
    <div class="card-list-item" v-for="item in dramaList" :key="item.drama_id">
      <card-item2 :item="item" @click="handleClick(item)"></card-item2>
    </div>
    <!-- <card-item2></card-item2>
    <card-item2></card-item2>
    <card-item2></card-item2> -->
  </div>
</template>

<script setup lang="ts" name="card-list2">
import CardItem2 from './card-item2.vue';
import { useDramaList } from '../hooks/useDramaLIst';
import xl from '@/utils/log';

const { loading, dramaList } = useDramaList({ params: { label_id: '2' } });
const router = useRouter();
const handleClick = (item: DramaItem) => {
  router.push({
    name: 'video',
    query: {
      drama_id: item.drama_id,
      source: item.source,
      bigsource: '3',
    },
  });
  xl.act({ actentryid: '100004', subactid: item.drama_id, type: 'click' });
};

onMounted(() => {
  xl.act({ actentryid: '100004', type: 'show' });
});
</script>

<style scoped lang="scss">
.card-list {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding-left: 17px;
  overflow-x: auto;

  &-item {
    &:not(:first-of-type) {
      margin-top: 15px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
</style>
