<template>
  <van-popup
    v-model:show="show"
    class="pay-result"
    position="center"
    :close-on-click-overlay="false"
  >
    <div class="con">
      {{ $t('play.txt.desc5', { type: '' }) }}
    </div>
    <div class="btn__done" @click="handleCheckOrder('yes')">{{ $t('play.txt.btn1') }}</div>
    <div class="btn__help" @click="handleCheckOrder('no')">{{ $t('play.txt.btn2') }}</div>
  </van-popup>
</template>
<script setup lang="ts">
import xl from '@/utils/log';
import { payItem } from '@/utils/auth';
import { ToastWrapperInstance, showLoadingToast, showToast } from 'vant';
import { usePwa } from '@/composables/usePwa';
import { wakeUp } from '@/utils';
import { isMeta, isPro } from '@/utils/is';
import { usePayStore } from '@/pages/index/store/modules/pay';

interface IProps {
  visible: boolean;
  order_id: string;
  entrance_id?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  visible: false,
  order_id: '',
});

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void;
  (e: 'pay'): void;
}>();

const show = computed<boolean>({
  get() {
    return props.visible;
  },
  set(val) {
    emit('update:visible', val);
  },
});

const payStore = usePayStore();

const { getHref } = usePwa();
const { t } = useI18n();
let retryCount = 5; //轮询查询次数
let totalCount = 6;
let timer: ReturnType<typeof setTimeout>;
let checkLoading: ToastWrapperInstance | undefined;

type Result = 'yes' | 'no';

function handleCheckOrder(result: Result = 'yes') {
  xl.act({ actentryid: '110004', subactid: result, type: 'click' });
  checkLoading = showLoadingToast({
    message: '',
    forbidClick: true,
    duration: 0,
  });
  retryCount = result === 'yes' ? 5 : 2;
  totalCount = retryCount + 1;
  checkOrderStatus();
}

async function checkOrderStatus() {
  try {
    const order_id = props.order_id;
    const entrance_id = props.entrance_id;
    const res = await payStore.checkPayStatus(order_id, entrance_id);
    !isPro && console.log(`查询支付结果 >> `, JSON.parse(payItem.value || '{}'), res);
    if (payStore.paySuccess) {
      //支付成功
      try {
        showToast(t('play.txt.status_suc'));
        // 非pwa、未安装、非chrome环境下，提醒用chrome打开
        if (isMeta) {
          wakeUp(getHref());
        }
        xl.act({ actentryid: '120001', subactid: '2', type: 'show' });
        xl.act({ actentryid: '120003', subactid: order_id, type: 'show' });
      } catch (error) {
        console.error(error);
      } finally {
        clearTimeout(timer);
        emit('pay');
        checkLoading?.close();
      }
    } else {
      //服务端拿不到中台的支付回传认为订单在处理中...在此处需轮询去查订单状态，直到订单状态为1, 若5次订单状态未改变则认为此订单失败
      if (retryCount > 0) {
        timer = setTimeout(
          () => {
            retryCount--;
            checkOrderStatus();
          },
          150 * (totalCount - retryCount)
        );
      } else {
        // 认为支付失败
        emit('pay');
        checkLoading?.close();
        clearTimeout(timer);
      }
    }
  } catch (error) {
    console.error(error);
    checkLoading?.close();
    emit('pay');
    clearTimeout(timer);
  }
}

watch(
  () => show.value,
  (val) => {
    if (val) {
      xl.act({ actentryid: '110004', type: 'show' });
    } else {
      xl.act({ actentryid: '110004', type: 'close' });
    }
  }
);
</script>
<style lang="scss" scoped>
.pay-result {
  width: 300px;
  min-height: 150px;
  background: #fff;
  border-radius: 12px;

  .con {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 72px;
    padding: 0 20px;
    color: #222;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    border-bottom: 1px solid #d4d4d4;
  }

  .btn__done,
  .btn__help {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 43px;
    padding: 0 20px;
    line-height: 20px;
    text-align: center;

    &.btn__done {
      color: #ff3969;
      font-weight: 500;
      font-size: 14px;
      border-bottom: 1px solid #d4d4d4;
    }

    &.btn__help {
      color: #999;
      font-size: 13px;
      border-bottom: none;
    }
  }
}
</style>
