interface IItem {
  value: string;
  expires: number;
}

export default class Storage {
  static getItem(key: string) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr) as IItem;
    const now = new Date();

    if (now.getTime() > item.expires) {
      // 已过期，删除数据并返回null
      Storage.removeItem(key);
      return null;
    }
    return item.value;
  }

  /**
   * 存储数据
   * @param key 存储key
   * @param value key对应的value
   * @param expires 过期时间（单位：s），默认7天
   */
  static setItem(key: string, value: any, expires = 7 * 24 * 60 * 60) {
    const now = new Date();
    const item: IItem = {
      value: value,
      expires: now.getTime() + expires * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
  static clear() {
    localStorage.clear();
  }
}
