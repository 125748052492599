import { LocaleLangEnum } from '@/locale/lang';
import { TOKTV_LANG } from '@/pages/index/keys';

export default function useLocale() {
  const i18 = useI18n();
  const currentLocale = computed(() => i18.locale.value as LocaleLangEnum);

  const changeLocale = (value: LocaleLangEnum) => {
    console.log(currentLocale.value, value, '<<<< changeLocale');
    if (currentLocale.value === value) return;

    i18.locale.value = value;
    localStorage.setItem(TOKTV_LANG, value);
  };

  return {
    currentLocale,
    changeLocale,
  };
}
