<template>
  <div class="page mine">
    <div class="container">
      <div class="user">
        <div class="user__box" @click="handleLogin">
          <img :src="isLogin ? userinfo?.figureurl || pngDefault : pngDefault" alt="" />
          <div class="user__des">
            <div class="name">
              {{ isLogin ? userinfo?.nickname : `${$t('login.name.default')}` }}
            </div>
            <span v-show="userinfo?.id">ID:{{ userinfo?.id }}</span>
          </div>
        </div>
        <div v-if="showToBind && isLogin" class="to-bind" @click="handleToBind">
          <span>{{ $t('play.txt.to-bind') }}</span>
          <img src="../../imgs/mine/icon1.png" alt="" />
        </div>
      </div>
      <div class="data__box">
        <div class="data__box__top">
          <div class="top__left">{{ $t('play.txt.wallet') }}</div>
          <div class="top__right" @click="handleJumpWallet">
            <span>{{ $t('play.txt.wallet') }}</span
            ><img src="../../imgs/mine/icon1.png" alt="" />
          </div>
        </div>
        <div class="data__box__bot">
          <div class="bot__left">
            <div class="li">
              <div class="num">{{ payStore.memberInfo.k_value }}</div>
              <div class="li__bot">
                <img src="../../imgs/mine/gold-icon1.png" alt="" />
                <span>{{ $t('play.txt.kb') }}</span>
              </div>
            </div>
            <div class="li" v-show="isAppcqidOrganic()">
              <div class="num">{{ payStore.memberInfo.g_value }}</div>
              <div class="li__bot">
                <img src="../../imgs/mine/gold-icon2.png" alt="" />
                <span>{{ $t('task.gold') }}</span>
              </div>
            </div>
          </div>
          <div class="bot__right" @click="handleJumpPay">{{ $t('play.txt.recharge') }}</div>
        </div>
      </div>
      <div class="vip__box">
        <div class="box__left">
          <p class="p1">TokTV VIP</p>
          <p class="p2">
            {{
              payStore.memberInfo.is_vip == 1
                ? `${$t('play.txt.time', {
                    time: getActivityTime(payStore.memberInfo.valid, 14, true),
                  })}`
                : $t('play.txt.desc')
            }}
          </p>
        </div>
        <div class="box__right" @click="handleJumpPay"></div>
      </div>
      <ul>
        <li v-for="item in list" :key="item.id" @click="handleMenuItemEvent(item.id)">
          <div class="lll">
            <img :src="item.pic" alt="" />
            <span>{{ $t(item.i18n) }}</span>
          </div>
          <img src="../../imgs/mine/icon2.png" alt="" class="rrr" />
        </li>
      </ul>

      <!-- <div class="banner" @click="$router.push('/join-us')">
        <img :src="getAssetURL(`banner_${applang}.png`)" />
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePayStore } from '@/pages/index/store/modules/pay';
import { getActivityTime } from '@/utils/create-time';
import pngDefault from '../../imgs/mine/logo.png';
import xl, { isAppcqidOrganic } from '@/utils/log';
import { useUserStore } from '../../store/modules/user';
import { getCurrentLang } from '@/utils';
import { locale2lang } from '@/locale/lang';

const supportedUrlLang = ['en', 'th', 'pt'];
const lang = getCurrentLang();
const serverLang = locale2lang(lang);
const applang = supportedUrlLang.includes(serverLang) ? serverLang : 'en';

// const route = useRoute();
const router = useRouter();
const payStore = usePayStore();
const userStore = useUserStore();
const showToBind = computed(() => {
  // 判断用户是否已经绑定过google和facebook
  const alreadyBindGoogle = !!userStore.state.loginInfo.info?.find((item) => item.usertype == '24');
  const alreadyBindFacebook = !!userStore.state.loginInfo.info?.find(
    (item) => item.usertype == '23'
  );
  return !(alreadyBindGoogle && alreadyBindFacebook);
});
const isLogin = computed(() => !!userStore.tokenGetter);
const userinfo = computed(() => userStore.state.loginInfo);
const getAssetURL = (image: string) => {
  return new URL(`../../imgs/mine/${image}`, import.meta.url).href;
};
const list = ref([
  {
    id: 1,
    pic: getAssetURL('icon4.png'),
    name: '追剧',
    i18n: 'home.tabs.tab5',
  },
  // {
  //   id: 2,
  //   pic: getAssetURL('icon5.png'),
  //   name: '帮助中心',
  // },
  {
    id: 3,
    pic: getAssetURL('icon6.png'),
    name: '设置',
    i18n: 'setting.title',
  },
]);

onActivated(() => {
  xl.act({ actentryid: '400001', type: 'show' });
  payStore.getVipMemberInfo();
});

const handleMenuItemEvent = (id: number) => {
  if (id == 1) {
    if (isLogin.value) {
      router.push('/drama');
    } else {
      router.push('/login');
    }
    xl.act({ actentryid: '400005', type: 'click' });
  }
  if (id == 2) {
    //
  }
  if (id == 3) {
    router.push('/setting');
    xl.act({ actentryid: '400007', type: 'click' });
  }
};

const handleLogin = () => {
  if (isLogin.value) {
    handleToBind();
    return;
  }
  xl.act({ actentryid: '400002', type: 'click' });
  router.push('/login');
};

const handleJumpWallet = () => {
  xl.act({ actentryid: '400003', type: 'click' });
  router.push('/wallet');
};

const handleJumpPay = () => {
  xl.act({ actentryid: '400004', type: 'click' });
  router.push('/pay');
};

const handleToBind = () => {
  xl.act({ actentryid: '400008', type: 'click' });
  if (!isLogin.value) {
    router.push('/login');
  } else {
    router.push('/bind');
  }
};
</script>

<style scoped lang="scss">
.container {
  min-height: 100vh;
  padding-top: 1px;
  background: url('../../imgs/mine/bg.png') no-repeat top/100%;

  .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 345px;
    margin: 22px auto 0;

    .user__box {
      display: flex;
      align-items: center;

      img {
        width: 77px;
        height: 77px;
        margin-right: 16px;
        object-fit: cover;
        border-radius: 50%;
      }

      .user__des {
        .name {
          margin-bottom: 7px;
          font-size: 21px;
        }

        span {
          color: #999;
          font-size: 14px;
        }
      }
    }

    .to-bind {
      display: flex;
      align-items: center;

      span {
        margin-right: 4px;
        color: #999;
        font-size: 14px;
      }

      img {
        width: 6px;
      }
    }
  }

  .data__box {
    width: 345px;
    height: 130px;
    margin: 22px auto 0;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 7px 0 rgba(233, 233, 233, 0.6);

    .data__box__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 15px;
      padding: 15px 0;
      border-bottom: 1px solid #f2f2f2;

      .top__left {
        font-size: 16px;
      }

      .top__right {
        display: flex;
        align-items: center;

        span {
          margin-right: 4px;
          color: #999;
          font-size: 14px;
        }

        img {
          width: 6px;
        }
      }
    }

    .data__box__bot {
      position: relative;
      margin-top: 23px;

      .bot__left {
        display: flex;
        margin-right: 90px;
        margin-left: 15px;

        .li {
          margin-right: 5px;

          .num {
            font-weight: bold;
            font-size: 20px;
            text-align: center;
          }

          .li__bot {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 15px;
              margin-right: 4px;
            }

            span {
              color: #999;
              font-size: 14px;
            }
          }
        }
      }

      .bot__right {
        position: absolute;
        top: 0;
        right: 15px;
        width: 90px;
        height: 38px;
        color: #fff;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        background: linear-gradient(90deg, #ff3465 0%, #ff4572 100%);
        border-radius: 19px;
      }
    }
  }

  .vip__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 345px;
    height: 65px;
    margin: 12px auto 0;
    padding: 15px 20px 15px 60px;
    background: url('../../imgs/mine/li-bg.png') no-repeat 0/100%;

    .box__left {
      .p1 {
        margin-bottom: 4px;
        color: #f5cb93;
        font-weight: bold;
        font-size: 18px;
      }

      .p2 {
        color: #d9dbde;
        font-size: 12px;
      }
    }

    .box__right {
      width: 25px;
      height: 25px;
      background: url('../../imgs/mine/icon3.png') no-repeat 0/100%;
    }
  }

  ul {
    margin: 22px 19px 0 12px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      .lll {
        display: flex;
        align-items: center;

        img {
          width: 23px;
          margin-right: 9px;
        }

        span {
          font-size: 16px;
        }
      }

      .rrr {
        width: 7px;
      }
    }
  }
}

.banner {
  width: 345px;
  height: 120px;
  margin: 20px auto;
}
</style>
