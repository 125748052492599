<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
import { Locale } from 'vant';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhHK from 'vant/es/locale/lang/zh-HK';
import viVN from 'vant/es/locale/lang/vi-VN';
import thTH from 'vant/es/locale/lang/th-TH';
import idID from 'vant/es/locale/lang/id-ID';
import enUS from 'vant/es/locale/lang/en-US';
import ptBR from 'vant/es/locale/lang/pt-BR';
import useLocale from '@/composables/useLocale';
import { LocaleLangEnum, lang2locale } from '@/locale/lang';
import { getIpInfo } from '@/pages/index/api/index';
import {
  curEpisodeMap,
  cacheIPInfo,
  setLaunchSearchParams,
  setShareDramaId,
  setCz,
  setPixelId,
  setPlat,
  swRegistered,
  shouldShowWebPushSubscription,
  showWebPushSubscriptionTime,
} from '@/utils/auth';
import xl from '@/utils/log';
import { getCurrentLang, getHrefWithoutMyExtraInfo, getQueryParam, isPwa } from '@/utils';
import { K_DRAMA_ID, K_LOG_DRAMA_ID, MY_EXTRA_INFO } from './keys';
import { isMeta } from '@/utils/is';
import { useUserStore } from './store/modules/user';
import { usePayStore } from './store/modules/pay';

import { useRegisterSW } from 'virtual:pwa-register/vue';
import {
  myPostMessage,
  registerSWMessage,
  requestNotificationPermission,
  subscribeToServer,
} from '@/utils/web-push';
const updateServiceWorker = useRegisterSW({
  onRegistered(r) {
    console.log('[SW] onRegistered', r);
    swRegistered.value = true;

    // 更新环境变量
    myPostMessage({
      type: 'UPDATE_ENV',
      data: import.meta.env.VITE_APP_ENV,
    });
    // 更新公参
    myPostMessage({
      type: 'UPDATE_COMMON_PARAMS',
      data: xl.commonParams,
    });

    // registerSWMessage((d) => {
    //   console.log('[SW] registerSWMessage >> ', d);
    //   const { type, data } = d;
    //   switch (type) {
    //     case 'ON_PUSH':
    //       // 接收到推送消息
    //       xl.act({ actentryid: '130012', subactid: data.url, type: 'show' });
    //       break;
    //     case 'ON_NOTIFICATION_CLICK':
    //       // 推送消息点击
    //       xl.act({ actentryid: '130012', subactid: data.url, type: 'click' });
    //       break;
    //     default:
    //       console.log('[SW] 其他消息通信 received >> ', d);
    //   }
    // });
  },
});

console.log('[SW] updateServiceWorker >> ', updateServiceWorker);

const userStore = useUserStore();
const payStore = usePayStore();

(function init() {
  console.log(isMeta, '<<<< isMeta');

  // 缓存首次进入项目的URL参数
  setLaunchSearchParams(location.search);
  // 缓存来源剧ID
  // 剧ID这里逻辑有点绕，首次进来肯定是 drama_id 参数，但是从FB跳到chrome之后，优先取 log_drama_id
  const logDramaId = getQueryParam(K_LOG_DRAMA_ID) || getQueryParam(K_DRAMA_ID);
  console.log(logDramaId, ' <<< logDramaId');
  if (logDramaId) {
    setShareDramaId(logDramaId);
  }
  // 缓存cz（与支付选项控制有关）
  const cz = getQueryParam('cz');
  console.log(cz, ' <<< cz');
  if (cz) {
    setCz(cz);
  }
  // 缓存pix（与facebook pixel有关）
  const pixelId = getQueryParam('pix');
  console.log(pixelId, ' <<< pixelId');
  if (pixelId) {
    setPixelId(pixelId);
  }
  // 缓存plat（与facebook pixel有关）
  const plat = getQueryParam('plat');
  console.log(plat, ' <<< plat');
  if (plat) {
    setPlat(plat);
  }

  // 获取并缓存地区信息
  getIpInfo()
    .then((ipInfo) => {
      xl.setParams({ region: ipInfo.data.country, province: ipInfo.data.subdivision });
      cacheIPInfo(ipInfo.data);
    })
    .catch((error) => console.error(error));

  // pwa环境fb回传逻辑
  if (isPwa()) {
    xl.act({ actentryid: '100012', type: 'click' });
  }

  //获取用户会员信息(是否会员以及会员过期时间，K币，金币余额)
  payStore.getVipMemberInfo();

  // 三方跳chrome时参数解析（登录状态、用户信息、公参、推广参数等）
  try {
    const myExtraInfo = getQueryParam(MY_EXTRA_INFO);
    if (myExtraInfo) {
      const { ui, cp, cem, cz, pix } = JSON.parse(myExtraInfo);
      console.log({ ui, cp, cem, cz, pix }, '<<<< myExtraInfo');
      if (pix) {
        setPixelId(pix);
      }
      if (cz) {
        setCz(cz);
      }
      if (ui) {
        userStore.updateLoginInfo(ui);
      }
      if (cp) {
        const { accid, uid, appcqid } = cp;
        xl.setParams({ accid, uid, appcqid });
      }
      if (cem) {
        curEpisodeMap.value = cem;
      }
      nextTick(() => {
        location.replace(getHrefWithoutMyExtraInfo());
      });
    }
  } catch (error) {
    console.log(error);
  }
})();

const { currentLocale, changeLocale } = useLocale();

const setVantLocale = (value: LocaleLangEnum) => {
  switch (value) {
    case LocaleLangEnum.zhCN:
      Locale.use(value, zhCN);
      break;
    case LocaleLangEnum.zhHK:
      Locale.use(value, zhHK);
      break;
    case LocaleLangEnum.viVN:
      Locale.use(value, viVN);
      break;
    case LocaleLangEnum.thTH:
      Locale.use(value, thTH);
      break;
    case LocaleLangEnum.idID:
      Locale.use(value, idID);
      break;
    case LocaleLangEnum.enUS:
      Locale.use(value, enUS);
      break;
    case LocaleLangEnum.ptBR:
      Locale.use(value, ptBR);
      break;
    default:
      Locale.use(LocaleLangEnum.zhHK, zhHK);
      break;
  }
};

async function tryGrantPushPermission() {
  /*
  一、
    1. 用户未授权 + 用户未弹出过授权弹窗
    2. 用户未授权 + 用户已弹出过授权弹窗
    3. 用户已授权
    4. 用户禁止授权
  二、
    1. 用户已订阅
    2. 用户未订阅
  */
  const permission: NotificationPermission | 'unknown' =
    window?.Notification?.permission || 'unknown';
  console.log('[WEB-PUSH] 用户授权前状态', permission);
  if (permission === 'default') {
    // 用户未授权过
    // 判断弹窗是否在允许时间范围段。
    if (shouldShowWebPushSubscription()) {
      requestWebPushPermission();
      // 记录弹窗时间，方便后面控制授权弹窗频率
      showWebPushSubscriptionTime.value = Date.now();
    }
  }
}

async function requestWebPushPermission() {
  // xl.act({ actentryid: '130013', subactid: '', type: 'click' });

  let permission: NotificationPermission = 'default';
  try {
    xl.act({ actentryid: '130014', subactid: '', type: 'show' });
    // 请求通知权限并订阅推送服务
    permission = await requestNotificationPermission();
    if (permission === 'granted') {
      xl.act({ actentryid: '130014', subactid: '1', type: 'click' });
    } else if (permission === 'denied') {
      xl.act({ actentryid: '130014', subactid: '2', type: 'click' });
    }
  } catch (error) {
    console.error('[WEB-PUSH] requestNotificationPermission', error);
  }
  xl.act({ actentryid: '130014', subactid: '', type: 'close' });
  console.log('[WEB-PUSH] requestNotificationPermission', permission);
  if (permission === 'granted') {
    // 测试通知 -- start
    // testShowNotification();
    // 测试通知 -- end

    // 用户允许通知，直接订阅通知到服务器
    try {
      const res = await subscribeToServer();
      if (res.code == 0) {
        console.log('[WEB-PUSH] subscribeToServer res >> ', res);
        xl.act({ actentryid: '130015', subactid: '', type: 'show' });
      } else {
        console.error('[WEB-PUSH] subscribeToServer res >> ', res);
      }
    } catch (error) {
      console.error('[WEB-PUSH] subscribeToServer err >> ', error);
    }
  }
}

watch(
  () => currentLocale.value,
  (value) => {
    setVantLocale(value);
  },
  {
    immediate: true,
  }
);

onMounted(async () => {
  const lang = getCurrentLang();
  changeLocale(lang);
  // changeLocale(LocaleLangEnum.ptBR);

  setTimeout(() => {
    tryGrantPushPermission();
  }, 3000);
});
</script>

<style lang="scss" scoped></style>
