<template>
  <div class="container setting">
    <div class="top__bar">
      <img @click="$router.push('/mine')" src="../../imgs/wallet/back.png" alt="" />
      <h3>{{ $t('setting.title') }}</h3>
    </div>
    <div class="ul__box">
      <ul>
        <li v-for="item in list" :key="item.id" @click="handleMenuItemEvent(item.id)">
          <div class="lll">
            <img :src="item.pic" class="pic" />
            {{ $t(item.i18n) }}
          </div>
          <img src="../../imgs/wallet/icon3.png" alt="" class="rrr" />
        </li>
      </ul>
    </div>
  </div>
  <!-- 联系我们 -->
  <contact-us :show="showContactUs" @hide="hideContactUs"></contact-us>
</template>
<script setup lang="ts">
import ContactUs from './component/contact-us.vue';
import { usePayStore } from '@/pages/index/store/modules/pay';
import xl from '@/utils/log';
import { useUserStore } from '../../store/modules/user';

const getAssetURL = (image: string) => {
  return new URL(`../../imgs/setting/${image}`, import.meta.url).href;
};
const router = useRouter();
const payStore = usePayStore();
const userStore = useUserStore();
const showContactUs = ref(false);
const list = ref([
  {
    id: 1,
    pic: getAssetURL('pic1.png'),
    name: '切换语言',
    i18n: 'setting.menu1',
  },
  {
    id: 2,
    pic: getAssetURL('pic2.png'),
    name: '用户协议',
    i18n: 'setting.menu2',
  },
  {
    id: 3,
    pic: getAssetURL('pic3.png'),
    name: '隐私政策',
    i18n: 'setting.menu3',
  },
  {
    id: 4,
    pic: getAssetURL('pic4.png'),
    name: '联系我们',
    i18n: 'setting.menu4',
  },
]);

const handleMenuItemEvent = (id: number) => {
  if (id == 1) {
    router.push('/language');
  }

  if (id == 2) {
    router.push('/user-agreement');
  }

  if (id == 3) {
    router.push('/privacy-policy');
  }

  if (id == 4) {
    showContactUs.value = true;
  }
  xl.act({ actentryid: '900001', subactid: `${id}`, type: 'click' });
};

const hideContactUs = () => {
  showContactUs.value = false;
};

onMounted(() => {
  xl.act({ actentryid: '900001', type: 'show' });
});
</script>
<style scoped lang="scss">
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &.setting {
    .top__bar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      margin: 20px 0;

      h3 {
        font-weight: bold;
        font-size: 18px;
      }

      img {
        position: absolute;
        left: 15px;
        width: 25px;
      }
    }

    .ul__box {
      width: 100%;
      margin-top: 10px;
      padding-top: 1px;
      background: #fff;
      border-radius: 15px 15px 0 0;
    }

    ul {
      margin: 0 15px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 49px;
        border-bottom: 2px solid #f5f5f5;

        &:last-child {
          border-bottom: none;
        }

        .lll {
          display: flex;
          align-items: center;

          img {
            width: 23px;
            margin-right: 9px;
          }

          span {
            color: #222;
            font-weight: 500;
            font-size: 16px;
          }
        }

        .rrr {
          width: 20px;
        }
      }
    }
  }
}
</style>
