<template>
  <!-- :autoplay="{
      delay: 3000,
      disableOnInteraction: true,
    }" -->
  <swiper :slides-per-view="1" :space-between="-28" :modules="modules">
    <swiper-slide v-for="item in props.list" :key="item.showId">
      <div class="panel" @click="handleBuyGoods(item)">
        <div class="mark" v-show="item.custom?.markText">{{ item.custom?.markText }}</div>
        <div class="info">
          <div class="name">{{ item.commodityName }}</div>
          <div class="get">{{ item.commodityDesc }}</div>
        </div>
        <div class="price-wrap">
          <div class="price">{{ item.custom?.unit }}{{ item.price }}</div>
          <div class="price-origin" v-if="item.custom?.originPrice">
            {{ item.custom?.unit }}{{ item.custom.originPrice }}
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const modules = [Autoplay];
const emit = defineEmits(['buy']);
const props = defineProps({
  list: Array<PayType.PlyItemFormat>,
});
const handleBuyGoods = (item: PayType.PlyItemFormat) => {
  emit('buy', item);
};
</script>
<style lang="scss" scoped>
.swiper {
  overflow: inherit;

  .panel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 335px;
    height: 80px;
    margin: 0 auto;
    padding: 0 15px 0 20px;
    background: url('@/pages/index/imgs/pay/vip-panel.png');
    background-color: #3d362c;
    background-size: 100% 100%;
    border-radius: 11px;

    .mark {
      position: absolute;
      top: -9px;
      left: 0;
      min-width: 60px;
      height: 21px;
      padding: 0 10px;
      color: #fff;
      font-size: 12px;
      line-height: 21px;
      text-align: center;
      background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
      border-radius: 11px 0;
    }

    .info {
      .name {
        color: #ffc97f;
        font-weight: 500;
        font-size: 18px;
        font-style: italic;
      }

      .get {
        margin-top: 4px;
        color: #fff2e1;
        font-size: 13px;
      }
    }

    .price-wrap {
      margin-left: 10px;
    }

    .price {
      padding: 0 12px;
      color: #652d00;
      font-weight: bold;
      font-size: 13px;
      line-height: 33px;
      text-align: center;
      word-break: normal;
      background: linear-gradient(180deg, #ffe5c3 0%, #f09c30 100%);
      border-radius: 50px;

      &-origin {
        width: 100%;
        margin-top: 2px;
        color: #fed299;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        opacity: 0.4;
        text-decoration-line: line-through;
      }
    }
  }
}
</style>
