<template>
  <div class="page turntable">
    <div class="main">
      <div class="top__bar">
        <img @click="handleGoBack" src="../../imgs/turntable/back.png" alt="" />
      </div>
      <div class="turntable-title">{{ $t('turntable.title') }}</div>
      <div class="turntable-main">
        <div class="dish-container">
          <div class="dish" :style="{ transform: `rotate(${rotation}deg)` }" ref="prizeWrap">
            <div
              :class="['prize', { 'color-red': index % 2 === 0 }]"
              v-for="(item, index) in awardsConf"
              :key="index"
              :style="{ transform: `rotate(${(index + 1) * 60}deg)` }"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="pointer">100%</div>
      </div>
      <div class="surplus">{{ $t('turntable.drawnum', { num: diffNum }) }}</div>
      <div class="drawbtn" @click="handleFreeDraw">{{ $t('turntable.drawbtn') }}</div>
    </div>
    <div class="rule">
      <p class="title">{{ $t('turntable.rule') }}</p>
      <p>{{ $t('turntable.rule1', { total: totalNum }) }}</p>
      <p>{{ $t('turntable.rule2') }}</p>
      <p>{{ $t('turntable.rule3') }}</p>
    </div>
  </div>
  <x-ad
    v-model:visible="showAD"
    pagetype="nativeinsert"
    gametype="nativeinsertdzp"
    adSlot="display"
    @close="handleCloseXAd"
  />
  <dialog-playsuc
    :visible="popPlaySuc"
    :gold="getGold"
    @double="handlePlayDouble"
    @close="handleClosePopPlaySuc"
  />
  <dialog-getgold :visible="popGetGold" :gold="getGold" :type="2" @close="handleClosePopGetGold" />
</template>
<script lang="ts" setup>
import { getTurntableConfig, turntableLuckDraw, turntableGetDouble } from '@/pages/index/api';
import dialogPlaysuc from './component/dialog-playsuc.vue';
import dialogGetgold from './component/dialog-getgold.vue';
import { goBack } from '../../router';
import xl from '@/utils/log';

const showAD = ref(false);
const popPlaySuc = ref(false);
const popGetGold = ref(false);
const awardsConf = ref<Array<number>>([]); //金币奖励配置
const getGold = ref(0); //获得的金币数量
const diffNum = ref(0); //剩余次数
const totalNum = ref(0); //总次数
const todayNum = ref(0); //今日已抽奖次数
const spinning = ref(false); //正在抽奖中...
const rotation = ref(0); //旋转角度
const isAdDouble = ref<1 | 2>(1); //是否通过看广告，再次领取 1-否 2-是
const turnsNum = 5; //旋转的圈数
const prizeAngle = 360 / 6; // 每个奖品占据的角度
const prizeWrap = ref<any>(null);

/**
 * 返回
 */
const handleGoBack = () => {
  goBack();
};
/**
 * 获取大转盘配置信息
 */
const getTurntableConfigReq = async () => {
  try {
    const res = await getTurntableConfig();
    console.log('getTurntableConfigReq===>', res);
    const { diff, num, today_num, awards } = res.data;
    diffNum.value = diff;
    totalNum.value = num;
    todayNum.value = today_num;
    awardsConf.value = awards;
  } catch (error) {
    console.log(error);
  }
};

/**
 * 大转盘抽奖，点击抽奖先调用广告
 */
const handleFreeDraw = () => {
  if (!diffNum.value) return;
  if (spinning.value) return;
  showAD.value = true;
  spinning.value = true;
  xl.act({ actentryid: '600006', type: 'click' });
};

/**
 * 关闭广告后，开启大转盘抽奖
 */
const handleCloseXAd = async () => {
  if (isAdDouble.value == 1) {
    try {
      const res = await turntableLuckDraw({ number: todayNum.value });
      if (res && res.code != 0) return;
      const { coin, today_num, num, diff } = res.data;
      getGold.value = coin;
      diffNum.value = diff;
      todayNum.value = today_num;
      totalNum.value = num;
      //大转盘开始转动, 并转动到目标上
      const targetIndex = awardsConf.value.findIndex((item: number) => {
        return item === coin;
      });
      turntableRotate(targetIndex);
    } catch (error) {
      console.error(error);
    }
  } else {
    watchAdGoldDouble();
  }
};

/**
 * 大转盘转动到目标下标
 * index->目标下标
 */
const turntableRotate = async (index: number) => {
  let angle = 0;
  angle =
    360 * turnsNum + 360 / awardsConf.value.length + 360 - (index + 1) * prizeAngle - prizeAngle;
  prizeWrap.value.style = `transform:rotate(${angle}deg); transition: all 3s ease;`;
  prizeWrap.value.addEventListener('transitionend', stopRotate);
  rotation.value = angle;
};

/**
 * 大转盘转动结束
 */
const stopRotate = () => {
  prizeWrap.value.style = `transform:rotate(${rotation.value}deg);`;
  setTimeout(() => {
    spinning.value = false;
    popPlaySuc.value = true;
  }, 300);
};

/**
 * 再次领取
 */
const handlePlayDouble = () => {
  handleClosePopPlaySuc();
  showAD.value = true;
  isAdDouble.value = 2;
};

/**
 * 看视频双倍领取
 */
const watchAdGoldDouble = async () => {
  try {
    const res = await turntableGetDouble({ number: todayNum.value });
    if (res && res.code != 0) return;
    const { coin } = res.data;
    getGold.value = coin;
    popGetGold.value = true;
  } catch (error) {
    console.error(error);
  }
};

/**
 * 关闭恭喜获得金币弹框
 */
const handleClosePopPlaySuc = () => {
  popPlaySuc.value = false;
  rotation.value = 0;
};

/**
 * 关闭再次领取金币弹框
 */
const handleClosePopGetGold = () => {
  popGetGold.value = false;
  isAdDouble.value = 1;
};

onMounted(() => {
  xl.act({ actentryid: '600006', type: 'show' });
  getTurntableConfigReq();
});
</script>
<style lang="scss" scoped>
.page {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 23px;
  background-color: #2f03c0;

  &.turntable {
    .main {
      width: 100%;
      height: 652px;
      padding-top: 20px;
      background: url('@/pages/index/imgs/turntable/bg.png');
      background-size: 100% 100%;

      .top__bar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
        margin: 0 0 15px;
        padding: 0 15px;

        span {
          min-width: 73px;
          height: 28px;
          padding: 0 10px;
          color: #7e808a;
          font-size: 13px;
          line-height: 28px;
          text-align: center;
          background: #ebedef;
          border: 0.5px solid #a9aaad;
          border-radius: 14px;
        }

        img {
          left: 15px;
          width: 25px;
        }
      }

      .turntable-title {
        width: 350px;
        height: 87.5px;
        margin: 0 auto 6px;
        padding: 16px 0 0;
        color: #fff3d6;
        font-weight: bold;
        font-size: 34px;
        line-height: 34px;
        text-align: center;
        background: url('@/pages/index/imgs/turntable/title.png');
        background-size: 100% 100%;
        // word-break: normal;
      }

      .turntable-main {
        position: relative;
        width: 375px;
        height: 375px;
        margin: 0 auto;
        padding-top: 27.5px;
        background: url('@/pages/index/imgs/turntable/outer-ring.png');
        background-size: 100% 100%;

        .dish-container {
          position: relative;
          width: 282.5px;
          height: 282.5px;
          margin: 0 auto;

          .dish {
            position: relative;
            width: 100%;
            height: 100%;
            background: url('@/pages/index/imgs/turntable/dish.png');
            background-size: 100% 100%;
            transform-style: preserve-3d;
            // transition: transform 1s ease;

            .prize {
              position: absolute;
              width: 100%;
              height: 100%;
              padding-top: 10px;
              color: #ffe476;
              font-weight: bold;
              font-size: 16px;
              text-align: center;

              &.color-red {
                color: #f03b5c;
              }
            }
          }
        }

        .pointer {
          position: absolute;
          top: 79px;
          right: 0;
          left: 0;
          width: 148.5px;
          height: 162px;
          margin: auto;
          padding-top: 75px;
          color: #fefefe;
          font-weight: bold;
          font-size: 24px;
          text-align: center;
          background: url('@/pages/index/imgs/turntable/pointer.png');
          background-size: 100% 100%;
        }
      }

      .surplus {
        margin-top: 15px;
        color: #b087fa;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
      }

      .drawbtn {
        width: 320px;
        height: 89px;
        margin: 0 auto;
        color: #e92d2d;
        font-weight: 800;
        font-size: 25px;
        line-height: 86px;
        text-align: center;
        background: url('@/pages/index/imgs/turntable/btn.png');
        background-size: 100% 100%;
      }
    }

    .rule {
      width: 345px;
      min-height: 230px;
      margin: 23px auto 0;
      padding: 16px;
      background: url('@/pages/index/imgs/turntable/rule.png');
      background-size: 100% 100%;

      p {
        margin-bottom: 16px;
        color: #e0caff;
        font-size: 13px;
        line-height: 18px;
        // text-align: justify;
        word-break: normal;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        margin-bottom: 16px;
        color: #e0caff;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
</style>
