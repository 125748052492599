<template>
  <van-swipe class="swipe" :autoplay="0" lazy-render>
    <van-swipe-item
      v-for="item in recommendList"
      :key="item.drama_id"
      :autoplay="60"
      @click="handleClick(item)"
    >
      <img class="img" :src="item.cover" />
      <div class="img-mask">
        <div class="lf">
          <h3 class="title">{{ item.title }}</h3>
          <!-- <div class="tag">复仇</div> -->
        </div>
        <div class="rt"><img :src="pngPlay" alt="paly" /></div>
      </div>
    </van-swipe-item>
  </van-swipe>
</template>

<script setup lang="ts" name="x-banner">
import pngPlay from '../../../imgs/i-play.png';
import { getBannerList } from '../../../api';
import xl from '@/utils/log';

const recommendList = ref<Array<DramaItem>>([]);
const loadData = async () => {
  const res = await getBannerList({});
  recommendList.value = res.data.recommend_list;
};
loadData();

const router = useRouter();
const handleClick = (item: DramaItem) => {
  router.push({
    name: 'video',
    query: {
      drama_id: item.drama_id,
      source: item.source,
      bigsource: '1',
    },
  });
  xl.act({ actentryid: '100002', subactid: item.drama_id, type: 'click' });
};

onMounted(() => {
  xl.act({ actentryid: '100002', type: 'show' });
});
</script>

<style scoped lang="scss">
.swipe {
  height: 260px;
  background-color: black;

  :deep(.van-swipe__indicators) {
    .van-swipe__indicator--active {
      width: 12px;
      background-color: #fff;
      border-radius: 5px;
    }
  }

  .img {
    width: 100%;
    height: 100%;

    &-mask {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      padding: 0 15px;
      background-image: linear-gradient(
        to bottom,
        transparent 0%,
        rgba(0, 0, 0, 0.2) 30%,
        rgba(0, 0, 0, 0.6) 100%
      );

      .lf {
        flex: 1;
        // margin-top: 15px;
        // margin-left: 15px;
        padding-right: 10px;
        color: #fff;
        line-height: 1;

        .title {
          font-size: 17px;
        }

        .tag {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 21px;
          margin-top: 10px;
          font-size: 12px;
          background-image: url(../../../imgs/circle.png);
          background-repeat: no-repeat;
          background-size: cover;
          opacity: 0.7;
        }
      }

      .rt {
        // position: absolute;
        // right: 18px;
        // bottom: 15px;
        width: 39px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
