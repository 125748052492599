function formatZero(num: number) {
  if (num < 10) {
    return '0' + num;
  } else {
    return '' + num;
  }
}
export function formatTimeNow(t: number, s = 1) {
  let date = null;
  t = t || 0;
  if (t) {
    date = new Date(t * 1000);
  } else {
    date = new Date();
  }
  let json = null;
  if (s == 1) {
    json = {
      year: date.getFullYear(),
      mon: date.getMonth() + 1,
      day: date.getDate(),
      week: date.getDay(),
      h: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds(),
    };
  } else {
    json = {
      year: formatZero(date.getFullYear()),
      mon: formatZero(date.getMonth() + 1),
      day: formatZero(date.getDate()),
      week: formatZero(date.getDay()),
      h: formatZero(date.getHours()),
      m: formatZero(date.getMinutes()),
      s: formatZero(date.getSeconds()),
    };
  }
  return json;
}
export function getActivityTime(t: number, type = 1, isHm: boolean | number = true) {
  // 处理活动时间
  try {
    let time, time2;
    if (isHm) {
      time = formatTimeNow(t / 1000, 1);
      time2 = formatTimeNow(t / 1000, 2);
    } else {
      time = formatTimeNow(t, 1);
      time2 = formatTimeNow(t, 2);
    }
    // console.log('time2:',time2)
    if (type == 1) {
      return `${time2.mon}月${time2.day}日 ${time2.h}:${time2.m}:${time2.s}`;
    } else if (type == 2) {
      return `${time.mon}月${time.day}日`;
    } else if (type == 3) {
      // return `${time.mon}月${time.day}日${time2.h}:${time2.m}:${time2.s}`
      return `${time.mon}月${time.day}日 ${time2.h}:${time2.m}`;
    } else if (type == 4) {
      return `${time.year}年${time.mon}月${time.day}日`;
    } else if (type == 5) {
      return `${time.mon}月${time.day}日${time2.h}:${time2.m}`;
    } else if (type == 6) {
      if (t == 0) {
        return `00:00`;
      } else {
        return `${time2.m}:${time2.s}`;
      }
    } else if (type == 7) {
      return `${time2.year}-${time2.mon}-${time2.day}`;
    } else if (type == 8) {
      return `${time2.mon}/${time2.day} ${time2.h}:${time2.m}`;
    } else if (type == 9) {
      return `${time2.mon}-${time2.day} ${time2.h}:${time2.m}`;
    } else if (type == 10) {
      return `${time2.day}天${time2.h}时${time2.m}分`;
    } else if (type == 11) {
      return `${time2.day}${time2.h}${time2.m}`;
    } else if (type == 12) {
      return `${time2.mon}月${time2.day}日`;
    } else if (type == 13) {
      return `${time2.mon}.${time2.day}`;
    } else if (type == 14) {
      return `${time2.year}/${time2.mon}/${time2.day}`;
    } else {
      return `${time2.year}.${time2.mon}.${time2.day} ${time2.h}:${time2.m}:${time2.s}`;
    }
  } catch (error) {
    console.log('error:', error);
  }
}
