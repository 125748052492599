<template>
  <div class="content">
    <span class="right first">Version Release Date: August 2023</span>
    <span class="right">Effective Date of this Version: August 2023</span>
    <div class="panel">
      <p>Welcome to Toktv APP!</p>
      <p>
        Toktv APP is developed and operated by Hong Kong Xingneng Yongsheng Technology Co., Ltd.
        ("we"), registered address: 19H, Mandy Plaza, 3 Tai Yau Street, San Po Kong, Kowloon, Hong
        Kong. This Privacy Policy ("Policy") explains who we are, how we collect, share, and use
        your personal information, and how you can exercise your privacy rights. This Policy applies
        only to the personal information collected through all services provided on our Toktv APP.
      </p>
      <p>
        By agreeing to this Policy or continuing to use Toktv services, you consent and authorize us
        to collect, use, store, and process your personal information in accordance with this
        Policy. We will periodically update this Policy and notify you through various means such as
        push notifications, announcements, or pop-ups.
      </p>
      <p>
        Please read this Privacy Policy carefully and fully understand its contents before using our
        services. If you have any questions or concerns about how we use your personal information,
        please contact us using the contact information provided at the bottom of this Privacy
        Policy.
      </p>
      <p>I. What Personal Information We Collect and Process About You</p>
      <p>II. How We Use Your Personal Data</p>
      <p>III. Who We Share Your Personal Information With</p>
      <p>IV. Use of Cookies and Similar Technologies</p>
      <p>V. Protecting Your Personal Information Security</p>
      <p>VI. International Data Transfers</p>
      <p>VII. Retention of Your Personal Information</p>
      <p>VIII. Your Data Protection Rights</p>
      <p>IX. Information Related to Children</p>
      <p>X. Updates to this Privacy Policy</p>
      <p>XI. How to Contact Us</p>
      <p>I. What Personal Information We Collect and Process About You</p>
      <p>Information Provided Voluntarily by You</p>
      <p>
        Certain parts of our service may ask you to voluntarily provide personal information. For
        example, we may ask you to provide your contact information to register an account with us.
        The personal information requested and the reasons for requesting it will be explained to
        you when we ask for your personal information.
      </p>
      <p>(1) Registering as a user of our service</p>
      <p>
        You can register to become a user of our service. Please note that registration is not a
        prerequisite for using our service. When you register and log in to your Toktv account, you
        will need to follow the specified steps to complete a series of registration procedures.
        During this process, you will be required to provide us with device information, and we will
        generate your user ID on Toktv. You may also provide your nickname, avatar, and other
        related information during registration or at a later date. You should ensure the accuracy
        of such information. We collect this information to provide account registration and login
        services, protect your account, and mitigate security risks.
      </p>
      <p>(2) Creating your profile on Toktv</p>
      <p>
        You can enter account settings to provide more personal information about yourself, such as
        your date of birth, nickname, avatar, personalized tagline, etc. Please note that this step
        is entirely optional for your use of our service.
      </p>
      <p>Information Automatically Collected by Us</p>
      <p>
        (1) When you use our service, we may automatically collect certain information from your
        device. In some cases, using your personal information may lead to automated decision-making
        (including understanding your preferences and suggesting related videos based on your
        viewing preferences). Automated decision-making means that decisions about you are made
        automatically (using software algorithms) without our manual review. You can use settings on
        Toktv to express your preferences for certain videos, and the algorithm will record your
        choices and accordingly reduce the frequency of sharing similar videos with you.
      </p>
      <p>(2) Security, Administrative, and Management of Our Service Platform</p>
      <p>
        When providing our service to you, we will automatically collect information about your
        device (including hardware model, operating system version, MAC address, network device
        hardware address, unique device identifier, IP address, software version number, network
        access method and type, network quality data, operation logs, use and service, device name,
        system language) and your IP address, your application crash messages, your login times and
        attempts, and your behavioral information. We collect this information for (i) ensuring your
        security, including reviewing whether UGC, messages, and related metadata violate our
        platform's user behavior guidelines and other inappropriate content; (ii) assisting us in
        detecting and combating abuse, harmful activities, fraud, spam, and illegal activities on
        Toktv; (iii) data analysis and testing of the platform to ensure its stability and security;
        (iv) providing user support for you;
      </p>
      <p>Data Analysis</p>
      <p>
        When you enter search terms to search for videos, we will analyze your search terms and
        search logs among our other users to find results. Rest assured, your search terms will not
        be disclosed to anyone in our analysis, and you can choose to delete search terms.
      </p>
      <p>Video Display and Playback</p>
      <p>
        You can use video display and playback on Toktv, and we will collect information about your
        device, including your IP address, device type, model, device name, universally unique
        identifier, operating system, language settings, app version, and your behavioral
        information, to record your paused position on specific videos.
      </p>
      <p>Information We Obtain from Third Parties</p>
      <p>
        Sometimes, we may obtain your personal information from third-party sources, including
        certain SDK providers, for example, they may record potential IP addresses or device IDs
        identified as potential security issues if such IP addresses or device IDs have initiated
        network security attacks. Before receiving any personal information about you, our
        professional security team will enhance the security of personal information (including
        reporting sensitive information, encrypting sensitive information for storage, access
        control, etc.).
      </p>
      <p>
        You understand that it is important and legitimate for us to maintain the operation of Toktv
        APP and the security of our service. For example, in operating our platform and, when
        necessary, we may communicate with you to provide our services and maintain our legitimate
        interests in business, such as responding to your queries, improving our platform
        suggestions, conducting marketing, or for detecting or preventing illegal activities. In
        certain cases, we may also have a legal obligation to collect personal information from you,
        or it may be necessary to protect your or others' vital interests.
      </p>
      <p>II. How We Use Your Personal Data</p>
      <p>We will use the information we collect about you in the following ways:</p>
      <p>Enforce our terms, conditions, and policies.</p>
      <p>Provide, process, maintain, develop, and improve our services.</p>
      <p>Deliver content to you.</p>
      <p>
        Communicate with you, including sending commercial promotional information, operational
        activity notifications, and updates to agreements.
      </p>
      <p>
        Ensure your safety and protection, including reviewing user content, messages, and related
        data for compliance with our community policies and other inappropriate content.
      </p>
      <p>Provide you with location-based services (if available within your jurisdiction).</p>
      <p>
        Conduct market research and trend analysis, as well as comprehensive statistics, data
        analysis, processing, and investigations related to our services.
      </p>
      <p>
        Generally, we will only use the personal information collected from you for the purposes
        described in this Privacy Policy or the purposes explained to you when collecting your
        personal information. However, in cases where it is legally permissible, we may also use
        your personal information for purposes other than those disclosed to you when collecting
        your personal information (such as for public interest, scientific or historical research
        purposes, or statistical purposes, archiving purposes), or for other purposes not
        conflicting with those disclosed.
      </p>
      <p>III. Who We Share Your Personal Information With</p>
      <p>
        You may share your personal information or other information with any third party (including
        unspecified recipients) through our service. For example, when using the sharing function of
        our service, you can choose to share relevant information with social apps (such as Twitter,
        Facebook, etc.) or other applications. Information you share with any third-party
        application will be controlled and processed by that third party, subject to its terms of
        service and privacy policy. You are responsible for any disclosures, abuses, or similar
        situations resulting from your sharing, and we are not liable for this.
      </p>
      <p>We may disclose your personal information to the following categories of recipients:</p>
      <p>
        Business Partners: If you choose to register and use the platform using your social network
        account details (including Facebook, Twitter), you will provide us or allow your social
        network to provide us with your username and public information.
      </p>
      <p>
        Service Providers: We provide information and content to service providers that support our
        business, such as cloud service providers and content moderation service providers, to
        ensure that Toktv is a safe and enjoyable platform.
      </p>
      <p>
        Analytics Providers: We use analytics providers to help us optimize and improve our
        services.
      </p>
      <p>
        Any competent law enforcement authority, regulatory agency, government agency, court, or
        other third party that we believe is necessary (i) under applicable laws or regulations,
        (ii) to exercise, establish, or defend our legal rights, or (iii) to protect your or anyone
        else's vital interests. (iv) to detect, prevent, or otherwise address security, fraud, or
        technical issues; (v) to comply with legal requirements or permits to protect our, our
        users', third parties', or the public's rights, property, or safety (including exchanging
        information with other companies and organizations to prevent fraud and reduce credit risk);
        (vi) to enforce our terms of service and other agreements, policies, and standards,
        including investigating any potential violations, etc.
      </p>
      <p>Any other individuals disclosed with your consent.</p>
      <p>IV. Cookies and Similar Tracking Technologies</p>
      <p>
        We and our suppliers and service providers may use cookies and other similar technologies
        (such as web beacons, flash cookies, etc.) ("cookies") to automatically collect information,
        measure and analyze which pages you clicked on and how you use our service, enhance your
        experience using Toktv, and improve our services. Cookies are small files that, when placed
        on your device, allow the platform to provide certain features and functionality. Web
        beacons are very small pieces of data embedded in images or image tags, also known as "pixel
        tags" or "transparent GIFs," that can identify cookies, view the time and date a page
        containing the pixel tag was placed, and similar information from your computer or device.
        For example, we use cookies and similar tracking technologies provided by Google.
      </p>
      <p>V. Protecting Your Personal Information Security</p>
      <p>
        We will use appropriate technical and organizational measures to protect the personal
        information we collect and process about you. The measures we use are designed to provide a
        level of security appropriate to the risk of processing your personal information. For
        example, we use SSL to encrypt and protect data; we establish access control mechanisms to
        ensure that only authorized personnel can access personal information of our users; we
        regularly hold security and privacy protection training courses to raise employee awareness
        of the importance of protecting personal information.
      </p>
      <p>
        To prevent security incidents, we have established a dedicated data security department and
        developed appropriate warning mechanisms and emergency response plans. In the event of a
        personal information security incident, we will promptly notify you in a manner that is
        appropriate and sufficient to draw your attention, in accordance with applicable laws and
        regulations, and immediately activate our emergency response plan to minimize losses.
        Unfortunately, transmitting information over the internet is not completely secure. While we
        do our best to protect your personal data, such as through encryption, we cannot guarantee
        the security of the information you transmit to the platform; any transmission is at your
        own risk.
      </p>
      <p>VI. International Data Transfers</p>
      <p>
        Your personal information will be stored and retained in Singapore, and may be transferred
        and processed in countries/regions other than the country/region in which you reside. Data
        protection laws in these countries/regions may be different from those in your
        country/region. However, we have taken appropriate protective measures to ensure that your
        personal information is protected in accordance with this Privacy Policy.
      </p>
      <p>VII. Retention of Your Personal Information</p>
      <p>
        In general, we will only retain your personal information for the shortest period within the
        possible term of providing our services. After this period, we will delete or anonymize your
        personal information in accordance with relevant laws and regulations. If we share such
        information with any third parties, we will also require that third party to do so. However,
        in the following cases, we may change the storage period of information due to relevant
        legal requirements:
      </p>
      <p>Compliance with applicable legal regulations;</p>
      <p>Compliance with court judgments, rulings, or other legal proceedings;</p>
      <p>Compliance with requests from relevant government agencies or authorized organizations;</p>
      <p>We believe it is necessary to comply with relevant legal regulations;</p>
      <p>
        For the fulfillment of relevant service agreements or this policy, for the maintenance of
        public interest, for the protection of our, our affiliates', our partners', other users' or
        employees' personal and property safety, or for the legitimate and necessary purposes of
        protecting other lawful rights and interests.
      </p>
      <p>VIII. Your Data Protection Rights</p>
      <p>As a user of our service, you have the following data protection rights:</p>
      <p>Right of Access:</p>
      <p>You can query or access your relevant personal information in our service, including:</p>
      <p>
        a. Account information: You can log in to your personal account through the relevant product
        page at any time to access personal information in your account, including profile picture,
        nickname, birthday, etc.
      </p>
      <p>
        b. Usage information: You can check your partial usage information at any time through the
        relevant product page, including favorite history, view history, and downloads.
      </p>
      <p>Right of Rectification:</p>
      <p>
        You can follow the operation guide and settings on Toktv's relevant product page to
        correct/change some of your personal information. You can also contact us directly to modify
        or change your data.
      </p>
      <p>Right of Deletion:</p>
      <p>
        a. You can request the deletion of your personal information by sending an email to
        [toktvservice@outlook.com]. If you want to delete information related to your third-party
        account in our service, you can follow our URL data deletion instructions. For your partial
        personal information, you can also voluntarily delete it by going to the account security
        settings in our product settings page and choosing "account cancellation."
      </p>
      <p>
        b. If we violate relevant laws and regulations regarding the collection, use, or sharing of
        your personal information, you can directly request us to delete your personal information
        or notify third parties to delete it, unless such data has already been anonymized or there
        are other legal requirements.
      </p>
      <p>
        c. You understand and agree that once you delete your personal information from our service
        either by yourself or with our assistance, and subject to applicable legal and relevant
        security technologies, we may not immediately delete your information from the backup
        system. In this case, we will store your personal information separately from any further
        data processing in a secure manner until such information is deleted or anonymized during
        the update period of our backup system.
      </p>
      <p>Right to Restrict or Refuse Processing of Personal Information:</p>
      <p>
        If your jurisdiction grants you rights to certain personal information, you may restrict or
        refuse the processing of some of your personal information, and we will process your request
        according to the law based on your actual situation.
      </p>
      <p>Right to Cancel Your Account:</p>
      <p>
        You can also cancel your account. We will contact you through the contact channel. After
        verifying your identity and obtaining your confirmation, we will delete or anonymize all
        relevant personal data generated by you in Toktv in accordance with relevant laws and
        regulations, unless otherwise required by laws and regulations. You can also exercise this
        right by sending an email to [toktvservice@outlook.com].
      </p>
      <p>Right to Obtain a Copy of Personal Information:</p>
      <p>
        You can contact us through the contact information provided in this policy. After verifying
        your identity, we will provide you with a copy of such information in our service within the
        prescribed period as required by our regulations, unless otherwise provided by law or this
        policy.
      </p>
      <p>Right to Lodge a Complaint:</p>
      <p>
        You have the right to lodge a complaint with a data protection authority about our
        collection and use of your personal information. For more information, please contact your
        local data protection authority.
      </p>
      <p>
        To exercise your rights mentioned above, you can also contact us through the contact
        information provided in section XI. We will respond to all requests made by individuals
        wishing to exercise their data protection rights in accordance with applicable data
        protection laws. In order to protect the security of your account and personal information,
        we may request additional information from you to verify your identity before fulfilling
        your request.
      </p>
      <p>IX. Information Related to Children</p>
      <p>
        Children under the age of 14 are not allowed to register for our service and create their
        own accounts. If you are over 14 years old but considered a minor in your country/region,
        you must have your parents or legal guardians read this privacy policy with you, and you
        declare that you have obtained permission from your parents or legal guardians to create
        your Toktv account. If parents or legal guardians permit, children of all age ranges can use
        the service. If you are a parent or legal guardian of a minor in your country/region, please
        make sure to read this privacy policy before allowing your child to create their own account
        or use the service. You agree that you will comply with this privacy policy and be
        responsible for your child's activities when using the service. You agree to supervise your
        child's use of the service and ensure that your child only views content suitable for their
        age.
      </p>
      <p>X. Update of this Privacy Policy</p>
      <p>
        We may update this privacy policy from time to time to respond to changing legal, technical,
        or business developments. When we update our privacy policy, we will take appropriate
        measures to notify you of the significance of the changes we have made. If applicable data
        protection laws require it, we will obtain your consent for any material changes to this
        privacy policy.
      </p>
      <p>XI. How to Contact Us</p>
      <p>
        If you have any questions or concerns about this privacy policy and our use of your personal
        information, please contact us at [toktvservice@outlook.com].
      </p>
    </div>
  </div>
</template>
