<template>
  <div class="page pay">
    <div class="container">
      <div class="top__bar">
        <img @click="handleGoBack" src="../../imgs/wallet/back.png" alt="" />
      </div>
      <div class="balance">
        <div>
          {{ $t('play.txt.balance1') }}：<span class="value">{{ payStore.memberInfo.k_value }}</span
          >&nbsp;<span>{{ $t('play.txt.kb') }}</span>
        </div>
        <div v-show="isAppcqidOrganic()">
          <span class="line">|</span>
          <span class="value">{{ payStore.memberInfo.g_value }}</span
          >&nbsp;<span>{{ $t('task.gold') }}</span>
        </div>
      </div>
      <div class="balancetip" v-show="isAppcqidOrganic()">
        1{{ $t('play.txt.kb') }}=1{{ $t('task.gold') }}
      </div>
      <div class="buy__vip">
        <card-vip :list="payStore.vipGoodsList" @buy="(item) => payBuyGoods(item, 1)" />
      </div>
      <div class="buy__k">
        <div class="title">{{ $t('play.txt.recharge') }}</div>
        <div
          :class="['k__special', { focus: payStore.kGoodsSpecial }]"
          v-if="payStore.kGoodsSpecial"
          @click="payStore.kGoodsSpecial && payBuyGoods(payStore.kGoodsSpecial, 3)"
        >
          <div class="k__info">
            <div class="price">
              <span>{{ kgsCustom?.value }}</span>
              {{ $t('play.txt.kb') }}
            </div>
            <div class="text" v-show="kgsCustom?.extra_value && Number(kgsCustom?.extra_value) > 0">
              +{{ kgsCustom?.extra_value }} {{ $t('play.txt.kb') }}
            </div>
          </div>
          <div class="k__price">{{ kgsCustom?.unit }} {{ payStore.kGoodsSpecial.price }}</div>
          <div class="mark" v-show="kgsCustom?.markText && Number(kgsCustom?.extra_value) != 0">
            {{ kgsCustom?.markText }}
          </div>
          <div class="mark2">
            <span class="hh">{{ current.hh }}</span
            >&nbsp;:&nbsp;<span class="mm">{{ current.mm }}</span
            >&nbsp;:&nbsp;<span class="ss">{{ current.ss }}</span>
            <!-- &nbsp;:&nbsp;<span class="ms">{{ current.value.milliseconds }}</span> -->
          </div>
          <div class="finger"><ani :src="svgaFinger"></ani></div>
        </div>
        <card-k2 :type="2" :list="payStore.kGoodsList" @buy="(item) => payBuyGoods(item, 2)" />
      </div>
      <div class="footer">
        <p class="p1">{{ $t('play.txt.desc6') }}</p>
        <p class="p2">{{ $t('play.txt.desc7') }}</p>
      </div>
    </div>
  </div>
  <!-- 支付方式 -->
  <pay-way v-model:visible="showPayWay" :goods="payGoods" />
  <!-- 支付结果 -->
  <pay-result v-model:visible="showPayResult" :order_id="payOrderId" @pay="userPayResult" />
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';
import { goBack } from '@/pages/index/router';
import { useRouteStore } from '@/pages/index/store/modules/route';
import { usePayStore } from '@/pages/index/store/modules/pay';
import { usePay } from './usePay';
import { payItem } from '@/utils/auth';
import CardVip from './component/card-vip.vue';
import CardK2 from './component/card-k2.vue';
import PayResult from './component/pay-result.vue';
import PayWay from './component/pay-way.vue';
import xl, { isAppcqidOrganic } from '@/utils/log';
import { TOKTV_K_GOODS_COUNT_DOWN_TIME } from '../../keys';
import { CurrentTime, useCountDown } from '@vant/use';
import ani from '@/components/ani-player/index.vue';
import svgaFinger from '@/pages/index/imgs/svga/finger.svga?url';

const { payOrderId, showPayWay, payGoods, showPayResult, handlePay, userPayResult } = usePay();
const routeStore = useRouteStore();
const payStore = usePayStore();
const visibility = useDocumentVisibility();
const kgsCustom = computed(() => payStore.kGoodsSpecial?.custom);

const current = reactive({
  hh: '00',
  mm: '00',
  ss: '00',
});
const countDownTime =
  import.meta.env.VITE_APP_ENV === 'production' ? 24 * 60 * 60 * 1000 : 60 * 1000;
const time = useLocalStorage(TOKTV_K_GOODS_COUNT_DOWN_TIME, countDownTime);
const countDown = useCountDown({
  // 倒计时 24 小时
  time: time.value,
  millisecond: false,
  onChange(current: CurrentTime) {
    time.value = current.total;
  },
  onFinish() {
    // console.log('[countDown onFinish]');
    time.value = countDownTime;
    countDown.reset(time.value);
    countDown.start();
  },
});

const payBuyGoods = (goods: PayType.PlyItemFormat, type: number) => {
  //如果只有一种支付方式则直接调用接口创建订单，否则让用户选择支付方式
  payItem.value = JSON.stringify(goods);
  if (payStore.choosePayments.length > 1) {
    payGoods.value = goods;
    showPayWay.value = true;
  } else {
    handlePay(goods);
  }
  if (type == 1) {
    xl.act({ actentryid: '110008', subactid: `${goods.commodityId}`, type: 'click' });
  }
  if (type == 2) {
    xl.act({ actentryid: '110009', subactid: `${goods.custom?.value}`, type: 'click' });
  }
};
const handleGoBack = () => {
  xl.act({ actentryid: '110008', type: 'close' });
  goBack(routeStore.saveRouteInfo.path);
};

const checkOrderShowResult = () => {
  // 如果存在订单ID，则弹出确认支付状态弹框。
  if (payOrderId.value) {
    showPayResult.value = true;
  }
};

watchEffect(() => {
  const { hours, minutes, seconds } = countDown.current.value;
  let hh = `${hours}`;
  let mm = `${minutes}`;
  let ss = `${seconds}`;
  if (hh.length < 2) hh = `0${hh}`;
  if (mm.length < 2) mm = `0${mm}`;
  if (ss.length < 2) ss = `0${ss}`;
  current.hh = hh;
  current.mm = mm;
  current.ss = ss;
});

watch(visibility, (v) => {
  if (v === 'visible') {
    checkOrderShowResult();
  }
});

onMounted(() => {
  xl.act({ actentryid: '110008', type: 'show' });
  payStore.getGoodsList();
  checkOrderShowResult();
  countDown.start();
});
</script>
<style lang="scss" scoped>
.pay {
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  background-color: #ebedef;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  .container {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;

    .top__bar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 20px;
      margin: 0 0 20px;
      padding: 0 15px;

      span {
        min-width: 73px;
        height: 28px;
        padding: 0 10px;
        color: #7e808a;
        font-size: 13px;
        line-height: 28px;
        text-align: center;
        background: #ebedef;
        border: 0.5px solid #a9aaad;
        border-radius: 14px;
      }

      img {
        left: 15px;
        width: 25px;
      }
    }

    .balance {
      display: flex;
      align-items: center;
      padding: 0 15px;
      color: #7e808a;
      font-size: 16px;

      span {
        color: #222;
        font-weight: 500;

        &.line {
          margin: 0 4px;
        }

        &.value {
          color: #ff3868;
        }
      }
    }

    .balancetip {
      margin-top: 10px;
      padding: 0 15px;
      color: #ff3969;
      font-size: 14px;
    }

    .buy__vip {
      position: relative;
      width: 100%;
      height: 80px;
      margin-top: 24px;
    }

    .buy__k {
      width: 100%;
      margin-top: 20px;
      padding: 0 15px;

      .title {
        margin-bottom: 15px;
        color: #222;
        font-weight: 500;
        font-size: 16px;
      }

      .k__special {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 345px;
        height: 80px;
        margin: 40px auto 15px;
        padding: 0 25px 0 16px;
        background: #fff;
        border-radius: 0 0 11px 11px;
        box-shadow: 0 1px 7px 0 rgba(220, 222, 225, 0.8);

        &::before {
          display: inline-block;
          width: 67px;
          height: 41px;
          background: url('@/pages/index/imgs/pay/kcoin.png');
          background-size: 100% 100%;
          content: '';
        }

        &.focus {
          .finger {
            position: absolute;
            right: -30px;
            bottom: -50px;
            display: block;
            width: 110px;
          }
        }

        .finger {
          display: none;
          pointer-events: none;
        }

        .k__info {
          flex: 1;
          align-items: flex-start;
          padding-right: 10px;
          padding-left: 13px;

          .price {
            color: #7c9094;
            font-size: 14px;

            span {
              color: #222;
              font-weight: bold;
              font-size: 20px;
            }
          }

          .text {
            color: #ff7a00;
            font-size: 14px;
            word-break: normal;
          }
        }

        .k__price {
          min-width: 62px;
          height: 30px;
          padding: 0 10px;
          color: #fff;
          font-weight: 700;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          background: #ff3868;
          border-radius: 5.5px;
        }

        // .special {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   min-width: 45px;
        //   height: 21px;
        //   padding: 0 10px;
        //   color: #fff;
        //   font-size: 12px;
        //   line-height: 21px;
        //   text-align: center;
        //   background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
        //   border-radius: 11px 0;
        // }

        .mark {
          position: absolute;
          top: -25px;
          left: 0;
          display: flex;
          align-items: center;
          min-width: 43px;
          height: 25px;
          padding: 0 10px;
          color: #fff;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          background: linear-gradient(90deg, #ff8f3c 0%, #ff3566 100%);
          border-radius: 11px 11px 11px 0;
        }

        .mark2 {
          position: absolute;
          top: -25px;
          right: 0;
          display: flex;
          align-items: center;
          height: 25px;
          color: #2a2e31;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;

          span {
            width: 25px;
            padding: 5px 0;
            color: #fff;
            font-weight: normal;
            text-align: center;
            background: #2a2e31;
            border-radius: 5px;
            // &.ms {
            //   width: 35px;
            //   padding: 5px;
            // }
          }
        }
      }
    }

    .footer {
      margin-top: 30px;
      padding: 0 20px;

      p {
        font-size: 12px;
        text-align: center;
        word-break: normal;
      }

      .p1 {
        margin-bottom: 10px;
        color: #666;
      }

      .p2 {
        color: #bebebe;
        line-height: 21px;
      }
    }
  }
}
</style>
