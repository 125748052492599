const ENV = import.meta.env.VITE_APP_ENV;

let signSecret, paramsSecret;
if (ENV === 'production') {
  signSecret = 'kjIdRQO4hvvu2lMD=+Dz';
  paramsSecret = 'W4oa+Mub61CSgkxR8rNIm3secTKA5zyw9QYBlip2JZVEj/nHD0tOfdP7LXhFGqvU';
} else {
  signSecret = 'lZePIwPwmtle7mBv=+Dz';
  paramsSecret = 'UXqAKNWa/4I9vwg87oMYy2dT5b+HnsQe0CSDPcZLVukfREm3BJjOl1zipxFrth6G';
}

export const signSecretKey = signSecret;
export const paramsSecretKey = paramsSecret;
